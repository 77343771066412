import { type Reise, ReiseIdentifikation, ReiseQuelle } from '@/model/reise';
import { Route } from '@/proto/hafas/hafas';
import { mapHafasDurationToDuration, mapHafasLegToAbschnitt } from '@/mapper/from-hafas/abschnitt';
import { mapHafasStopInfoToZeit } from '@/mapper/from-hafas/zeit';

export function mapHafasRouteToReise(route: Route): Reise {
  if (route.departure === undefined || route.arrival === undefined) {
    throw 'Arrival or Departure of Route is undefined, which is not allowed';
  }

  return {
    id: new ReiseIdentifikation(ReiseQuelle.DB_BUSINESS_NAVIGATOR, route.ctxRecon),
    abfahrt: mapHafasStopInfoToZeit(route.departure),
    ankunft: mapHafasStopInfoToZeit(route.arrival),
    dauer: mapHafasDurationToDuration(route.duration),
    isAlternative: route.isAlternative,
    isFahrbar: route.isRideable,
    umstiege: route.changes,
    allAbschnitt: route.legs.map(mapHafasLegToAbschnitt),
  };
}
