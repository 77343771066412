import { computed, type Ref } from 'vue';
import { formatDurationInHours } from '@/composables/helper';
import { format, startOfMinute } from 'date-fns';
import { type Abschnitt } from '@/model/abschnitt';
import { convertIntervalToDuration } from '@/model/zeit';
import { VerzoegerungOhnePrognose } from '@/model/verzoegerung';

export default function useReiseBegleitungTexts(
  currentTime: Ref<number>,
  isCurrentlyOnTrain: Ref<boolean>,
  lastDeparture: Ref<Abschnitt | null>,
  nextArrival: Ref<Abschnitt | null>,
  nextDeparture: Ref<Abschnitt | null>,
) {
  const command = computed(() => {
    if (isCurrentlyOnTrain.value && nextArrival.value !== null) {
      return 'Ausstieg in';
    } else if (nextDeparture.value !== null) {
      return 'Abfahrt in';
    } else if (lastDeparture.value !== null) {
      return `Du bist mit ${lastDeparture.value.fahrt.getName()} um`;
    }
    return 'Gleich geht es los...';
  });
  const station = computed(() => {
    if (isCurrentlyOnTrain.value && nextArrival.value !== null) {
      return `in ${nextArrival.value.ankunft.haltestelle.name}.` || '';
    } else if (nextDeparture.value !== null) {
      return `in ${nextDeparture.value.abfahrt.haltestelle.name}.` || '';
    } else if (lastDeparture.value !== null) {
      return `in ${lastDeparture.value.ankunft.haltestelle.name} angekommen!`;
    }
    return 'Verbindungsdaten werden geladen.';
  });
  const time = computed(() => {
    if (isCurrentlyOnTrain.value && nextArrival.value !== null) {
      const curTime = startOfMinute(currentTime.value);
      const arrival = nextArrival.value.ankunft.fahrtereignis.getZeit();
      const diff = convertIntervalToDuration(curTime, arrival);
      return formatDurationInHours(diff);
    } else if (nextDeparture.value !== null) {
      const curTime = startOfMinute(currentTime.value);
      const departure = nextDeparture.value.abfahrt.fahrtereignis.getZeit();
      const diff = convertIntervalToDuration(curTime, departure);
      return formatDurationInHours(diff);
    } else if (lastDeparture.value !== null) {
      return format(lastDeparture.value.ankunft.fahrtereignis.getZeit(), 'HH:mm');
    }
    return '';
  });
  const rawDelay = computed(() => {
    if (isCurrentlyOnTrain.value && nextArrival.value !== null) {
      return nextArrival.value.ankunft.fahrtereignis.getVerzoegerung();
    } else if (nextDeparture.value !== null) {
      return nextDeparture.value.abfahrt.fahrtereignis.getVerzoegerung();
    } else if (lastDeparture.value !== null) {
      return lastDeparture.value.ankunft.fahrtereignis.getVerzoegerung();
    }
    return new VerzoegerungOhnePrognose();
  });
  const hasDelay = computed(() => {
    return rawDelay.value.hasPrognose;
  });
  const isDelayOver5Minutes = computed(() => !rawDelay.value.isUnderFiveMinutes());
  const delay = computed(() => rawDelay.value.format());
  const nextDepartureText = computed(() => (isCurrentlyOnTrain.value ? 'Anschluss' : 'Nächster Abschnitt'));

  return {
    command,
    time,
    station,
    delay,
    hasDelay,
    isDelayOver5Minutes,
    nextDepartureText,
  };
}
