import { useJourneyAssistanceStore } from '@/stores/journeyAssistance';

/**
 * @deprecated Use useReiseBegleitung.ts instead
 **/
export default function useJourneyAssistance() {
  const journeyAssistanceStore = useJourneyAssistanceStore();

  /**
   * @deprecated remove when migration is done
   */
  const triggerMigrationToReiseBegleitung = async () => await journeyAssistanceStore.migrateToReiseBegleitung();

  return {
    triggerMigrationToReiseBegleitung,
  };
}
