import { api } from '@zentrains/api';
import { type Reise, ReiseIdentifikation, ReiseQuelle } from '@/model/reise';
import { mapExternalDauer } from '@/mapper/from-api/helper';
import { mapExternalZeit } from '@/mapper/from-api/zeit';
import { mapExternalAbschnitt } from '@/mapper/from-api/abschnitt';

export function mapExternalReise(reise: api.Reise): Reise {
  const reiseId = reise.id;
  if (reiseId === undefined) {
    throw 'ReiseIdentifikation of Reise is undefined, which is illegal';
  }
  const abfahrt = reise.abfahrt;
  if (abfahrt === undefined) {
    throw 'Abfahrt of Reise is undefined, which is illegal';
  }
  const ankunft = reise.ankunft;
  if (ankunft === undefined) {
    throw 'Ankunft of Reise is undefined, which is illegal';
  }
  return {
    id: mapExternalReiseIdentifikation(reiseId),
    dauer: mapExternalDauer(reise.dauer),
    umstiege: reise.umstiege,
    isAlternative: reise.isAlternative,
    isFahrbar: reise.isFahrbar,
    abfahrt: mapExternalZeit(abfahrt),
    ankunft: mapExternalZeit(ankunft),
    allAbschnitt: reise.allAbschnitt.map(mapExternalAbschnitt),
  };
}

export function mapExternalReiseIdentifikation(id: api.ReiseIdentifikation): ReiseIdentifikation {
  return new ReiseIdentifikation(mapExternalReiseQuelle(id.quelle), id.reiseId);
}

export function mapExternalReiseQuelle(quelle: api.ReiseQuelle): ReiseQuelle {
  switch (quelle) {
    case api.ReiseQuelle.REISE_DB_BUSINESS_NAVIGATOR:
      return ReiseQuelle.DB_BUSINESS_NAVIGATOR;
    case api.ReiseQuelle.REISE_DB_NAVIGATOR:
      return ReiseQuelle.DB_NAVIGATOR;
    case api.ReiseQuelle.REISE_OEBB:
      return ReiseQuelle.OEBB;
    case api.ReiseQuelle.REISE_SBB:
      return ReiseQuelle.SBB;
    case api.ReiseQuelle.UNRECOGNIZED:
      return ReiseQuelle.UNBEKANNT;
  }
}
