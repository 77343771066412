import { StopInfo } from '@/proto/iris/iris';
import { StopInfo as HafasStopInfo, Train } from '@/proto/hafas/hafas';
import { type TransformedStopInfo } from '@/components/station-board/types';
import { type MessagePayload, type NotificationPayload } from 'firebase/messaging';
import { addYears, type Duration, format, isToday, isTomorrow, startOfToday, subYears } from 'date-fns';
import { DATE_FORMAT } from '@/constants';
import { type PickupsByDate, type ReiseByDate } from '@/types';
import { type LastAbschnittPickup } from '@/stores/types';
import { type Reise } from '@/model/reise';

const today = new Date();
export const IonDateTimeMax = format(addYears(today, 1), 'yyyy');
export const IonDateTimeMin = format(subYears(today, 1), 'yyyy');

export function formatDateForUser(date: number | Date) {
  const formattedTime = format(date, 'HH:mm');
  let formattedDate: string;
  if (isToday(date)) {
    formattedDate = 'Heute';
  } else if (isTomorrow(date)) {
    formattedDate = 'Morgen';
  } else {
    formattedDate = format(date, 'dd.MM.yyyy');
  }
  return `${formattedDate}, ${formattedTime} Uhr`;
}

export function transformStopInfo(stopInfo: StopInfo | HafasStopInfo | null | undefined): TransformedStopInfo {
  if (stopInfo) {
    return {
      delay: stopInfo.delay,
      hasDelay: stopInfo.hasDelay,
      timeActual: stopInfo.time,
      timePlanned: stopInfo.scheduledTime,
    };
  }
  return {
    delay: null,
    hasDelay: null,
    timeActual: null,
    timePlanned: null,
  };
}

export interface MessagePayloadWithNotification extends MessagePayload {
  notification: NotificationPayload;
}

export async function sendNotificationIfNecessary(
  serviceWorkerRegistration: ServiceWorkerRegistration,
  payload: MessagePayload,
): Promise<boolean> {
  if (payload.data) {
    const title = payload.data.title;
    const body = payload.data.body;
    const details = payload.data.details;
    if (title !== undefined && body !== undefined) {
      await serviceWorkerRegistration.showNotification(title, {
        body:
          details !== ''
            ? `${body}
${details}`
            : body,
        icon: '/img/icons/android-chrome-192x192.png',
        badge: '/img/icons/android-chrome-192x192.png',
      });
      return true;
    }
  }
  return false;
}

export function isInt(s: string | null | undefined): s is string {
  return s !== null && s !== undefined && !Number.isNaN(Number.parseInt(s, 10));
}

export function toDuration(duration: number): Duration {
  return {
    days: Math.floor(duration / 86400),
    hours: Math.floor((duration % 86400) / 3600),
    minutes: (duration % 3600) / 60,
  };
}

export function convertRoutesToRoutesByDate(result: ReiseByDate, route: Reise): ReiseByDate {
  const date = format(route.abfahrt.sollZeit, DATE_FORMAT);
  if (result[date] !== undefined) {
    const insertIndex = result[date].findIndex((r) => route.abfahrt.sollZeit < r.abfahrt.sollZeit);
    const index = insertIndex > -1 ? insertIndex : result[date].length;
    result[date].splice(index, 0, route);
  } else {
    result[date] = [route];
  }
  return result;
}

export function convertPickupsToPickupsByDate(res: PickupsByDate, pickup: LastAbschnittPickup) {
  const date = pickup.lastLeg.ankunft
    ? format(pickup.lastLeg.ankunft.fahrtereignis.sollZeit, DATE_FORMAT)
    : format(startOfToday(), DATE_FORMAT);
  if (res[date] !== undefined) {
    const insertIndex = res[date].findIndex(
      (r) => pickup.lastLeg.ankunft.fahrtereignis.sollZeit < r.lastLeg.ankunft.fahrtereignis.sollZeit,
    );
    const index = insertIndex > -1 ? insertIndex : res[date].length;
    res[date].splice(index, 0, pickup);
  } else {
    res[date] = [pickup];
  }
  return res;
}

export function formatDurationInHours(duration: Duration): string {
  return `${duration.hours || 0}:${duration.minutes?.toString(10).padStart(2, '0') || '00'}`;
}

export function formatDurationInHoursWithH(duration: Duration): string {
  return `${formatDurationInHours(duration)}h`;
}

export function formatMinuteDurationInHours(minutes: number): string {
  return `${Math.floor(minutes / 60)}:${(minutes % 60).toString(10).padStart(2, '0')}`;
}

export function formatMinuteDurationInHoursWithH(minutes: number): string {
  return `${formatMinuteDurationInHours(minutes)}h`;
}

const trainTypesToIgnoreLine: string[] = ['ICE', 'IC', 'ECE', 'EC', 'RJX', 'RJ'];

function shouldDisplayLine(train: Train) {
  return (
    train.line !== '' &&
    train.line !== train.number.toString(10) &&
    (trainTypesToIgnoreLine.indexOf(train.type) === -1 || train.operatorId === '85')
  );
}

export function getTrainName(train: Train | undefined): string {
  if (train === undefined) {
    return '';
  }
  return shouldDisplayLine(train) ? `${train.type} ${train.line}` : `${train.type} ${train.number}`;
}

export function getTrainNumber(train: Train | undefined): string {
  if (train === undefined) {
    return '';
  }
  return shouldDisplayLine(train) ? train.number.toString(10) : '';
}
