import { type Duration } from 'date-fns';
import { type Abschnitt } from '@/model/abschnitt';
import { Zeit } from '@/model/zeit';

export interface Reise {
  readonly id: ReiseIdentifikation;

  readonly isAlternative: boolean;
  readonly isFahrbar: boolean;

  readonly abfahrt: Zeit;
  readonly ankunft: Zeit;
  readonly dauer: Duration;
  readonly umstiege: number;

  readonly allAbschnitt: Abschnitt[];
}

export class ReiseIdentifikation {
  readonly reiseQuelle: ReiseQuelle;
  readonly reiseId: string;

  constructor(reiseQuelle: ReiseQuelle, reiseId: string) {
    this.reiseQuelle = reiseQuelle;
    this.reiseId = reiseId;
  }

  toString(): string {
    return `${this.reiseQuelle}-${this.reiseId}`;
  }
}

export enum ReiseQuelle {
  DB_BUSINESS_NAVIGATOR,
  DB_NAVIGATOR,
  OEBB,
  SBB,
  UNBEKANNT = 999,
}

export function displayName(reiseQuelle: ReiseQuelle) {
  switch (reiseQuelle) {
    case ReiseQuelle.DB_BUSINESS_NAVIGATOR:
      return 'DB Business Navigator';
    case ReiseQuelle.DB_NAVIGATOR:
      return 'DB Navigator';
    case ReiseQuelle.OEBB:
      return 'ÖBB';
    case ReiseQuelle.SBB:
      return 'SBB Mobile';
    case ReiseQuelle.UNBEKANNT:
      return 'Unbekannt';
  }
}
