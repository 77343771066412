import { StopInfo } from '@/proto/hafas/hafas';
import { Fahrtereignis } from '@/model/fahrtereignis';

export function mapHafasStopInfoToFahrtereignis(
  stopInfo: StopInfo | undefined,
  isAusfall: boolean,
  isZusaetzlich: boolean,
): Fahrtereignis | null {
  if (stopInfo !== undefined) {
    return new Fahrtereignis(
      new Date(stopInfo.scheduledTime),
      stopInfo.hasDelay ? new Date(stopInfo.time) : null,
      stopInfo.scheduledPlatform,
      stopInfo.platform,
      isAusfall,
      isZusaetzlich,
      [],
    );
  }
  return null;
}
