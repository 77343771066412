import { defineStore } from 'pinia';
import {
  Backend,
  type LastAbschnittPickup,
  type Pickup,
  type PickupAssistanceState,
  type ReiseIdToReiseDto,
} from '@/stores/types';
import { type PickupsByDate } from '@/types';
import { RefreshJourneyRequest } from '@/proto/hafas/hafas';
import { convertPickupsToPickupsByDate } from '@/composables/helper';
import { useSettingsStore } from '@/stores/settings';
import { useNotificationsStore } from '@/stores/notifications';
import { grpc } from '@/proto/rpc';
import { addHours, isBefore } from 'date-fns';
import { Toast } from '@capacitor/toast';
import { captureException } from '@sentry/vue';
import { AbschnittTyp } from '@/model/abschnitt';
import { type Reise, ReiseIdentifikation } from '@/model/reise';
import { mapDtoToReise } from '@/mapper/from-store-dto/reise';
import { mapHafasRouteToReise } from '@/mapper/from-hafas/reise';
import { mapReiseToDto } from '@/mapper/to-store-dto/reise';
import { useBackendStore } from '@/stores/backend';
import { api } from '@zentrains/api';
import { mapToExternalReiseIdentifikation } from '@/mapper/to-api/reiseId';

function byDate(a: LastAbschnittPickup, b: LastAbschnittPickup) {
  return a.lastLeg.ankunft.fahrtereignis.getZeit().getTime() - b.lastLeg.ankunft.fahrtereignis.getZeit().getTime();
}

export const usePickupAssistanceStore = defineStore('pickupAssistance', {
  state: (): PickupAssistanceState => ({
    allPickup: [],
    reiseIdToReiseDto: {},
    updating: false,
  }),
  persist: true,
  getters: {
    allReise(): Reise[] {
      return Object.values(this.reiseIdToReiseDto).map(mapDtoToReise);
    },
    reiseIdToReiseMap(): Map<string, Reise> {
      return new Map<string, Reise>(this.allReise.map((r) => [r.id.reiseId, r]));
    },
    lastLegsMap(): Map<string, LastAbschnittPickup> {
      return new Map<string, LastAbschnittPickup>(
        this.allPickup
          .filter((p) => this.reiseIdToReiseMap.has(p.reiseId.toString()))
          .map((p) => [
            p.reiseId.toString(),
            {
              pickup: p,
              lastLeg: this.reiseIdToReiseMap
                .get(p.reiseId.toString())!
                .allAbschnitt.filter((leg) => leg.typ === AbschnittTyp.Fahrt)
                .reverse()[0],
            },
          ]),
      );
    },
    lastLegs(): LastAbschnittPickup[] {
      return [...this.lastLegsMap.values()].sort(byDate);
    },
    pickupsByDate(): PickupsByDate {
      return this.lastLegs.reduce(convertPickupsToPickupsByDate, {} as PickupsByDate);
    },
  },
  actions: {
    getPickup(reiseId: string): LastAbschnittPickup | null {
      return this.lastLegsMap.get(reiseId) || null;
    },
    deleteAllPickups() {
      this.reiseIdToReiseDto = {};
      this.allPickup = [];
    },
    async sendPickupsToNotificationServer() {
      const settings = useSettingsStore();
      const notifications = useNotificationsStore();
      if (settings.pushNotificationsActive && notifications.pushToken !== '') {
        const request: api.SaveAllPickupRequest = {
          deviceKey: notifications.pushToken,
          allItem: this.allPickup.map(
            (p): api.PickupItem => ({
              reiseId: mapToExternalReiseIdentifikation(p.reiseId),
              name: p.name,
              wagen: p.coach,
            }),
          ),
        };
        const backend = useBackendStore();
        await backend.notificationClient.saveAllPickup(request);
      }
    },
    async deletePickup(reiseId: ReiseIdentifikation) {
      delete this.reiseIdToReiseDto[reiseId.toString()];
      const index = this.allPickup.findIndex((p) => p.reiseId.toString() === reiseId.toString());
      if (index !== -1) {
        this.allPickup.splice(index, 1);
      }
      await this.sendPickupsToNotificationServer();
    },
    async refreshPickup(reset: boolean, ...pickups: Pickup[]) {
      try {
        this.updating = true;
        const backend = useBackendStore();
        if (backend.selectedBackend === Backend.LEGACY) {
          const refreshRequest: RefreshJourneyRequest = {
            ctxRecon: pickups.map((p) => p.reiseId.reiseId),
          };
          const response = await grpc.RefreshJourneyWithPlanSequence(refreshRequest);

          if (response.isSuccess) {
            const routes = response.data.map(mapHafasRouteToReise);
            if (reset) {
              const now = Date.now();
              const filteredRoutes = routes.filter((route) => isBefore(now, addHours(route.ankunft.getZeit(), 6)));
              this.allPickup = [];
              this.reiseIdToReiseDto = filteredRoutes.reduce((obj: ReiseIdToReiseDto, reise: Reise) => {
                const pickup = pickups.find((p) => p.reiseId.toString() === reise.id.toString());
                this.allPickup.push({
                  name: pickup?.name || '',
                  coach: pickup?.coach || '',
                  reiseId: reise.id,
                });
                obj[reise.id.reiseId] = mapReiseToDto(reise);
                return obj;
              }, {} as ReiseIdToReiseDto);
            } else {
              routes.forEach((reise) => {
                this.reiseIdToReiseDto[reise.id.reiseId] = mapReiseToDto(reise);
              });
            }
            await this.sendPickupsToNotificationServer();
          } else {
            captureException(response.error);
            await Toast.show({
              text: 'Fehler beim Aktualisieren der gespeicherten Abholungen',
            });
          }
        } else {
          const allReise = await backend.refreshReise(pickups.map((p) => p.reiseId));
          if (reset && allReise.length === pickups.length) {
            this.allPickup = [];
            this.reiseIdToReiseDto = allReise.reduce((obj: ReiseIdToReiseDto, reise: Reise) => {
              const pickup = pickups.find((p) => p.reiseId.toString() === reise.id.toString());
              this.allPickup.push({
                name: pickup?.name || '',
                coach: pickup?.coach || '',
                reiseId: reise.id,
              });
              obj[reise.id.reiseId] = mapReiseToDto(reise);
              return obj;
            }, {} as ReiseIdToReiseDto);
          } else {
            allReise.forEach((reise) => {
              this.reiseIdToReiseDto[reise.id.reiseId] = mapReiseToDto(reise);
            });
          }
          await this.sendPickupsToNotificationServer();
        }
      } catch (e) {
        captureException(e);
      } finally {
        this.updating = false;
      }
    },
    async savePickup(pickup: Pickup) {
      this.allPickup.push(pickup);
      await this.refreshPickup(false, pickup);
    },
    async refreshPickups() {
      if (this.allPickup.length > 0) {
        await this.refreshPickup(true, ...this.allPickup);
      }
    },
  },
});
