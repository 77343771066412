import { defineStore } from 'pinia';
import { type SettingsState, type TraewellingOAuth } from '@/stores/types';
import { useNotificationsStore } from '@/stores/notifications';
import { useBackendStore } from '@/stores/backend';

const defaultTraewellingOAuth = {
  accessToken: '',
  refreshToken: '',
  tokenType: '',
  expiry: -1,
};

export const useSettingsStore = defineStore('settings', {
  state: (): SettingsState => ({
    pushNotificationsActive: false,
    thresholdChangeNotificationMinutes: 7,
    thresholdDepartureNotificationMinutes: 15,
    thresholdFetchNotificationMinutes: 15,
    checkinActive: false,
    searchForBetriebsstelle: false,
    showDetails: false,
    traewellingOAuth: {
      ...defaultTraewellingOAuth,
    },
    travelynxToken: '',
    unlockCodes: '',
  }),
  persist: true,
  getters: {
    isTroubleshootingUnlocked: (state) => state.unlockCodes.includes('fehler'),
    isKonzernUnlocked: (state) => state.unlockCodes.includes('konzern'),
    isBackendSelectionUnlocked(): boolean {
      return this.unlockCodes.includes('dev');
    },
  },
  actions: {
    setTraewellingOAuth(traewellingOAuth: TraewellingOAuth) {
      this.traewellingOAuth = traewellingOAuth;
    },
    resetTraewellingOAuth() {
      this.traewellingOAuth = {
        ...defaultTraewellingOAuth,
      };
    },
    async sendCheckinTokensToServer() {
      const notifications = useNotificationsStore();
      if (this.pushNotificationsActive && notifications.pushToken !== '') {
        const backend = useBackendStore();
        await backend.notificationClient.saveCheckinSettings({
          deviceKey: notifications.pushToken,
          checkinActive: this.checkinActive,
          traewellingToken: this.traewellingOAuth.accessToken,
          travelynxToken: this.travelynxToken,
        });
      }
    },
    async sendNotificationThresholdsToServer() {
      const notifications = useNotificationsStore();
      if (this.pushNotificationsActive && notifications.pushToken !== '') {
        const backend = useBackendStore();
        await backend.notificationClient.saveNotificationThresholds({
          deviceKey: notifications.pushToken,
          thresholdChangeMinutes: this.thresholdChangeNotificationMinutes,
          thresholdDepartureMinutes: this.thresholdDepartureNotificationMinutes,
          thresholdPickupMinutes: this.thresholdFetchNotificationMinutes,
        });
      }
    },
  },
});
