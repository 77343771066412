import { api } from '@zentrains/api';
import { type AbschnittFluegel } from '@/model/abschnitt';
import { mapExternalFahrt } from '@/mapper/from-api/fahrt';
import { mapExternalMeldung } from '@/mapper/from-api/meldung';

export function mapExternalAbschnittFluegel(externalFluegel: api.AbschnittFluegel): AbschnittFluegel {
  const externalFahrt = externalFluegel.fahrt;
  if (externalFahrt === undefined) {
    throw 'Fahrt within AbschnittFluegel undefined, which is illegal';
  }

  return {
    fahrtId: externalFluegel.fahrtId,
    fahrt: mapExternalFahrt(externalFahrt),
    allMeldung: externalFluegel.allMeldung.map(mapExternalMeldung),
  };
}
