import { Reihung, Station, StopInfo } from '@/proto/hafas/hafas';
import { type AbschnittFahrtereignis } from '@/model/abschnitt';
import { mapHafasStopInfoToFahrtereignis } from '@/mapper/from-hafas/fahrtereignis';
import { mapHafasReihungToWagenreihung } from '@/mapper/from-hafas/wagenreihung';
import { Fahrtereignis } from '@/model/fahrtereignis';

export function mapHafasLegStopInfoToAbschnittFahrtereignis(
  stopInfo: StopInfo | undefined,
  station: Station | undefined,
  reihung: Reihung | undefined,
): AbschnittFahrtereignis {
  const fahrtereignis = mapHafasStopInfoToFahrtereignis(stopInfo, stopInfo?.cancelled || false, false);
  if (fahrtereignis === null) {
    throw 'stopInfo of Hafas Leg was undefined, which is not allowed';
  }
  if (station === undefined) {
    throw 'station of Hafas Leg was undefined, which is not allowed';
  }
  return {
    fahrtereignis: new Fahrtereignis(
      fahrtereignis.sollZeit,
      fahrtereignis.progZeit,
      fahrtereignis.sollGleis,
      fahrtereignis.progGleis,
      fahrtereignis.isAusfall,
      fahrtereignis.isZusaetzlich,
      fahrtereignis.allFahrtMerkmal,
    ),
    haltestelle: {
      name: station.title,
      evaNummer: station.evaNumber,
      rl100: null,
    },
    isUnbestimmtVerspaetet: false,
    wagenreihung: mapHafasReihungToWagenreihung(reihung),
  };
}
