export const INTERVAL_REFRESH_JOURNEYS = 5 * 60 * 1000;
export const VAPID_KEY = 'BCKBn0gYGx9xxLfUGLc4U8yVg9b7fe1_ridQ_TlxX_Euylk6g2qIynGyY5aKoz540xFHSZGm66MAGBwkGyQ4TiA';
export const DATE_FORMAT = 'dd.MM.yyyy';

export interface JourneyProductFilter {
  shortName: string;
  longName: string;
  value: number;
}

export interface JourneyProductFilters {
  [k: number]: JourneyProductFilter;
}

export const IonDateTimeMinuteSteps = '0,5,10,15,20,25,30,35,40,45,50,55';

export const NEXT_DEPARTURE_RANGE_IN_MINUTES = 180;

export const JOURNEY_PRODUCT_FILTERS: JourneyProductFilters = {
  1: {
    value: 1,
    longName: 'InterCityExpress',
    shortName: 'ICE',
  },
  2: {
    value: 2,
    longName: 'InterCity & EuroCity',
    shortName: 'IC/EC',
  },
  4: {
    value: 4,
    longName: 'Regionalexpress & InterRegio',
    shortName: 'RE/IR',
  },
  8: {
    value: 8,
    longName: 'Regionalbahn',
    shortName: 'RB',
  },
  16: {
    value: 16,
    longName: 'S-Bahn',
    shortName: 'S',
  },
  32: {
    value: 32,
    longName: 'Bus',
    shortName: 'B',
  },
  64: {
    value: 64,
    longName: 'Fähre',
    shortName: 'F',
  },
  128: {
    value: 128,
    longName: 'U-Bahn',
    shortName: 'U',
  },
  256: {
    value: 256,
    longName: 'Tram',
    shortName: 'T',
  },
  512: {
    value: 512,
    longName: 'Gruppen-Taxi',
    shortName: 'Taxi',
  },
};
