import { Baureihe as HafasBaureihe } from '@/proto/hafas/hafas';
import { Baureihe } from '@/model/baureihe';

export function mapHafasBaureiheToBaureihe(baureihe: HafasBaureihe): Baureihe {
  switch (baureihe) {
    case HafasBaureihe.BR101:
      return Baureihe.BR101;
    case HafasBaureihe.BR146:
      return Baureihe.BR146;
    case HafasBaureihe.BR193:
      return Baureihe.BR193;
    case HafasBaureihe.BR401:
      return Baureihe.BR401;
    case HafasBaureihe.BR401_LDV:
      return Baureihe.BR401_LDV;
    case HafasBaureihe.BR401_9T:
      return Baureihe.BR401_9T;
    case HafasBaureihe.BR402:
      return Baureihe.BR402;
    case HafasBaureihe.BR403:
      return Baureihe.BR403R;
    case HafasBaureihe.BR403R:
      return Baureihe.BR403R;
    case HafasBaureihe.BR403_S1:
      return Baureihe.BR403R;
    case HafasBaureihe.BR403_S2:
      return Baureihe.BR403R;
    case HafasBaureihe.BR406:
      return Baureihe.BR406;
    case HafasBaureihe.BR406R:
      return Baureihe.BR406R;
    case HafasBaureihe.BR407:
      return Baureihe.BR407;
    case HafasBaureihe.BR408:
      return Baureihe.BR408;
    case HafasBaureihe.BR410_1:
      return Baureihe.BR410_1;
    case HafasBaureihe.BR411:
      return Baureihe.BR411;
    case HafasBaureihe.BR411_S1:
      return Baureihe.BR411_S1;
    case HafasBaureihe.BR411_S2:
      return Baureihe.BR411_S2;
    case HafasBaureihe.BR412:
      return Baureihe.BR412;
    case HafasBaureihe.BR412_7T:
      return Baureihe.BR412_7T;
    case HafasBaureihe.BR412_13T:
      return Baureihe.BR412_13T;
    case HafasBaureihe.BR415:
      return Baureihe.BR415;
    case HafasBaureihe.BR423:
      return Baureihe.BR423;
    case HafasBaureihe.BR425:
      return Baureihe.BR425;
    case HafasBaureihe.BR427:
      return Baureihe.BR427;
    case HafasBaureihe.BR430:
      return Baureihe.BR430;
    case HafasBaureihe.BR440:
      return Baureihe.BR440;
    case HafasBaureihe.BR442:
      return Baureihe.BR442;
    case HafasBaureihe.BR446:
      return Baureihe.BR446;
    case HafasBaureihe.BR462:
      return Baureihe.BR462;
    case HafasBaureihe.BR463:
      return Baureihe.BR463;
    case HafasBaureihe.BR612:
      return Baureihe.BR612;
    case HafasBaureihe.BR628:
      return Baureihe.BR628;
    case HafasBaureihe.BR632:
      return Baureihe.BR632;
    case HafasBaureihe.BR633:
      return Baureihe.BR633;
    case HafasBaureihe.BR642:
      return Baureihe.BR642;
    case HafasBaureihe.BR643:
      return Baureihe.BR643;
    case HafasBaureihe.BR650:
      return Baureihe.BR650;
    case HafasBaureihe.IC2_TWIN:
      return Baureihe.IC2_TWIN;
    case HafasBaureihe.IC2_KISS:
      return Baureihe.IC2_KISS;
    case HafasBaureihe.MET:
      return Baureihe.UNBEKANNT;
    case HafasBaureihe.TGV:
      return Baureihe.TGV;
    case HafasBaureihe.EC:
      return Baureihe.EC;
    case HafasBaureihe.IC:
      return Baureihe.IC;
    case HafasBaureihe.RJ:
      return Baureihe.RJ;
    case HafasBaureihe.ETR610:
      return Baureihe.ETR610;
    case HafasBaureihe.NJ:
      return Baureihe.NJ;
    case HafasBaureihe.LINT:
      return Baureihe.BR622;
    case HafasBaureihe.DOSTO:
      return Baureihe.DOSTO;
    case HafasBaureihe.DSB_IC3:
      return Baureihe.DSB_IC3;
    case HafasBaureihe.OEBB_4024:
      return Baureihe.OEBB_4024;
    case HafasBaureihe.OEBB_4746:
      return Baureihe.OEBB_4746;
    case HafasBaureihe.OEBB_1016:
      return Baureihe.OEBB_1016;
    case HafasBaureihe.OEBB_1116:
      return Baureihe.OEBB_1116;
    case HafasBaureihe.OEBB_1216:
      return Baureihe.OEBB_1216;
    case HafasBaureihe.OEBB_CJX:
      return Baureihe.OEBB_CJX;
    case HafasBaureihe.OEBB_DOSTO:
      return Baureihe.OEBB_DOSTO;
    case HafasBaureihe.OEBB_4023:
      return Baureihe.OEBB_4023;
    case HafasBaureihe.UNRECOGNIZED:
    case HafasBaureihe.UNKNOWN:
    default:
      return Baureihe.UNBEKANNT;
  }
}
