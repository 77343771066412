import { type HaltDto } from '@/stores/dto/HaltDto';
import { Halt } from '@/model/halt';
import { mapDtoToNullableFahrtereignis } from '@/mapper/from-store-dto/fahrtereignis';

export function mapDtoToHalt(halt: HaltDto): Halt {
  return new Halt(
    mapDtoToNullableFahrtereignis(halt.abfahrt),
    mapDtoToNullableFahrtereignis(halt.ankunft),
    halt.haltestelle,
    halt.auslastung,
    halt.allMeldung,
  );
}
