import { api } from '@zentrains/api';
import { type AbschnittFahrtereignis } from '@/model/abschnitt';
import { mapExternalFahrtereignis } from '@/mapper/from-api/fahrtereignis';
import { mapExternalWagenreihung } from '@/mapper/from-api/wagenreihung';
import { mapExternalHaltestelle } from '@/mapper/from-api/haltestelle';

export function mapExternalAbschnittFahrtereignis(
  abschnittFahrtereignis: api.AbschnittFahrtereignis,
): AbschnittFahrtereignis {
  const externalFahrtereignis = abschnittFahrtereignis.fahrtereignis;
  const externalHaltestelle = abschnittFahrtereignis.haltestelle;
  if (externalFahrtereignis === undefined) {
    throw 'Fahrtereignis within AbschnittFahrtereignis is undefined, which is illegal';
  }
  if (externalHaltestelle === undefined) {
    throw 'Haltestelle within AbschnittFahrtereignis is undefined, which is illegal';
  }

  const fahrtereignis = mapExternalFahrtereignis(externalFahrtereignis);
  if (fahrtereignis === null) {
    throw 'Fahrtereignis mapping within AbschnittFahrtereignis failed';
  }
  const haltestelle = mapExternalHaltestelle(externalHaltestelle);
  const wagenreihung = abschnittFahrtereignis.wagenreihung;

  return {
    fahrtereignis,
    haltestelle,
    isUnbestimmtVerspaetet: abschnittFahrtereignis.isUnbestimmtVerspaetet,
    wagenreihung: wagenreihung !== undefined ? mapExternalWagenreihung(wagenreihung) : null,
  };
}
