// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.27.2
// source: stop/stop.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";

export const protobufPackage = "stop";

export enum StopType {
  STATION = 0,
  BETRIEBSSTELLE = 1,
  TICKET = 2,
  TRAIN = 3,
  UNRECOGNIZED = -1,
}

export function stopTypeFromJSON(object: any): StopType {
  switch (object) {
    case 0:
    case "STATION":
      return StopType.STATION;
    case 1:
    case "BETRIEBSSTELLE":
      return StopType.BETRIEBSSTELLE;
    case 2:
    case "TICKET":
      return StopType.TICKET;
    case 3:
    case "TRAIN":
      return StopType.TRAIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StopType.UNRECOGNIZED;
  }
}

export function stopTypeToJSON(object: StopType): string {
  switch (object) {
    case StopType.STATION:
      return "STATION";
    case StopType.BETRIEBSSTELLE:
      return "BETRIEBSSTELLE";
    case StopType.TICKET:
      return "TICKET";
    case StopType.TRAIN:
      return "TRAIN";
    case StopType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface OmniStop {
  evaNumber: string;
  name: string;
  ril100: string;
  stopType: StopType;
}

export interface ByEvaNumberRequest {
  evaNumber: string;
}

export interface OmniSearchRequest {
  name: string;
  limit: number;
  filterForIris: boolean;
  searchBetriebsstelle: boolean;
  showTicketOrTrain: boolean;
}

export interface OmniSearchResponse {
  stops: OmniStop[];
}

function createBaseOmniStop(): OmniStop {
  return { evaNumber: "", name: "", ril100: "", stopType: 0 };
}

export const OmniStop = {
  encode(message: OmniStop, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.evaNumber !== "") {
      writer.uint32(10).string(message.evaNumber);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.ril100 !== "") {
      writer.uint32(26).string(message.ril100);
    }
    if (message.stopType !== 0) {
      writer.uint32(32).int32(message.stopType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OmniStop {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOmniStop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ril100 = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.stopType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OmniStop {
    return {
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ril100: isSet(object.ril100) ? globalThis.String(object.ril100) : "",
      stopType: isSet(object.stopType) ? stopTypeFromJSON(object.stopType) : 0,
    };
  },

  toJSON(message: OmniStop): unknown {
    const obj: any = {};
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ril100 !== "") {
      obj.ril100 = message.ril100;
    }
    if (message.stopType !== 0) {
      obj.stopType = stopTypeToJSON(message.stopType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OmniStop>, I>>(base?: I): OmniStop {
    return OmniStop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OmniStop>, I>>(object: I): OmniStop {
    const message = createBaseOmniStop();
    message.evaNumber = object.evaNumber ?? "";
    message.name = object.name ?? "";
    message.ril100 = object.ril100 ?? "";
    message.stopType = object.stopType ?? 0;
    return message;
  },
};

function createBaseByEvaNumberRequest(): ByEvaNumberRequest {
  return { evaNumber: "" };
}

export const ByEvaNumberRequest = {
  encode(message: ByEvaNumberRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.evaNumber !== "") {
      writer.uint32(10).string(message.evaNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ByEvaNumberRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseByEvaNumberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ByEvaNumberRequest {
    return { evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "" };
  },

  toJSON(message: ByEvaNumberRequest): unknown {
    const obj: any = {};
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ByEvaNumberRequest>, I>>(base?: I): ByEvaNumberRequest {
    return ByEvaNumberRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ByEvaNumberRequest>, I>>(object: I): ByEvaNumberRequest {
    const message = createBaseByEvaNumberRequest();
    message.evaNumber = object.evaNumber ?? "";
    return message;
  },
};

function createBaseOmniSearchRequest(): OmniSearchRequest {
  return { name: "", limit: 0, filterForIris: false, searchBetriebsstelle: false, showTicketOrTrain: false };
}

export const OmniSearchRequest = {
  encode(message: OmniSearchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.limit !== 0) {
      writer.uint32(16).uint32(message.limit);
    }
    if (message.filterForIris !== false) {
      writer.uint32(24).bool(message.filterForIris);
    }
    if (message.searchBetriebsstelle !== false) {
      writer.uint32(32).bool(message.searchBetriebsstelle);
    }
    if (message.showTicketOrTrain !== false) {
      writer.uint32(40).bool(message.showTicketOrTrain);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OmniSearchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOmniSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.filterForIris = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.searchBetriebsstelle = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.showTicketOrTrain = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OmniSearchRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      filterForIris: isSet(object.filterForIris) ? globalThis.Boolean(object.filterForIris) : false,
      searchBetriebsstelle: isSet(object.searchBetriebsstelle)
        ? globalThis.Boolean(object.searchBetriebsstelle)
        : false,
      showTicketOrTrain: isSet(object.showTicketOrTrain) ? globalThis.Boolean(object.showTicketOrTrain) : false,
    };
  },

  toJSON(message: OmniSearchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.filterForIris !== false) {
      obj.filterForIris = message.filterForIris;
    }
    if (message.searchBetriebsstelle !== false) {
      obj.searchBetriebsstelle = message.searchBetriebsstelle;
    }
    if (message.showTicketOrTrain !== false) {
      obj.showTicketOrTrain = message.showTicketOrTrain;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OmniSearchRequest>, I>>(base?: I): OmniSearchRequest {
    return OmniSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OmniSearchRequest>, I>>(object: I): OmniSearchRequest {
    const message = createBaseOmniSearchRequest();
    message.name = object.name ?? "";
    message.limit = object.limit ?? 0;
    message.filterForIris = object.filterForIris ?? false;
    message.searchBetriebsstelle = object.searchBetriebsstelle ?? false;
    message.showTicketOrTrain = object.showTicketOrTrain ?? false;
    return message;
  },
};

function createBaseOmniSearchResponse(): OmniSearchResponse {
  return { stops: [] };
}

export const OmniSearchResponse = {
  encode(message: OmniSearchResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.stops) {
      OmniStop.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OmniSearchResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOmniSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stops.push(OmniStop.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OmniSearchResponse {
    return { stops: globalThis.Array.isArray(object?.stops) ? object.stops.map((e: any) => OmniStop.fromJSON(e)) : [] };
  },

  toJSON(message: OmniSearchResponse): unknown {
    const obj: any = {};
    if (message.stops?.length) {
      obj.stops = message.stops.map((e) => OmniStop.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OmniSearchResponse>, I>>(base?: I): OmniSearchResponse {
    return OmniSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OmniSearchResponse>, I>>(object: I): OmniSearchResponse {
    const message = createBaseOmniSearchResponse();
    message.stops = object.stops?.map((e) => OmniStop.fromPartial(e)) || [];
    return message;
  },
};

export interface StopService {
  OmniSearch(request: DeepPartial<OmniSearchRequest>, metadata?: grpc.Metadata): Promise<OmniSearchResponse>;
  ByEvaNumber(request: DeepPartial<ByEvaNumberRequest>, metadata?: grpc.Metadata): Promise<OmniStop>;
}

export class StopServiceClientImpl implements StopService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.OmniSearch = this.OmniSearch.bind(this);
    this.ByEvaNumber = this.ByEvaNumber.bind(this);
  }

  OmniSearch(request: DeepPartial<OmniSearchRequest>, metadata?: grpc.Metadata): Promise<OmniSearchResponse> {
    return this.rpc.unary(StopServiceOmniSearchDesc, OmniSearchRequest.fromPartial(request), metadata);
  }

  ByEvaNumber(request: DeepPartial<ByEvaNumberRequest>, metadata?: grpc.Metadata): Promise<OmniStop> {
    return this.rpc.unary(StopServiceByEvaNumberDesc, ByEvaNumberRequest.fromPartial(request), metadata);
  }
}

export const StopServiceDesc = { serviceName: "stop.StopService" };

export const StopServiceOmniSearchDesc: UnaryMethodDefinitionish = {
  methodName: "OmniSearch",
  service: StopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return OmniSearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = OmniSearchResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StopServiceByEvaNumberDesc: UnaryMethodDefinitionish = {
  methodName: "ByEvaNumber",
  service: StopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ByEvaNumberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = OmniStop.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
