import { HafasMessage, IrisMessage, IrisMessageType } from '@/proto/iris/iris';
import { IrisMessage as HafasIrisMessage, IrisMessageType as HafasIrisMessageType } from '@/proto/hafas/hafas';
import { type HimMeldung, MeldungQuelle, type RisMeldung, RisMeldungTyp } from '@/model/meldung';

function mapIrisMeldungTyp(type: IrisMessageType | HafasIrisMessageType): RisMeldungTyp {
  switch (type) {
    case IrisMessageType.DELAY:
      return RisMeldungTyp.VERSPAETUNG;
    case IrisMessageType.QOS:
      return RisMeldungTyp.SERVICEQUALITAET;
    case IrisMessageType.UNRECOGNIZED:
    default:
      return RisMeldungTyp.VERSPAETUNG;
  }
}

export function mapIrisMessageToMeldung(irisMessage: IrisMessage | HafasIrisMessage): RisMeldung {
  return {
    quelle: MeldungQuelle.RIS,
    typ: mapIrisMeldungTyp(irisMessage.type),
    id: `${irisMessage.timestamp}`,
    zeitpunkt: new Date(irisMessage.timestamp),
    code: irisMessage.code,
    isErsetzt: irisMessage.superseded,
  };
}
export function mapIrisHafasMessageToMeldung(message: HafasMessage): HimMeldung {
  return {
    quelle: MeldungQuelle.HIM,
    id: '',
    titel: message.summary,
    text: message.text,
  };
}
