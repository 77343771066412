import * as t from 'io-ts';
import { Route, TrainDetails, TrainDetailsWithSearchQuery } from '@/proto/hafas/hafas';
import { type ReiseDto, type ReiseIdentifikationDto } from '@/stores/dto/ReiseDto';
import { type Reise, ReiseIdentifikation, ReiseQuelle } from '@/model/reise';
import { type Abschnitt } from '@/model/abschnitt';
import type { Haltestelle } from '@/model/haltestelle';
import type { OmniItem } from '@/model/omniSuche';

// region State

export enum Backend {
  LEGACY,
  LOCAL,
  DEV,
  PRD,
}

export interface BackendItem {
  title: string;
  value: Backend;
}

export interface BackendState {
  selectedBackend: Backend;
}

export interface RouteEntries {
  [reiseId: string]: Route;
}

export interface JourneyAssistanceState {
  ctxReconList: string[];
  routes: RouteEntries;
  updating: boolean;
  migrated: boolean;
}

export interface ReiseIdToReiseDto {
  [reiseId: string]: ReiseDto;
}

export interface ReiseBegleitungState {
  allReiseIdDto: ReiseIdentifikationDto[];
  reiseIdToReiseDto: ReiseIdToReiseDto;
  updating: boolean;
}

export interface PickupAssistanceState {
  allPickup: Pickup[];
  reiseIdToReiseDto: ReiseIdToReiseDto;
  updating: boolean;
}

export interface Pickup {
  name: string;
  reiseId: ReiseIdentifikation;
  coach: string;
}

export interface LastAbschnittPickup {
  pickup: Pickup;
  lastLeg: Abschnitt;
}

export interface ReiseIdToLastAbschnittPickupMap {
  [reiseId: string]: LastAbschnittPickup;
}

export interface RouteReplacePayload {
  oldReiseId: string;
  newReiseId: string;
}

export interface OmniSearchState {
  query: string;
  loading: boolean;
  searchFieldInFocus: boolean;
  allItem: OmniItem[];
}

export interface RouteCacheState {
  allCachedRoute: Map<ReiseIdentifikation, RouteCacheEntry>;
}

export interface RouteCacheEntry {
  reise: Reise | null;
  isUpdating: boolean;
  lastUpdated: number;
}

export interface RouteSearchVia {
  stop: Haltestelle;
  duration: number;
}

export const RouteSearchMinimalVia = t.exact(
  t.type({
    stop: t.string,
    duration: t.number,
  }),
);

export const JourneyFilter = t.exact(
  t.type({
    value: t.string,
    mode: t.string,
    type: t.string,
  }),
);

export type RouteSearchMinimalVia = t.TypeOf<typeof RouteSearchMinimalVia>;
export type JourneyFilter = t.TypeOf<typeof JourneyFilter>;

export const RouteSearchVias = t.array(RouteSearchMinimalVia);
export const JourneyFilters = t.array(JourneyFilter);

export interface RouteSearchState {
  allReise: Reise[];
  lastQuelle: ReiseQuelle;
  from: Haltestelle | null;
  to: Haltestelle | null;
  vias: RouteSearchVia[];
  journeyFilter: JourneyFilter[];
  date: Date;
  onlyFastestRoutes: boolean;
  minTransferTime: number;
  maxTransfers: number;
  loading: boolean;
  loadingEarlier: boolean;
  loadingLater: boolean;
  earlierReiseId: string;
  laterReiseId: string;
  allowsBicycles: boolean;
  allowsNetzcard: boolean;
}

export interface StationBoardState {
  trainDetails: {
    [k: string]: TrainDetails;
  };
  trainDetailsQueue: {
    [k: string]: TrainDetailsWithSearchQuery;
  };
}

export interface StationMRUEntry {
  station: Haltestelle;
  lastUsed: number;
  timesUsed: number;
}

export interface StationMRUEntries {
  [id: string]: StationMRUEntry;
}

export interface StationMRUState {
  stations: StationMRUEntries;
}

export interface SettingsState {
  pushNotificationsActive: boolean;
  thresholdChangeNotificationMinutes: number;
  thresholdDepartureNotificationMinutes: number;
  thresholdFetchNotificationMinutes: number;
  checkinActive: boolean;
  searchForBetriebsstelle: boolean;
  showDetails: boolean;
  traewellingOAuth: TraewellingOAuth;
  travelynxToken: string;
  unlockCodes: string;
}

export interface TraewellingOAuth {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  expiry: number;
}

export interface VersionState {
  lastVersion: string;
}

export interface NotificationState {
  pushToken: string;
  activePushMethod: ActivePushMethod;
}

export enum ActivePushMethod {
  NONE,
  SERVICE_WORKER,
  NATIVE,
}

// endregion State
