<template>
  <ion-item @click="goToSearch">
    <ion-icon slot="start" :icon="searchSharp" class="search-icon" />
    <ion-input
      v-model="query"
      :autofocus="autofocus || false"
      :debounce="500"
      enterkeyhint="search"
      inputmode="search"
      label="Station, Ticket, Zug, Betriebsstelle"
      label-placement="floating"
      placeholder="München Hbf, ABCDEF, ICE 628, MH"
      type="search"
      @focus="goToSearch"
      @ion-blur="setSearchFieldInFocus(false)"
      @ion-focus="setSearchFieldInFocus(true)"
    />
  </ion-item>
</template>

<script lang="ts">
import { IonIcon, IonInput, IonItem } from '@ionic/vue';
import { computed } from 'vue';
import { ROUTE } from '@/router';
import { useRoute, useRouter } from 'vue-router';
import { searchSharp } from 'ionicons/icons';
import { useOmniSearchStore } from '@/stores/omniSearch';

export default {
  name: 'OmniSearchBar',
  components: {
    IonIcon,
    IonInput,
    IonItem,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const omniSearchStore = useOmniSearchStore();
    const query = computed({
      get: () => omniSearchStore.query,
      set: (val) => omniSearchStore.setQueryAndFetchStops(val),
    });

    const route = useRoute();
    const router = useRouter();
    const goToSearch = () => {
      if (route.name !== ROUTE.OMNI_SEARCH) {
        router.push({
          name: ROUTE.OMNI_SEARCH,
        });
      }
    };

    const setSearchFieldInFocus = (val: boolean) => {
      omniSearchStore.searchFieldInFocus = val;
    };

    return {
      goToSearch,
      query,
      setSearchFieldInFocus,
      searchSharp,
    };
  },
};
</script>

<style scoped lang="scss">
.search-icon {
  margin-inline-end: 8px;
}
ion-item {
  --padding-start: 8px;
}
</style>
