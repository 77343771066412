import { RemL } from '@/proto/hafas/hafas';
import { type HimMeldung, MeldungQuelle } from '@/model/meldung';

export function mapHafasMessageToMeldung(message: RemL): HimMeldung {
  return {
    quelle: MeldungQuelle.HIM,
    id: message.code !== '' ? message.code : message.type,
    titel: message.summary,
    text: message.text,
  };
}
