import { defineStore } from 'pinia';
import { Backend, type OmniSearchState } from '@/stores/types';
import { OmniSearchRequest } from '@/proto/stop/stop';
import { useSettingsStore } from '@/stores/settings';
import { grpc } from '@/proto/rpc';
import { mapLegacyOmniStop } from '@/mapper/from-omni/omniStop';
import { useBackendStore } from '@/stores/backend';
import { mapExternalOmniItem } from '@/mapper/from-api/omniSuche';

export const useOmniSearchStore = defineStore('omniSearch', {
  state: (): OmniSearchState => ({
    query: '',
    loading: false,
    searchFieldInFocus: false,
    allItem: [],
  }),
  actions: {
    async setQueryAndFetchStops(query: string) {
      if (this.searchFieldInFocus) {
        this.query = query;
        this.loading = true;
        const settings = useSettingsStore();
        const backendStore = useBackendStore();
        if (backendStore.selectedBackend === Backend.LEGACY) {
          const omniSearchRequest: OmniSearchRequest = {
            name: this.query,
            limit: 10,
            filterForIris: true,
            searchBetriebsstelle: settings.searchForBetriebsstelle === true,
            showTicketOrTrain: true,
          };
          try {
            const response = await grpc.OmniSearch(omniSearchRequest);
            if (response.isSuccess) {
              this.allItem = response.data.map(mapLegacyOmniStop);
            }
          } finally {
            this.loading = false;
          }
        } else {
          try {
            const response = await backendStore.client.omniSearch({
              query: this.query,
            });
            this.allItem = response.allItem.map(mapExternalOmniItem);
          } finally {
            this.loading = false;
          }
        }
      }
    },
  },
});
