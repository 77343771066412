import { api } from '@zentrains/api';
import { Fahrtereignis } from '@/model/fahrtereignis';
import { filterNotNull } from '@/mapper/from-api/helper';
import { mapExternalFahrtmerkmal } from '@/mapper/from-api/fahrt';

export function mapExternalFahrtereignis(externalFahrtereignis: api.Fahrtereignis | null): Fahrtereignis | null {
  if (externalFahrtereignis === null) {
    return null;
  }

  const externalZeit: api.Zeit | undefined = externalFahrtereignis.zeit;
  if (externalZeit === undefined) {
    throw 'Zeit was undefined in Fahrtereignis, which was unexpected';
  }

  const progGleis: string | undefined = externalFahrtereignis.progGleis;
  return new Fahrtereignis(
    new Date(externalZeit.sollZeit),
    externalZeit.progZeit ? new Date(externalZeit.progZeit) : null,
    externalFahrtereignis.sollGleis,
    progGleis !== undefined ? progGleis : null,
    externalFahrtereignis.isAusfall,
    externalFahrtereignis.isZusaetzlich,
    filterNotNull(externalFahrtereignis.allFahrtMerkmal.map(mapExternalFahrtmerkmal)),
  );
}
