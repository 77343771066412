import { api } from '@zentrains/api';
import type { Haltestelle } from '@/model/haltestelle';

export function mapExternalHaltestelle(externalHaltestelle: api.Haltestelle): Haltestelle {
  return {
    name: externalHaltestelle.name,
    evaNummer: externalHaltestelle.evaNummer,
    rl100: externalHaltestelle.rl100 || null,
  };
}
