import type { ReiseIdentifikation } from '@/model/reise';
import type { Haltestelle } from '@/model/haltestelle';

export type OmniItem = OmniBetriebsstelle | OmniHaltestelle | OmniTicket | OmniZug;

export enum OmniTyp {
  HALTESTELLE,
  BETRIEBSSTELLE,
  ZUG,
  TICKET,
}

export interface OmniBetriebsstelle {
  typ: OmniTyp.BETRIEBSSTELLE;
  name: string;
  rl100: string;
  betriebsstelleTyp: string | null;
}

export interface OmniHaltestelle {
  typ: OmniTyp.HALTESTELLE;
  name: string;
  evaNummer: string;
  rl100: string | null;
}

export interface OmniTicket {
  typ: OmniTyp.TICKET;
  ticketId: string;
}

export interface OmniZug {
  typ: OmniTyp.ZUG;
  reiseId: ReiseIdentifikation;
  name: string;
  startHalt: string;
  zielHalt: string;
}

export function omnifyHaltestelle(haltestelle: Haltestelle): OmniHaltestelle {
  return {
    typ: OmniTyp.HALTESTELLE,
    name: haltestelle.name,
    evaNummer: haltestelle.evaNummer,
    rl100: haltestelle.rl100,
  };
}
