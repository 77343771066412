/* eslint-disable no-console */

import { register } from 'register-service-worker';
import ENVIRONMENT from '@/env/environment';
import SERVICE_WORKER_ACTIVE from '@/env/serviceWorkerActive';
import { type Ref } from 'vue';

export function registerServiceWorker(
  registrationRef: Ref<ServiceWorkerRegistration | null>,
  enableUpdating: () => void,
) {
  if (ENVIRONMENT === 'production' && SERVICE_WORKER_ACTIVE === 'true') {
    register(`${import.meta.env.BASE_URL}service-worker.js`, {
      registrationOptions: {
        type: 'module',
      },
      ready() {
        console.log('ZenTrains Service Worker is ready.');
      },
      registered(registration: ServiceWorkerRegistration) {
        console.log('ZenTrains Service Worker has been registered.');
        setInterval(
          () => {
            // noinspection JSIgnoredPromiseFromCall
            registration.update();
          },
          1000 * 60 * 60,
        );
        registrationRef.value = registration;
      },
      cached() {
        console.log('ZenTrains Service Worker has cached content for offline use.');
      },
      updatefound() {
        console.log('ZenTrains Service Worker is downloading updates.');
      },
      updated(registration: ServiceWorkerRegistration) {
        console.log('ZenTrains Service Worker has received updates, waiting for refresh.');
        registrationRef.value = registration;
        enableUpdating();
      },
      offline() {
        console.log('No internet connection found. ZenTrains is running in offline mode.');
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });
  }
}
