import { Auslastung as HafasAuslastung, AuslastungsValue } from '@/proto/hafas/hafas';
import { type Auslastung } from '@/model/auslastung';
import { AuslastungWert } from '@/model/common';

export function mapHafasAuslastungToAuslastung(auslastung: HafasAuslastung | undefined): Auslastung | null {
  if (auslastung === undefined) {
    return null;
  }
  return {
    ersteKlasse: mapHafasAuslastungValueToAuslastungWert(auslastung.first),
    zweiteKlasse: mapHafasAuslastungValueToAuslastungWert(auslastung.second),
  };
}

function mapHafasAuslastungValueToAuslastungWert(auslastungValue: AuslastungsValue): AuslastungWert {
  switch (auslastungValue) {
    case AuslastungsValue.UNRECOGNIZED:
    case AuslastungsValue.UNBEKANNT:
      return AuslastungWert.Unbekannt;
    case AuslastungsValue.GERING:
      return AuslastungWert.Gering;
    case AuslastungsValue.HOCH:
      return AuslastungWert.Hoch;
    case AuslastungsValue.SEHR_HOCH:
      return AuslastungWert.SehrHoch;
    case AuslastungsValue.AUSGEBUCHT:
      return AuslastungWert.Ausgebucht;
  }
}
