import {
  type ActionPerformed,
  type PushNotificationSchema,
  type Token,
  ZenTrainsPush,
} from '@/capacitor-plugins/ZenTrainsPush';
import { computed, watch } from 'vue';
import * as Sentry from '@sentry/browser';
import { Toast } from '@capacitor/toast';
import SERVICE_WORKER_ACTIVE from '@/env/serviceWorkerActive';
import { useSettingsStore } from '@/stores/settings';
import { useNotificationsStore } from '@/stores/notifications';
import { ActivePushMethod } from '@/stores/types';

function setupMessageReception() {
  // Show us the notification payload if the app is open on our device
  ZenTrainsPush.addListener(
    'pushNotificationReceived',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_: PushNotificationSchema) => {
      // noop
    },
  );

  // Method called when tapping on a notification
  ZenTrainsPush.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    alert('Push action performed: ' + JSON.stringify(notification));
  });
}

export default function useNativePushNotifications() {
  const notificationsStore = useNotificationsStore();
  const settingsStore = useSettingsStore();
  const pushNotificationsActive = computed((): boolean => settingsStore.pushNotificationsActive);
  watch(pushNotificationsActive, async (isPushNotificationActive) => {
    if (isPushNotificationActive) {
      try {
        if (notificationsStore.activePushMethod !== ActivePushMethod.NONE) {
          setupMessageReception();
          return;
        }
        const permissions = await ZenTrainsPush.requestPermissions();
        if (permissions.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          await ZenTrainsPush.register();
        } else {
          await Toast.show({
            text: 'Berechtigung für Benachrichtigungen wurde nicht gegeben.',
            duration: 'long',
          });
        }

        // On success, we should be able to receive notifications
        ZenTrainsPush.addListener('registration', async (token: Token) => {
          notificationsStore.activatePush(token.value, ActivePushMethod.NATIVE);
          await notificationsStore.sendPushTokenToServer();
        });

        // Some issue with our setup and push will not work
        ZenTrainsPush.addListener('registrationError', async (error: any) => {
          Sentry.captureMessage(error, 'error');
          await Toast.show({
            text: 'Push-Benachrichtigungen konnten nicht aktiviert werden.',
            duration: 'long',
          });
          if (notificationsStore.activePushMethod === ActivePushMethod.NONE) {
            settingsStore.pushNotificationsActive = false;
          }
        });
        setupMessageReception();
      } catch (e) {
        if (SERVICE_WORKER_ACTIVE === 'false') {
          Sentry.captureException(e);
          await Toast.show({
            text: 'Push-Benachrichtigungen werden auf deinem Gerät leider nicht unterstützt.',
            duration: 'long',
          });
          if (notificationsStore.activePushMethod === ActivePushMethod.NONE) {
            settingsStore.pushNotificationsActive = false;
          }
        }
      }
    } else if (notificationsStore.activePushMethod !== ActivePushMethod.NONE) {
      await notificationsStore.deactivatePush();
    }
  });
}
