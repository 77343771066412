import { type AbschnittFahrtereignisDto } from '@/stores/dto/AbschnittFahrtereignisDto';
import { type AbschnittFahrtereignis } from '@/model/abschnitt';
import { mapDtoToFahrtereignis } from '@/mapper/from-store-dto/fahrtereignis';

export function mapDtoToAbschnittFahrtereignis(fahrtereignis: AbschnittFahrtereignisDto): AbschnittFahrtereignis {
  return {
    fahrtereignis: mapDtoToFahrtereignis(fahrtereignis.fahrtereignis),
    haltestelle: fahrtereignis.haltestelle,
    isUnbestimmtVerspaetet: fahrtereignis.isUnbestimmtVerspaetet,
    wagenreihung: fahrtereignis.wagenreihung,
  };
}
