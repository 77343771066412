import { Fahrt } from '@/model/fahrt';
import { type FahrtDto } from '@/stores/dto/FahrtDto';

export function mapFahrtToDto(fahrt: Fahrt): FahrtDto {
  return {
    gattung: fahrt.gattung,
    linie: fahrt.linie,
    fahrtNummer: fahrt.fahrtNummer,
    betreiber: fahrt.betreiber,
    isZusatzfahrt: fahrt.isZusatzfahrt,
    allFahrtMerkmal: fahrt.allFahrtMerkmal,
    zielHalt: fahrt.zielHalt,
    startZeit: fahrt.startZeit !== null ? fahrt.startZeit.valueOf() : null,
  };
}
