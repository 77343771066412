import { Train } from '@/proto/hafas/hafas';
import { Fahrt, FahrtMerkmal } from '@/model/fahrt';

export function mapHafasTrainToFahrt(
  train: Train | undefined,
  finalDestination: string,
  isErsatzzug: boolean,
  isSonderfahrt: boolean,
  allowsBicycles: boolean,
  allowsNetzcard: boolean,
): Fahrt {
  if (train === undefined) {
    throw 'train of Hafas Leg was undefined, which is not allowed';
  }
  const allFahrtMerkmal: FahrtMerkmal[] = [];
  if (allowsBicycles) {
    allFahrtMerkmal.push(FahrtMerkmal.FahrradmitnahmeMoeglich);
  }
  if (allowsNetzcard) {
    allFahrtMerkmal.push(FahrtMerkmal.MitNetzcardFahrbar);
  }
  return new Fahrt(
    {
      betreiberId: train.operatorId,
      name: train.operator,
    },
    train.type,
    train.number,
    train.line,
    finalDestination,
    isErsatzzug || isSonderfahrt,
    allFahrtMerkmal,
    null,
  );
}
