import { type Abschnitt, AbschnittTyp, UmstiegBewertung } from '@/model/abschnitt';
import { ChangeRating, Leg, LegType } from '@/proto/hafas/hafas';
import { mapHafasStopToHalt } from '@/mapper/from-hafas/halt';
import { type Duration } from 'date-fns';
import { mapHafasTrainToFahrt } from '@/mapper/from-hafas/fahrt';
import { mapHafasLegStopInfoToAbschnittFahrtereignis } from '@/mapper/from-hafas/abschnittFahrtereignis';
import { mapHafasAuslastungToAuslastung } from '@/mapper/from-hafas/auslastung';
import { mapIrisMessageToMeldung } from '@/mapper/from-iris/meldung';
import { mapHafasMessageToMeldung } from '@/mapper/from-hafas/meldung';

export function mapHafasLegToAbschnitt(leg: Leg): Abschnitt {
  const departure = leg.departure !== undefined ? leg.departure : leg.stops[0].departure;
  const arrival = leg.arrival !== undefined ? leg.arrival : leg.stops[leg.stops.length - 1].arrival;
  return {
    fahrtId: leg.journeyId,
    typ: mapHafasLegTypeToAbschnittTyp(leg.type),
    dauer: mapHafasDurationToDuration(leg.duration),
    fahrt: mapHafasTrainToFahrt(
      leg.train,
      leg.finalDestination,
      leg.isErsatzzug,
      leg.isSonderfahrt,
      leg.allowsBicycles,
      leg.allowsNetzcard,
    ),
    auslastung: mapHafasAuslastungToAuslastung(leg.auslastung),
    umstiegBewertung: mapHafasChangeRatingToUmstiegBewertung(leg.changeRating),
    isAusfall: leg.cancelled,
    isErreichbar: leg.isReachable,
    abfahrt: mapHafasLegStopInfoToAbschnittFahrtereignis(departure, leg.segmentStart, leg.coachSequenceDeparture),
    ankunft: mapHafasLegStopInfoToAbschnittFahrtereignis(arrival, leg.segmentDestination, leg.coachSequenceArrival),
    umstiegDauer: mapHafasChangeDurationToDuration(leg.changeDuration),
    allFluegel: leg.wings.map(mapHafasLegToAbschnitt),
    allHalt: leg.stops.map(mapHafasStopToHalt),
    allMeldung: [...leg.irisMessages.map(mapIrisMessageToMeldung), ...leg.messages.map(mapHafasMessageToMeldung)],
  };
}

export function mapHafasChangeRatingToUmstiegBewertung(changeRating: ChangeRating): UmstiegBewertung {
  switch (changeRating) {
    case ChangeRating.UNRECOGNIZED:
    case ChangeRating.CONNECTION_UNKNOWN:
      return UmstiegBewertung.Unbekannt;
    case ChangeRating.CONNECTION_SECURE:
      return UmstiegBewertung.Gesichert;
    case ChangeRating.CONNECTION_PROBABLE:
      return UmstiegBewertung.Wahrscheinlich;
    case ChangeRating.CONNECTION_NO_INFO:
      return UmstiegBewertung.KeineInformation;
    case ChangeRating.CONNECTION_IMPROBABLE:
      return UmstiegBewertung.Gefaehrdet;
    case ChangeRating.CONNECTION_DENIED:
      return UmstiegBewertung.Abgelehnt;
  }
}

export function mapHafasLegTypeToAbschnittTyp(type: LegType): AbschnittTyp {
  switch (type) {
    case LegType.TRAIN:
      return AbschnittTyp.Fahrt;
    case LegType.CHECKIN:
    case LegType.CHECKOUT:
    case LegType.DEVIATION:
    case LegType.TRANSFER:
      return AbschnittTyp.Transfer;
    case LegType.WALK:
      return AbschnittTyp.Fussweg;
    default:
      return AbschnittTyp.Fahrt;
  }
}

export function mapHafasChangeDurationToDuration(duration: number): Duration {
  return {
    days: Math.floor(duration / 1440),
    hours: Math.floor((duration % 1440) / 60),
    minutes: duration % 60,
  };
}

export function mapHafasDurationToDuration(duration: number): Duration {
  return {
    days: Math.floor(duration / 86400),
    hours: Math.floor((duration % 86400) / 3600),
    minutes: (duration % 3600) / 60,
  };
}
