import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { ROUTE } from '@/router';

export default function useOmniSearchBar() {
  const route = useRoute();
  const shouldShowOmniSearchBar = computed(() => {
    switch (route.name) {
      case ROUTE.HOME:
      case ROUTE.OMNI_SEARCH:
      case ROUTE.STATION_BOARD:
      case ROUTE.TICKET_DETAILS:
      case ROUTE.TRAIN_DETAILS:
        return true;
      default:
        return false;
    }
  });
  return {
    shouldShowOmniSearchBar,
  };
}
