import { api } from '@zentrains/api';
import { type Meldung, MeldungQuelle, RisMeldungTyp } from '@/model/meldung';

export function mapExternalMeldung(externalMeldung: api.Meldung): Meldung {
  if (externalMeldung.himMeldung) {
    return {
      id: externalMeldung.id,
      quelle: MeldungQuelle.HIM,
      titel: externalMeldung.himMeldung.titel,
      text: externalMeldung.himMeldung.text,
    };
  } else if (externalMeldung.risMeldung) {
    return {
      id: externalMeldung.id,
      quelle: MeldungQuelle.RIS,
      typ: mapExternalRisMeldungTyp(externalMeldung.risMeldung.risMeldungTyp),
      isErsetzt: externalMeldung.risMeldung.isErsetzt,
      zeitpunkt: new Date(externalMeldung.risMeldung.zeitpunkt),
      code: externalMeldung.risMeldung.code,
    };
  }
  throw 'External Meldung has neither himMeldung nor risMeldung set, which is illegal';
}

export function mapExternalRisMeldungTyp(externalTyp: api.RisMeldungTyp): RisMeldungTyp {
  switch (externalTyp) {
    case api.RisMeldungTyp.Verspaetung:
      return RisMeldungTyp.VERSPAETUNG;
    case api.RisMeldungTyp.ServiceQualitaet:
      return RisMeldungTyp.SERVICEQUALITAET;
    case api.RisMeldungTyp.UNRECOGNIZED:
    default:
      return RisMeldungTyp.SERVICEQUALITAET;
  }
}
