import { Halt } from '@/model/halt';
import { Stop } from '@/proto/hafas/hafas';
import { mapHafasStopInfoToFahrtereignis } from '@/mapper/from-hafas/fahrtereignis';

import { mapHafasAuslastungToAuslastung } from '@/mapper/from-hafas/auslastung';
import { mapIrisMessageToMeldung } from '@/mapper/from-iris/meldung';
import { mapHafasMessageToMeldung } from '@/mapper/from-hafas/meldung';
import type { Haltestelle } from '@/model/haltestelle';

export function mapHafasStopToHalt(stop: Stop): Halt {
  const haltestelle: Haltestelle = {
    name: stop.station?.title || '',
    evaNummer: stop.station?.evaNumber || '',
    rl100: null,
  };
  return new Halt(
    mapHafasStopInfoToFahrtereignis(stop.departure, stop.cancelled, stop.additional),
    mapHafasStopInfoToFahrtereignis(stop.arrival, stop.cancelled, stop.additional),
    haltestelle,
    mapHafasAuslastungToAuslastung(stop.auslastung),
    [...stop.irisMessages.map(mapIrisMessageToMeldung), ...stop.messages.map(mapHafasMessageToMeldung)],
  );
}
