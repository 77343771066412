import { api } from '@zentrains/api';
import {
  Fahrtrichtung,
  type Sektor,
  type Wagen,
  type WagenGruppe,
  type Wagenreihung,
  WagenStatus,
} from '@/model/wagenreihung';
import { mapExternalAuslastungWert } from '@/mapper/from-api/auslastung';
import { mapExternalSitzplatzGruppe } from '@/mapper/from-api/sonderSitzplatz';
import { mapExternalWagenCSS } from '@/mapper/from-api/wagenCSS';
import { filterNotNull } from '@/mapper/from-api/helper';
import { mapExternalBaureihe } from '@/mapper/from-api/baureihe';

export function mapExternalWagenreihung(externalWagenreihung: api.Wagenreihung): Wagenreihung {
  return {
    isCached: externalWagenreihung.isCached,
    quelle: mapExternalWagenreihungQuelle(externalWagenreihung.quelle),
    fahrtGattung: externalWagenreihung.fahrtGattung,
    fahrtNummer: externalWagenreihung.fahrtNummer,
    hasFluegel: externalWagenreihung.hasFluegel,
    isMatchingPlan: externalWagenreihung.isMatchingPlan,
    fahrtrichtung: mapExternalFahrtrichtung(externalWagenreihung.fahrtrichtung),
    anfangAmHalt: 0,
    skalierung: 1,
    allSektor: externalWagenreihung.allSektor.map(mapExternalSektor),
    allWagenGruppe: externalWagenreihung.allWagenGruppe.map(mapExternalWagenGruppe),
  };
}

export function mapExternalWagenreihungQuelle(quelle: api.WagenreihungQuelle): string {
  switch (quelle) {
    case api.WagenreihungQuelle.WR_DB_BUSINESS_NAVIGATOR:
      return 'DB Business Navigator';
    case api.WagenreihungQuelle.WR_DB_NAVIGATOR:
      return 'DB Navigator';
    case api.WagenreihungQuelle.WR_BAHNHOF_LIVE:
      return 'Bahnhof Live';
    case api.WagenreihungQuelle.WR_BAHN_EXPERT:
      return 'bahn.expert';
    case api.WagenreihungQuelle.WR_OEBB:
      return 'ÖBB Live';
    case api.WagenreihungQuelle.WR_SBB:
      return 'SBB Mobile';
    case api.WagenreihungQuelle.UNRECOGNIZED:
    default:
      return 'unbekannt';
  }
}

export function mapExternalFahrtrichtung(fahrtrichtung: api.Fahrtrichtung): Fahrtrichtung {
  switch (fahrtrichtung) {
    case api.Fahrtrichtung.FAHRTRICHTUNG_LINKS:
      return Fahrtrichtung.LINKS;
    case api.Fahrtrichtung.FAHRTRICHTUNG_RECHTS:
      return Fahrtrichtung.RECHTS;
  }
  // TODO: capture Sentry error
  return Fahrtrichtung.LINKS;
}

export function mapExternalSektor(externalSektor: api.Sektor): Sektor {
  return {
    name: externalSektor.name,
    anfangAmHalt: externalSektor.anfangAmHalt,
    endeAmHalt: externalSektor.endeAmHalt,
  };
}

export function mapExternalWagenGruppe(externalWagenGruppe: api.WagenGruppe): WagenGruppe {
  const extraBezeichung = externalWagenGruppe.extraBezeichnung;
  const triebzugNummer = externalWagenGruppe.triebzugNummer;
  return {
    allWagen: externalWagenGruppe.allWagen.map(mapExternalWagen),
    bezeichnung: externalWagenGruppe.bezeichnung,
    extraBezeichung: extraBezeichung !== undefined ? extraBezeichung : null,
    triebzugNummer: triebzugNummer !== undefined ? triebzugNummer : null,
    baureihe: mapExternalBaureihe(externalWagenGruppe.baureihe),
    fahrtNummer: externalWagenGruppe.fahrtNummer,
    startHalt: externalWagenGruppe.startHalt,
    zielHalt: externalWagenGruppe.zielHalt,
    anfangAmHalt: externalWagenGruppe.anfangAmHalt,
    endeAmHalt: externalWagenGruppe.endeAmHalt,
  };
}

export function mapExternalWagen(externalWagen: api.Wagen): Wagen {
  if (externalWagen.allCSS === undefined) {
    throw 'CSS of Wagen is undefined, which is illegal';
  }

  const won = externalWagen.wagenordnungNummer;
  const uic = externalWagen.uicNummer;
  return {
    bauart: externalWagen.bauart,
    wagenordnungNummer: won !== undefined ? won : null,
    uicNummer: uic !== undefined ? uic : null,
    sektor: externalWagen.sektor,
    klasse: externalWagen.klasse,
    status: mapExternalWagenStatus(externalWagen.status),
    auslastung: externalWagen.auslastung !== undefined ? mapExternalAuslastungWert(externalWagen.auslastung) : null,
    allCSS: mapExternalWagenCSS(externalWagen.allCSS),
    allSonderSitzplatzGruppe: filterNotNull(externalWagen.allSonderSitzplatzGruppe.map(mapExternalSitzplatzGruppe)),
  };
}

export function mapExternalWagenStatus(status: api.WagenStatus): WagenStatus {
  switch (status) {
    case api.WagenStatus.WAGEN_OFFEN:
      return WagenStatus.OFFEN;
    case api.WagenStatus.WAGEN_GESCHLOSSEN:
      return WagenStatus.GESCHLOSSEN;
    case api.WagenStatus.UNRECOGNIZED:
    default:
      throw 'Unrecognized WagenStatus';
  }
}
