import { defineStore } from 'pinia';
import { type StationMRUEntries, type StationMRUEntry, type StationMRUState } from '@/stores/types';
import type { Haltestelle } from '@/model/haltestelle';

const MRU_MAX_ENTRIES = 20;

function getStationId(station: Haltestelle): string {
  return `${station.evaNummer}-${station.name}`;
}

export const useStationMRUStore = defineStore('stationMRU', {
  state: (): StationMRUState => ({
    stations: {},
  }),
  persist: true,
  getters: {
    allHaltestelleSorted: (state): StationMRUEntry[] => {
      const stations = Object.values(state.stations);
      stations.sort((a, b) => {
        if (b.timesUsed === a.timesUsed || b.timesUsed === 1) {
          return b.lastUsed - a.lastUsed;
        } else {
          return b.timesUsed - a.timesUsed;
        }
      });
      return stations;
    },
    allHaltestelleMRU(): Haltestelle[] {
      const stationMRUEntries: StationMRUEntry[] = this.allHaltestelleSorted;
      return stationMRUEntries.map((entry) => entry.station);
    },
    allHaltestelleWithRl100(): Haltestelle[] {
      return this.allHaltestelleMRU.filter((stop) => stop.rl100 !== null);
    },
  },
  actions: {
    addStationToMRU(station: Haltestelle) {
      const stationId = getStationId(station);
      if (this.stations[stationId]) {
        this.stations[stationId].timesUsed += 1;
        this.stations[stationId].lastUsed = Date.now();
      } else {
        this.stations[stationId] = {
          station,
          lastUsed: Date.now(),
          timesUsed: 1,
        };
      }
    },
    trimMRU() {
      if (this.allHaltestelleSorted.length > MRU_MAX_ENTRIES) {
        const newStations: StationMRUEntries = {};
        this.allHaltestelleSorted
          .slice(0, MRU_MAX_ENTRIES)
          .forEach((entry) => (newStations[getStationId(entry.station)] = entry));
        this.$patch({
          stations: newStations,
        });
      }
    },
  },
});
