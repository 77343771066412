import { api } from '@zentrains/api';
import { Fahrt, FahrtMerkmal } from '@/model/fahrt';
import { filterNotNull } from '@/mapper/from-api/helper';

export function mapExternalFahrt(externalFahrt: api.Fahrt): Fahrt {
  const externalBetreiber: api.Betreiber | undefined = externalFahrt.betreiber;
  if (externalBetreiber === undefined) {
    throw 'Betreiber was undefined in Fahrt, which was unexpected';
  }

  const betreiber = {
    name: externalBetreiber.name,
    betreiberId: externalBetreiber.betreiberId,
  };
  return new Fahrt(
    betreiber,
    externalFahrt.gattung,
    externalFahrt.fahrtNummer,
    externalFahrt.linie,
    externalFahrt.zielHalt,
    externalFahrt.isZusatzfahrt,
    filterNotNull(externalFahrt.allFahrtMerkmal.map(mapExternalFahrtmerkmal)),
    new Date(externalFahrt.startZeit),
  );
}

export function mapExternalFahrtmerkmal(externalFahrtMerkmal: api.FahrtMerkmal): FahrtMerkmal | null {
  switch (externalFahrtMerkmal) {
    case api.FahrtMerkmal.FahrradmitnahmeReservierungspflichtig:
      return FahrtMerkmal.FahrradmitnahmeReservierungspflichtig;
    case api.FahrtMerkmal.FahrradmitnahmeMoeglich:
      return FahrtMerkmal.FahrradmitnahmeMoeglich;
    case api.FahrtMerkmal.MitNetzcardFahrbar:
      return FahrtMerkmal.MitNetzcardFahrbar;
    case api.FahrtMerkmal.ReservierungsPflicht:
      return FahrtMerkmal.ReservierungsPflicht;
    case api.FahrtMerkmal.UNRECOGNIZED:
      return null;
  }
}
