import usePushNotifications from '@/composables/usePushNotifications';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { loadingController } from '@ionic/vue';
import { Toast } from '@capacitor/toast';
import { ROUTE } from '@/router';
import { useSettingsStore } from '@/stores/settings';
import { computed } from 'vue';
import { format } from 'date-fns';
import { useRoute, useRouter } from 'vue-router';
import { captureException } from '@sentry/vue';
import { useBackendStore } from '@/stores/backend';

export default function useTraewellingOAuth() {
  const pushStore = usePushNotifications();
  const settingsStore = useSettingsStore();

  const traewellingExpiry = computed(() => settingsStore.traewellingOAuth.expiry);
  const traewellingExpiryFormatted = computed(() => format(traewellingExpiry.value, 'dd.MM.yyyy'));

  const traewellingAccessToken = computed(() => settingsStore.traewellingOAuth.accessToken);
  const traewellingRefreshToken = computed(() => settingsStore.traewellingOAuth.refreshToken);
  const traewellingTokenType = computed(() => settingsStore.traewellingOAuth.tokenType);

  const isTraewellingLinked = computed(() => traewellingExpiry.value !== -1);

  const backend = useBackendStore();

  const triggerTraewellingOAuth = async () => {
    try {
      const response = await backend.notificationClient.requestTraewellingOAuthURL({
        deviceKey: pushStore.deviceKey.value,
        source: Capacitor.getPlatform(),
      });
      const url = response.message;
      await Browser.open({
        url,
      });
    } catch (e) {
      captureException(e);
      await Toast.show({
        text: 'Fehler bei der Anforderung eines Traewelling-Tokens',
      });
    }
  };

  const resetTraewellingOAuth = async () => {
    settingsStore.resetTraewellingOAuth();
    await settingsStore.sendCheckinTokensToServer();
  };

  const processOAuthCallback = async (code: string, state: string) => {
    try {
      const authResponse = await backend.notificationClient.processTraewellingOAuthCallback({
        deviceKey: pushStore.deviceKey.value,
        source: Capacitor.getPlatform(),
        code,
        state,
      });
      if (authResponse.data) {
        settingsStore.setTraewellingOAuth({
          tokenType: authResponse.data.tokenType,
          refreshToken: authResponse.data.refreshToken,
          accessToken: authResponse.data.accessToken,
          expiry: authResponse.data.expiry,
        });
        await settingsStore.sendCheckinTokensToServer();
      } else {
        await Toast.show({
          text: 'OAuth-Daten waren ungültig!',
          duration: 'long',
        });
      }
    } catch (e) {
      captureException(e);
      await Toast.show({
        text: 'OAuth war nicht erfolgreich!',
        duration: 'long',
      });
    }
  };

  const handleTraewellingResponse = async (code: string, state: string) => {
    const processing = await loadingController.create({
      cssClass: 'processing-oauth-response',
      message: 'Träewelling-Verknüpfung wird eingerichtet, bitte warten...',
    });
    await processing.present();
    await processOAuthCallback(code, state);
    await processing.dismiss();
  };

  const handleTraewellingRouteEntrypoint = async () => {
    const router = useRouter();
    const route = useRoute();
    if (route.name === ROUTE.TRAEWELLING_OAUTH_CALLBACK) {
      await router.replace({
        name: ROUTE.SETTINGS,
      });
    }
  };

  return {
    traewellingExpiryFormatted,
    traewellingAccessToken,
    traewellingRefreshToken,
    traewellingTokenType,
    isTraewellingLinked,
    triggerTraewellingOAuth,
    resetTraewellingOAuth,
    handleTraewellingResponse,
    handleTraewellingRouteEntrypoint,
  };
}
