import { computed, type Ref, ref, watch } from 'vue';
import { Toast } from '@capacitor/toast';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, type Messaging, onMessage } from 'firebase/messaging';
import { VAPID_KEY } from '@/constants';
import { firebaseConfig } from '@/notifications/firebaseConfig';
import { sendNotificationIfNecessary } from '@/composables/helper';
import * as Sentry from '@sentry/browser';
import { registerServiceWorker } from '@/registerServiceWorker';
import useSWAppUpdates from '@/composables/service-worker/useSWAppUpdates';
import { useNotificationsStore } from '@/stores/notifications';
import { useSettingsStore } from '@/stores/settings';
import { ActivePushMethod } from '@/stores/types';

async function setupPushNotifications(serviceWorkerRegistration: Ref<ServiceWorkerRegistration | null>) {
  const settingsStore = useSettingsStore();
  const pushNotificationsActive = computed((): boolean => settingsStore.pushNotificationsActive);
  watch(serviceWorkerRegistration, async (val) => await handleSWAndSettingsChange(val, pushNotificationsActive.value));
  watch(pushNotificationsActive, async (val) => await handleSWAndSettingsChange(serviceWorkerRegistration.value, val));
}

async function handleSWAndSettingsChange(
  serviceWorkerRegistration: ServiceWorkerRegistration | null,
  pushNotificationsActive: boolean,
) {
  if (serviceWorkerRegistration !== null) {
    const notificationStore = useNotificationsStore();
    if (pushNotificationsActive) {
      const settingsStore = useSettingsStore();
      if (!(await isSupported())) {
        await Toast.show({
          text: 'Dieser Browser unterstützt leider keine Push-Benachrichtigungen.',
          duration: 'long',
        });
        settingsStore.pushNotificationsActive = false;
        return;
      }
      try {
        const firebaseApp = initializeApp(firebaseConfig, {
          automaticDataCollectionEnabled: false,
        });
        const messaging = getMessaging(firebaseApp);
        if (notificationStore.activePushMethod !== ActivePushMethod.NONE) {
          console.log('Nutze bestehendes Token.', notificationStore.pushToken);
          await setupMessageReception(messaging, serviceWorkerRegistration);
          return;
        }
        const currentToken = await getToken(messaging, {
          vapidKey: VAPID_KEY,
          serviceWorkerRegistration,
        });
        if (currentToken) {
          console.log('Nutze neues Token.', currentToken);
          const notificationStore = useNotificationsStore();
          notificationStore.activatePush(currentToken, ActivePushMethod.SERVICE_WORKER);
          await setupMessageReception(messaging, serviceWorkerRegistration);
        } else {
          console.log('Kein Push-Token verfügbar, kein Service Worker zur Rechteanforderung verfügbar.');
        }
      } catch (e) {
        console.error('Fehler bei der Anforderung eines Push-Tokens. ', e);
        await Toast.show({
          text: 'Fehler bei der Anforderung eines Push-Tokens',
          duration: 'long',
        });
        Sentry.captureException(e);
        settingsStore.pushNotificationsActive = false;
      }
    } else if (notificationStore.activePushMethod !== ActivePushMethod.NONE) {
      await notificationStore.deactivatePush();
    }
  }
}

async function setupMessageReception(messaging: Messaging, serviceWorkerRegistration: ServiceWorkerRegistration) {
  onMessage(messaging, async (payload) => {
    await Sentry.startSpan(
      {
        name: 'push-notification/foreground',
      },
      async (span) => {
        const result = await sendNotificationIfNecessary(serviceWorkerRegistration, payload);
        span.setAttribute('notificationSent', result);
      },
    );
  });
  const notificationsStore = useNotificationsStore();
  await notificationsStore.sendAllDataToNotificationServer();
}

export default function useSW() {
  const serviceWorkerRegistration: Ref<ServiceWorkerRegistration | null> = ref(null);

  const isServiceWorkerActive = computed(() => serviceWorkerRegistration.value !== null);

  const appUpdating = useSWAppUpdates(serviceWorkerRegistration);
  registerServiceWorker(serviceWorkerRegistration, appUpdating.enableUpdating);
  setupPushNotifications(serviceWorkerRegistration).then(() => {
    // noop
  });

  return {
    isServiceWorkerActive,
    ...appUpdating,
  };
}
