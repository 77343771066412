import { api } from '@zentrains/api';
import { ReiseQuelle } from '@/model/reise';

export function mapToExternalReiseQuelle(quelle: ReiseQuelle): api.ReiseQuelle {
  switch (quelle) {
    case ReiseQuelle.DB_BUSINESS_NAVIGATOR:
      return api.ReiseQuelle.REISE_DB_BUSINESS_NAVIGATOR;
    case ReiseQuelle.DB_NAVIGATOR:
      return api.ReiseQuelle.REISE_DB_NAVIGATOR;
    case ReiseQuelle.OEBB:
      return api.ReiseQuelle.REISE_OEBB;
    case ReiseQuelle.SBB:
      return api.ReiseQuelle.REISE_SBB;
    case ReiseQuelle.UNBEKANNT:
      return api.ReiseQuelle.UNRECOGNIZED;
  }
}
