import { api } from '@zentrains/api';
import { Baureihe } from '@/model/baureihe';

export function mapExternalBaureihe(baureihe: api.Baureihe): Baureihe {
  switch (baureihe) {
    case api.Baureihe.BR101:
      return Baureihe.BR101;
    case api.Baureihe.BR146:
      return Baureihe.BR146;
    case api.Baureihe.BR193:
      return Baureihe.BR193;
    case api.Baureihe.BR401:
      return Baureihe.BR401;
    case api.Baureihe.BR401_LDV:
      return Baureihe.BR401_LDV;
    case api.Baureihe.BR401_9T:
      return Baureihe.BR401_9T;
    case api.Baureihe.BR402:
      return Baureihe.BR402;
    case api.Baureihe.BR403R:
      return Baureihe.BR403R;
    case api.Baureihe.BR406:
      return Baureihe.BR406;
    case api.Baureihe.BR406R:
      return Baureihe.BR406R;
    case api.Baureihe.BR407:
      return Baureihe.BR407;
    case api.Baureihe.BR408:
      return Baureihe.BR408;
    case api.Baureihe.BR410_1:
      return Baureihe.BR410_1;
    case api.Baureihe.BR411:
      return Baureihe.BR411;
    case api.Baureihe.BR411_S1:
      return Baureihe.BR411_S1;
    case api.Baureihe.BR411_S2:
      return Baureihe.BR411_S2;
    case api.Baureihe.BR412:
      return Baureihe.BR412;
    case api.Baureihe.BR412_7T:
      return Baureihe.BR412_7T;
    case api.Baureihe.BR412_13T:
      return Baureihe.BR412_13T;
    case api.Baureihe.BR415:
      return Baureihe.BR415;
    case api.Baureihe.BR423:
      return Baureihe.BR423;
    case api.Baureihe.BR425:
      return Baureihe.BR425;
    case api.Baureihe.BR427:
      return Baureihe.BR427;
    case api.Baureihe.BR430:
      return Baureihe.BR430;
    case api.Baureihe.BR440:
      return Baureihe.BR440;
    case api.Baureihe.BR442:
      return Baureihe.BR442;
    case api.Baureihe.BR445:
      return Baureihe.BR445;
    case api.Baureihe.BR446:
      return Baureihe.BR446;
    case api.Baureihe.BR462:
      return Baureihe.BR462;
    case api.Baureihe.BR463:
      return Baureihe.BR463;
    case api.Baureihe.BR554:
      return Baureihe.BR554;
    case api.Baureihe.BR612:
      return Baureihe.BR612;
    case api.Baureihe.BR620:
      return Baureihe.BR620;
    case api.Baureihe.BR622:
      return Baureihe.BR622;
    case api.Baureihe.BR1622:
      return Baureihe.BR1622;
    case api.Baureihe.BR623:
      return Baureihe.BR623;
    case api.Baureihe.BR628:
      return Baureihe.BR628;
    case api.Baureihe.BR632:
      return Baureihe.BR632;
    case api.Baureihe.BR633:
      return Baureihe.BR633;
    case api.Baureihe.BR640:
      return Baureihe.BR640;
    case api.Baureihe.BR642:
      return Baureihe.BR642;
    case api.Baureihe.BR643:
      return Baureihe.BR643;
    case api.Baureihe.BR648:
      return Baureihe.BR648;
    case api.Baureihe.BR1648:
      return Baureihe.BR1648;
    case api.Baureihe.BR2648:
      return Baureihe.BR2648;
    case api.Baureihe.BR650:
      return Baureihe.BR650;
    case api.Baureihe.IC2_TWIN:
      return Baureihe.IC2_TWIN;
    case api.Baureihe.IC2_KISS:
      return Baureihe.IC2_KISS;
    case api.Baureihe.TGV:
      return Baureihe.TGV;
    case api.Baureihe.EC:
      return Baureihe.EC;
    case api.Baureihe.IC:
      return Baureihe.IC;
    case api.Baureihe.RJ:
      return Baureihe.RJ;
    case api.Baureihe.ETR610:
      return Baureihe.ETR610;
    case api.Baureihe.NJ:
      return Baureihe.NJ;
    case api.Baureihe.DOSTO:
      return Baureihe.DOSTO;
    case api.Baureihe.DSB_IC3:
      return Baureihe.DSB_IC3;
    case api.Baureihe.OEBB_4024:
      return Baureihe.OEBB_4024;
    case api.Baureihe.OEBB_4746:
      return Baureihe.OEBB_4746;
    case api.Baureihe.OEBB_1016:
      return Baureihe.OEBB_1016;
    case api.Baureihe.OEBB_1116:
      return Baureihe.OEBB_1116;
    case api.Baureihe.OEBB_1216:
      return Baureihe.OEBB_1216;
    case api.Baureihe.OEBB_CJX:
      return Baureihe.OEBB_CJX;
    case api.Baureihe.OEBB_DOSTO:
      return Baureihe.OEBB_DOSTO;
    case api.Baureihe.OEBB_4023:
      return Baureihe.OEBB_4023;
    case api.Baureihe.UNRECOGNIZED:
    case api.Baureihe.UNBEKANNT:
    default:
      return Baureihe.UNBEKANNT;
  }
}
