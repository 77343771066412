import { api } from '@zentrains/api';
import type { Reise } from '@/model/reise';
import { mapToExternalReiseIdentifikation } from '@/mapper/to-api/reiseId';

export function mapToExternalReiseItem(reise: Reise): api.ReiseItem {
  return {
    reiseId: mapToExternalReiseIdentifikation(reise.id),
    startZeit: reise.abfahrt.sollZeit.valueOf(),
    abschnittKeyToReservierungMap: {},
  };
}
