// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.27.2
// source: iris/iris.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";

export const protobufPackage = "iris";

export enum IrisMessageType {
  DELAY = 0,
  QOS = 1,
  UNRECOGNIZED = -1,
}

export function irisMessageTypeFromJSON(object: any): IrisMessageType {
  switch (object) {
    case 0:
    case "DELAY":
      return IrisMessageType.DELAY;
    case 1:
    case "QOS":
      return IrisMessageType.QOS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IrisMessageType.UNRECOGNIZED;
  }
}

export function irisMessageTypeToJSON(object: IrisMessageType): string {
  switch (object) {
    case IrisMessageType.DELAY:
      return "DELAY";
    case IrisMessageType.QOS:
      return "QOS";
    case IrisMessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AbfahrtenRequest {
  evaNumber: string;
  from: number;
  to: number;
  productFilter: string[];
}

export interface AbfahrtenResponse {
  departures: Abfahrt[];
  wings: { [key: string]: Abfahrt };
  operators: { [key: number]: string };
}

export interface AbfahrtenResponse_WingsEntry {
  key: string;
  value: Abfahrt | undefined;
}

export interface AbfahrtenResponse_OperatorsEntry {
  key: number;
  value: string;
}

export interface StopInfo {
  scheduledTime: number;
  time: number;
  delay: number;
  scheduledPlatform: string;
  platform: string;
  hasDelay: boolean;
  cancelled: boolean;
  wingIds: string[];
}

export interface IrisMessage {
  type: IrisMessageType;
  timestamp: number;
  superseded: boolean;
  code: number;
}

export interface HafasMessage {
  summary: string;
  text: string;
}

export interface TrainInfo {
  type: string;
  number: number;
  line: string;
}

export interface TrainReference {
  type: string;
  number: number;
}

export interface Abfahrt {
  arrival: StopInfo | undefined;
  departure: StopInfo | undefined;
  additional: boolean;
  cancelled: boolean;
  substitute: boolean;
  auslastung: boolean;
  reihung: boolean;
  destination: string;
  scheduledDestination: string;
  platform: string;
  scheduledPlatform: string;
  mediumId: string;
  operatorId: number;
  initialDeparture: number;
  substituteReference: TrainReference | undefined;
  train: TrainInfo | undefined;
  rawId: string;
  messages: IrisMessage[];
  hafasMessages: HafasMessage[];
}

function createBaseAbfahrtenRequest(): AbfahrtenRequest {
  return { evaNumber: "", from: 0, to: 0, productFilter: [] };
}

export const AbfahrtenRequest = {
  encode(message: AbfahrtenRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.evaNumber !== "") {
      writer.uint32(10).string(message.evaNumber);
    }
    if (message.from !== 0) {
      writer.uint32(16).int64(message.from);
    }
    if (message.to !== 0) {
      writer.uint32(24).int64(message.to);
    }
    for (const v of message.productFilter) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AbfahrtenRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbfahrtenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.from = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.to = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.productFilter.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbfahrtenRequest {
    return {
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
      from: isSet(object.from) ? globalThis.Number(object.from) : 0,
      to: isSet(object.to) ? globalThis.Number(object.to) : 0,
      productFilter: globalThis.Array.isArray(object?.productFilter)
        ? object.productFilter.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: AbfahrtenRequest): unknown {
    const obj: any = {};
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    if (message.from !== 0) {
      obj.from = Math.round(message.from);
    }
    if (message.to !== 0) {
      obj.to = Math.round(message.to);
    }
    if (message.productFilter?.length) {
      obj.productFilter = message.productFilter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbfahrtenRequest>, I>>(base?: I): AbfahrtenRequest {
    return AbfahrtenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbfahrtenRequest>, I>>(object: I): AbfahrtenRequest {
    const message = createBaseAbfahrtenRequest();
    message.evaNumber = object.evaNumber ?? "";
    message.from = object.from ?? 0;
    message.to = object.to ?? 0;
    message.productFilter = object.productFilter?.map((e) => e) || [];
    return message;
  },
};

function createBaseAbfahrtenResponse(): AbfahrtenResponse {
  return { departures: [], wings: {}, operators: {} };
}

export const AbfahrtenResponse = {
  encode(message: AbfahrtenResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.departures) {
      Abfahrt.encode(v!, writer.uint32(10).fork()).join();
    }
    Object.entries(message.wings).forEach(([key, value]) => {
      AbfahrtenResponse_WingsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    Object.entries(message.operators).forEach(([key, value]) => {
      AbfahrtenResponse_OperatorsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AbfahrtenResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbfahrtenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.departures.push(Abfahrt.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = AbfahrtenResponse_WingsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.wings[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = AbfahrtenResponse_OperatorsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.operators[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbfahrtenResponse {
    return {
      departures: globalThis.Array.isArray(object?.departures)
        ? object.departures.map((e: any) => Abfahrt.fromJSON(e))
        : [],
      wings: isObject(object.wings)
        ? Object.entries(object.wings).reduce<{ [key: string]: Abfahrt }>((acc, [key, value]) => {
          acc[key] = Abfahrt.fromJSON(value);
          return acc;
        }, {})
        : {},
      operators: isObject(object.operators)
        ? Object.entries(object.operators).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: AbfahrtenResponse): unknown {
    const obj: any = {};
    if (message.departures?.length) {
      obj.departures = message.departures.map((e) => Abfahrt.toJSON(e));
    }
    if (message.wings) {
      const entries = Object.entries(message.wings);
      if (entries.length > 0) {
        obj.wings = {};
        entries.forEach(([k, v]) => {
          obj.wings[k] = Abfahrt.toJSON(v);
        });
      }
    }
    if (message.operators) {
      const entries = Object.entries(message.operators);
      if (entries.length > 0) {
        obj.operators = {};
        entries.forEach(([k, v]) => {
          obj.operators[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbfahrtenResponse>, I>>(base?: I): AbfahrtenResponse {
    return AbfahrtenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbfahrtenResponse>, I>>(object: I): AbfahrtenResponse {
    const message = createBaseAbfahrtenResponse();
    message.departures = object.departures?.map((e) => Abfahrt.fromPartial(e)) || [];
    message.wings = Object.entries(object.wings ?? {}).reduce<{ [key: string]: Abfahrt }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Abfahrt.fromPartial(value);
      }
      return acc;
    }, {});
    message.operators = Object.entries(object.operators ?? {}).reduce<{ [key: number]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseAbfahrtenResponse_WingsEntry(): AbfahrtenResponse_WingsEntry {
  return { key: "", value: undefined };
}

export const AbfahrtenResponse_WingsEntry = {
  encode(message: AbfahrtenResponse_WingsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Abfahrt.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AbfahrtenResponse_WingsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbfahrtenResponse_WingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Abfahrt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbfahrtenResponse_WingsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Abfahrt.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AbfahrtenResponse_WingsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Abfahrt.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbfahrtenResponse_WingsEntry>, I>>(base?: I): AbfahrtenResponse_WingsEntry {
    return AbfahrtenResponse_WingsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbfahrtenResponse_WingsEntry>, I>>(object: I): AbfahrtenResponse_WingsEntry {
    const message = createBaseAbfahrtenResponse_WingsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Abfahrt.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseAbfahrtenResponse_OperatorsEntry(): AbfahrtenResponse_OperatorsEntry {
  return { key: 0, value: "" };
}

export const AbfahrtenResponse_OperatorsEntry = {
  encode(message: AbfahrtenResponse_OperatorsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AbfahrtenResponse_OperatorsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbfahrtenResponse_OperatorsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AbfahrtenResponse_OperatorsEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: AbfahrtenResponse_OperatorsEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AbfahrtenResponse_OperatorsEntry>, I>>(
    base?: I,
  ): AbfahrtenResponse_OperatorsEntry {
    return AbfahrtenResponse_OperatorsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AbfahrtenResponse_OperatorsEntry>, I>>(
    object: I,
  ): AbfahrtenResponse_OperatorsEntry {
    const message = createBaseAbfahrtenResponse_OperatorsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseStopInfo(): StopInfo {
  return {
    scheduledTime: 0,
    time: 0,
    delay: 0,
    scheduledPlatform: "",
    platform: "",
    hasDelay: false,
    cancelled: false,
    wingIds: [],
  };
}

export const StopInfo = {
  encode(message: StopInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scheduledTime !== 0) {
      writer.uint32(8).int64(message.scheduledTime);
    }
    if (message.time !== 0) {
      writer.uint32(16).int64(message.time);
    }
    if (message.delay !== 0) {
      writer.uint32(24).int32(message.delay);
    }
    if (message.scheduledPlatform !== "") {
      writer.uint32(34).string(message.scheduledPlatform);
    }
    if (message.platform !== "") {
      writer.uint32(42).string(message.platform);
    }
    if (message.hasDelay !== false) {
      writer.uint32(48).bool(message.hasDelay);
    }
    if (message.cancelled !== false) {
      writer.uint32(56).bool(message.cancelled);
    }
    for (const v of message.wingIds) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scheduledTime = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.time = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.delay = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scheduledPlatform = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hasDelay = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.wingIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopInfo {
    return {
      scheduledTime: isSet(object.scheduledTime) ? globalThis.Number(object.scheduledTime) : 0,
      time: isSet(object.time) ? globalThis.Number(object.time) : 0,
      delay: isSet(object.delay) ? globalThis.Number(object.delay) : 0,
      scheduledPlatform: isSet(object.scheduledPlatform) ? globalThis.String(object.scheduledPlatform) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      hasDelay: isSet(object.hasDelay) ? globalThis.Boolean(object.hasDelay) : false,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      wingIds: globalThis.Array.isArray(object?.wingIds) ? object.wingIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: StopInfo): unknown {
    const obj: any = {};
    if (message.scheduledTime !== 0) {
      obj.scheduledTime = Math.round(message.scheduledTime);
    }
    if (message.time !== 0) {
      obj.time = Math.round(message.time);
    }
    if (message.delay !== 0) {
      obj.delay = Math.round(message.delay);
    }
    if (message.scheduledPlatform !== "") {
      obj.scheduledPlatform = message.scheduledPlatform;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.hasDelay !== false) {
      obj.hasDelay = message.hasDelay;
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.wingIds?.length) {
      obj.wingIds = message.wingIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopInfo>, I>>(base?: I): StopInfo {
    return StopInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopInfo>, I>>(object: I): StopInfo {
    const message = createBaseStopInfo();
    message.scheduledTime = object.scheduledTime ?? 0;
    message.time = object.time ?? 0;
    message.delay = object.delay ?? 0;
    message.scheduledPlatform = object.scheduledPlatform ?? "";
    message.platform = object.platform ?? "";
    message.hasDelay = object.hasDelay ?? false;
    message.cancelled = object.cancelled ?? false;
    message.wingIds = object.wingIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseIrisMessage(): IrisMessage {
  return { type: 0, timestamp: 0, superseded: false, code: 0 };
}

export const IrisMessage = {
  encode(message: IrisMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.timestamp !== 0) {
      writer.uint32(16).int64(message.timestamp);
    }
    if (message.superseded !== false) {
      writer.uint32(24).bool(message.superseded);
    }
    if (message.code !== 0) {
      writer.uint32(40).uint32(message.code);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IrisMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIrisMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestamp = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.superseded = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.code = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IrisMessage {
    return {
      type: isSet(object.type) ? irisMessageTypeFromJSON(object.type) : 0,
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      superseded: isSet(object.superseded) ? globalThis.Boolean(object.superseded) : false,
      code: isSet(object.code) ? globalThis.Number(object.code) : 0,
    };
  },

  toJSON(message: IrisMessage): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = irisMessageTypeToJSON(message.type);
    }
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.superseded !== false) {
      obj.superseded = message.superseded;
    }
    if (message.code !== 0) {
      obj.code = Math.round(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IrisMessage>, I>>(base?: I): IrisMessage {
    return IrisMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IrisMessage>, I>>(object: I): IrisMessage {
    const message = createBaseIrisMessage();
    message.type = object.type ?? 0;
    message.timestamp = object.timestamp ?? 0;
    message.superseded = object.superseded ?? false;
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseHafasMessage(): HafasMessage {
  return { summary: "", text: "" };
}

export const HafasMessage = {
  encode(message: HafasMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.summary !== "") {
      writer.uint32(10).string(message.summary);
    }
    if (message.text !== "") {
      writer.uint32(18).string(message.text);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HafasMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHafasMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HafasMessage {
    return {
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: HafasMessage): unknown {
    const obj: any = {};
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HafasMessage>, I>>(base?: I): HafasMessage {
    return HafasMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HafasMessage>, I>>(object: I): HafasMessage {
    const message = createBaseHafasMessage();
    message.summary = object.summary ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseTrainInfo(): TrainInfo {
  return { type: "", number: 0, line: "" };
}

export const TrainInfo = {
  encode(message: TrainInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.number !== 0) {
      writer.uint32(16).uint32(message.number);
    }
    if (message.line !== "") {
      writer.uint32(26).string(message.line);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.number = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.line = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainInfo {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
      line: isSet(object.line) ? globalThis.String(object.line) : "",
    };
  },

  toJSON(message: TrainInfo): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    if (message.line !== "") {
      obj.line = message.line;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainInfo>, I>>(base?: I): TrainInfo {
    return TrainInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainInfo>, I>>(object: I): TrainInfo {
    const message = createBaseTrainInfo();
    message.type = object.type ?? "";
    message.number = object.number ?? 0;
    message.line = object.line ?? "";
    return message;
  },
};

function createBaseTrainReference(): TrainReference {
  return { type: "", number: 0 };
}

export const TrainReference = {
  encode(message: TrainReference, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.number !== 0) {
      writer.uint32(16).uint32(message.number);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainReference {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.number = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainReference {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
    };
  },

  toJSON(message: TrainReference): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainReference>, I>>(base?: I): TrainReference {
    return TrainReference.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainReference>, I>>(object: I): TrainReference {
    const message = createBaseTrainReference();
    message.type = object.type ?? "";
    message.number = object.number ?? 0;
    return message;
  },
};

function createBaseAbfahrt(): Abfahrt {
  return {
    arrival: undefined,
    departure: undefined,
    additional: false,
    cancelled: false,
    substitute: false,
    auslastung: false,
    reihung: false,
    destination: "",
    scheduledDestination: "",
    platform: "",
    scheduledPlatform: "",
    mediumId: "",
    operatorId: 0,
    initialDeparture: 0,
    substituteReference: undefined,
    train: undefined,
    rawId: "",
    messages: [],
    hafasMessages: [],
  };
}

export const Abfahrt = {
  encode(message: Abfahrt, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.arrival !== undefined) {
      StopInfo.encode(message.arrival, writer.uint32(10).fork()).join();
    }
    if (message.departure !== undefined) {
      StopInfo.encode(message.departure, writer.uint32(18).fork()).join();
    }
    if (message.additional !== false) {
      writer.uint32(24).bool(message.additional);
    }
    if (message.cancelled !== false) {
      writer.uint32(32).bool(message.cancelled);
    }
    if (message.substitute !== false) {
      writer.uint32(40).bool(message.substitute);
    }
    if (message.auslastung !== false) {
      writer.uint32(48).bool(message.auslastung);
    }
    if (message.reihung !== false) {
      writer.uint32(56).bool(message.reihung);
    }
    if (message.destination !== "") {
      writer.uint32(66).string(message.destination);
    }
    if (message.scheduledDestination !== "") {
      writer.uint32(74).string(message.scheduledDestination);
    }
    if (message.platform !== "") {
      writer.uint32(82).string(message.platform);
    }
    if (message.scheduledPlatform !== "") {
      writer.uint32(90).string(message.scheduledPlatform);
    }
    if (message.mediumId !== "") {
      writer.uint32(98).string(message.mediumId);
    }
    if (message.operatorId !== 0) {
      writer.uint32(104).uint32(message.operatorId);
    }
    if (message.initialDeparture !== 0) {
      writer.uint32(112).int64(message.initialDeparture);
    }
    if (message.substituteReference !== undefined) {
      TrainReference.encode(message.substituteReference, writer.uint32(122).fork()).join();
    }
    if (message.train !== undefined) {
      TrainInfo.encode(message.train, writer.uint32(130).fork()).join();
    }
    if (message.rawId !== "") {
      writer.uint32(138).string(message.rawId);
    }
    for (const v of message.messages) {
      IrisMessage.encode(v!, writer.uint32(202).fork()).join();
    }
    for (const v of message.hafasMessages) {
      HafasMessage.encode(v!, writer.uint32(210).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Abfahrt {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAbfahrt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.arrival = StopInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.departure = StopInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.additional = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.substitute = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.auslastung = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.reihung = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.destination = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.scheduledDestination = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.scheduledPlatform = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.mediumId = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.operatorId = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.initialDeparture = longToNumber(reader.int64());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.substituteReference = TrainReference.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.train = TrainInfo.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rawId = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.messages.push(IrisMessage.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.hafasMessages.push(HafasMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Abfahrt {
    return {
      arrival: isSet(object.arrival) ? StopInfo.fromJSON(object.arrival) : undefined,
      departure: isSet(object.departure) ? StopInfo.fromJSON(object.departure) : undefined,
      additional: isSet(object.additional) ? globalThis.Boolean(object.additional) : false,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      substitute: isSet(object.substitute) ? globalThis.Boolean(object.substitute) : false,
      auslastung: isSet(object.auslastung) ? globalThis.Boolean(object.auslastung) : false,
      reihung: isSet(object.reihung) ? globalThis.Boolean(object.reihung) : false,
      destination: isSet(object.destination) ? globalThis.String(object.destination) : "",
      scheduledDestination: isSet(object.scheduledDestination) ? globalThis.String(object.scheduledDestination) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      scheduledPlatform: isSet(object.scheduledPlatform) ? globalThis.String(object.scheduledPlatform) : "",
      mediumId: isSet(object.mediumId) ? globalThis.String(object.mediumId) : "",
      operatorId: isSet(object.operatorId) ? globalThis.Number(object.operatorId) : 0,
      initialDeparture: isSet(object.initialDeparture) ? globalThis.Number(object.initialDeparture) : 0,
      substituteReference: isSet(object.substituteReference)
        ? TrainReference.fromJSON(object.substituteReference)
        : undefined,
      train: isSet(object.train) ? TrainInfo.fromJSON(object.train) : undefined,
      rawId: isSet(object.rawId) ? globalThis.String(object.rawId) : "",
      messages: globalThis.Array.isArray(object?.messages)
        ? object.messages.map((e: any) => IrisMessage.fromJSON(e))
        : [],
      hafasMessages: globalThis.Array.isArray(object?.hafasMessages)
        ? object.hafasMessages.map((e: any) => HafasMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Abfahrt): unknown {
    const obj: any = {};
    if (message.arrival !== undefined) {
      obj.arrival = StopInfo.toJSON(message.arrival);
    }
    if (message.departure !== undefined) {
      obj.departure = StopInfo.toJSON(message.departure);
    }
    if (message.additional !== false) {
      obj.additional = message.additional;
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.substitute !== false) {
      obj.substitute = message.substitute;
    }
    if (message.auslastung !== false) {
      obj.auslastung = message.auslastung;
    }
    if (message.reihung !== false) {
      obj.reihung = message.reihung;
    }
    if (message.destination !== "") {
      obj.destination = message.destination;
    }
    if (message.scheduledDestination !== "") {
      obj.scheduledDestination = message.scheduledDestination;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.scheduledPlatform !== "") {
      obj.scheduledPlatform = message.scheduledPlatform;
    }
    if (message.mediumId !== "") {
      obj.mediumId = message.mediumId;
    }
    if (message.operatorId !== 0) {
      obj.operatorId = Math.round(message.operatorId);
    }
    if (message.initialDeparture !== 0) {
      obj.initialDeparture = Math.round(message.initialDeparture);
    }
    if (message.substituteReference !== undefined) {
      obj.substituteReference = TrainReference.toJSON(message.substituteReference);
    }
    if (message.train !== undefined) {
      obj.train = TrainInfo.toJSON(message.train);
    }
    if (message.rawId !== "") {
      obj.rawId = message.rawId;
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => IrisMessage.toJSON(e));
    }
    if (message.hafasMessages?.length) {
      obj.hafasMessages = message.hafasMessages.map((e) => HafasMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Abfahrt>, I>>(base?: I): Abfahrt {
    return Abfahrt.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Abfahrt>, I>>(object: I): Abfahrt {
    const message = createBaseAbfahrt();
    message.arrival = (object.arrival !== undefined && object.arrival !== null)
      ? StopInfo.fromPartial(object.arrival)
      : undefined;
    message.departure = (object.departure !== undefined && object.departure !== null)
      ? StopInfo.fromPartial(object.departure)
      : undefined;
    message.additional = object.additional ?? false;
    message.cancelled = object.cancelled ?? false;
    message.substitute = object.substitute ?? false;
    message.auslastung = object.auslastung ?? false;
    message.reihung = object.reihung ?? false;
    message.destination = object.destination ?? "";
    message.scheduledDestination = object.scheduledDestination ?? "";
    message.platform = object.platform ?? "";
    message.scheduledPlatform = object.scheduledPlatform ?? "";
    message.mediumId = object.mediumId ?? "";
    message.operatorId = object.operatorId ?? 0;
    message.initialDeparture = object.initialDeparture ?? 0;
    message.substituteReference = (object.substituteReference !== undefined && object.substituteReference !== null)
      ? TrainReference.fromPartial(object.substituteReference)
      : undefined;
    message.train = (object.train !== undefined && object.train !== null)
      ? TrainInfo.fromPartial(object.train)
      : undefined;
    message.rawId = object.rawId ?? "";
    message.messages = object.messages?.map((e) => IrisMessage.fromPartial(e)) || [];
    message.hafasMessages = object.hafasMessages?.map((e) => HafasMessage.fromPartial(e)) || [];
    return message;
  },
};

export interface IrisService {
  Abfahrten(request: DeepPartial<AbfahrtenRequest>, metadata?: grpc.Metadata): Promise<AbfahrtenResponse>;
}

export class IrisServiceClientImpl implements IrisService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Abfahrten = this.Abfahrten.bind(this);
  }

  Abfahrten(request: DeepPartial<AbfahrtenRequest>, metadata?: grpc.Metadata): Promise<AbfahrtenResponse> {
    return this.rpc.unary(IrisServiceAbfahrtenDesc, AbfahrtenRequest.fromPartial(request), metadata);
  }
}

export const IrisServiceDesc = { serviceName: "iris.IrisService" };

export const IrisServiceAbfahrtenDesc: UnaryMethodDefinitionish = {
  methodName: "Abfahrten",
  service: IrisServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AbfahrtenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AbfahrtenResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
