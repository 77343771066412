import { api } from '@zentrains/api';
import { type Auslastung } from '@/model/auslastung';
import { AuslastungWert } from '@/model/common';

export function mapExternalAuslastung(externalAuslastung: api.Auslastung | null): Auslastung | null {
  if (externalAuslastung === null) {
    return null;
  }
  return {
    ersteKlasse: mapExternalAuslastungWert(externalAuslastung.ersteKlasse),
    zweiteKlasse: mapExternalAuslastungWert(externalAuslastung.zweiteKlasse),
  };
}

export function mapExternalAuslastungWert(externalAuslastungWert: api.AuslastungWert): AuslastungWert {
  switch (externalAuslastungWert) {
    case api.AuslastungWert.AuslastungGering:
      return AuslastungWert.Gering;
    case api.AuslastungWert.AuslastungHoch:
      return AuslastungWert.Hoch;
    case api.AuslastungWert.AuslastungSehrHoch:
      return AuslastungWert.SehrHoch;
    case api.AuslastungWert.AuslastungAusgebucht:
      return AuslastungWert.Ausgebucht;
    case api.AuslastungWert.AuslastungUnbekannt:
    case api.AuslastungWert.UNRECOGNIZED:
    default:
      return AuslastungWert.Unbekannt;
  }
}
