import { type ReiseDto, type ReiseIdentifikationDto } from '@/stores/dto/ReiseDto';
import { type Reise, ReiseIdentifikation } from '@/model/reise';
import { mapZeitToDto } from '@/mapper/to-store-dto/zeit';
import { mapAbschnittToDto } from '@/mapper/to-store-dto/abschnitt';

export function mapReiseToDto(reise: Reise): ReiseDto {
  return {
    id: mapReiseIdentifikationToDto(reise.id),
    abfahrt: mapZeitToDto(reise.abfahrt),
    ankunft: mapZeitToDto(reise.ankunft),
    dauer: reise.dauer,
    isFahrbar: reise.isFahrbar,
    isAlternative: reise.isAlternative,
    umstiege: reise.umstiege,
    allAbschnitt: reise.allAbschnitt.map(mapAbschnittToDto),
  };
}

export function mapReiseIdentifikationToDto(id: ReiseIdentifikation): ReiseIdentifikationDto {
  return {
    reiseId: id.reiseId,
    reiseQuelle: id.reiseQuelle,
  };
}
