import { createRouter, createWebHistory } from '@ionic/vue-router';
import { type NavigationGuardWithThis, type RouteRecordRaw } from 'vue-router';
import useTraewellingOAuth from '@/composables/useTraewellingOAuth';

export const ROUTE = {
  HOME: 'Home',
  OMNI_SEARCH: 'OmniSearch',
  STATION_BOARD: 'Abfahrtstafel',
  TICKET_DETAILS: 'Ticket-Details',
  TRAIN_DETAILS: 'Zug-Details',
  ROUTE_DETAILS: 'Verbindungs-Details',
  ROUTE_SEARCH: 'Verbindungen',
  ALTERNATIVE_SEARCH: 'Alternativen-Suche',
  ALTERNATIVE_ROUTE: 'Alternative Verbindung',
  MY_ROUTES: 'Meine Reisen',
  MY_PICKUPS: 'Abholen',
  MY_ROUTE_DETAILS: 'Meine Verbindung',
  SETTINGS: 'Einstellungen',
  ABOUT: 'Über die App',
  TRAEWELLING_OAUTH_CALLBACK: 'Träwelling-Login',
};

const processTraewellingOAuthResponse: NavigationGuardWithThis<any> = async (to) => {
  const code = to.query['code'] as string;
  const state = to.query['state'] as string;

  const traewellingOAuth = useTraewellingOAuth();
  await traewellingOAuth.handleTraewellingResponse(code, state);
};

const mapQuelleString = (quelle: string | string[]) => {
  if (typeof quelle === 'string') {
    return Number.parseInt(quelle, 10);
  }
  return 0;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: ROUTE.HOME,
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/search',
    name: ROUTE.OMNI_SEARCH,
    component: () => import('../views/OmniSearch.vue'),
  },
  {
    path: '/ticketSearch/:ticketId',
    name: ROUTE.TICKET_DETAILS,
    props: true,
    component: () => import('../views/TicketSearch.vue'),
  },
  {
    path: '/trainDetails/:quelle/:id',
    name: ROUTE.TRAIN_DETAILS,
    props: (route) => ({ ...route.params, ...route.query, ...{ quelle: mapQuelleString(route.params.quelle) } }),
    component: () => import('../views/RouteDetails.vue'),
  },
  {
    path: '/stationBoard/:evaNumber/:ril100?/:name?',
    name: ROUTE.STATION_BOARD,
    props: true,
    component: () => import('../views/StationBoard.vue'),
  },
  {
    path: '/route/:from/:to/:date',
    name: ROUTE.ROUTE_SEARCH,
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import('../views/RouteSearch.vue'),
  },
  {
    path: '/connection/:quelle/:id',
    name: ROUTE.ROUTE_DETAILS,
    props: (route) => ({ ...route.params, ...route.query, ...{ quelle: mapQuelleString(route.params.quelle) } }),
    component: () => import('../views/RouteDetails.vue'),
  },
  {
    path: '/alternative/:quelle/:id',
    name: ROUTE.ALTERNATIVE_SEARCH,
    props: (route) => ({ ...route.params, ...{ quelle: mapQuelleString(route.params.quelle) } }),
    component: () => import('../views/AlternativeSearchView.vue'),
  },
  {
    path: '/alternative/:oldQuelle/:oldId/:newQuelle/:newId',
    name: ROUTE.ALTERNATIVE_ROUTE,
    props: (route) => ({
      ...route.params,
      ...{ oldQuelle: mapQuelleString(route.params.oldQuelle), newQuelle: mapQuelleString(route.params.newQuelle) },
    }),
    component: () => import('../views/AlternativeDetails.vue'),
  },
  {
    path: '/myRoutes',
    name: ROUTE.MY_ROUTES,
    component: () => import('../views/MyRoutes.vue'),
  },
  {
    path: '/myPickups',
    name: ROUTE.MY_PICKUPS,
    component: () => import('../views/MyPickups.vue'),
  },
  {
    path: '/myConnection/:quelle/:id',
    name: ROUTE.MY_ROUTE_DETAILS,
    props: (route) => ({ ...route.params, ...route.query, ...{ quelle: mapQuelleString(route.params.quelle) } }),
    component: () => import('../views/MyRouteDetails.vue'),
  },
  {
    path: '/settings',
    name: ROUTE.SETTINGS,
    component: () => import('../views/SettingsView.vue'),
  },
  {
    path: '/about',
    name: ROUTE.ABOUT,
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/traewelling-callback',
    name: ROUTE.TRAEWELLING_OAUTH_CALLBACK,
    component: () => import('../views/SettingsView.vue'),
    beforeEnter: processTraewellingOAuthResponse,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
