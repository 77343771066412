import { loadingController } from '@ionic/vue';
import { Toast } from '@capacitor/toast';
import { onBeforeMount, ref, type Ref } from 'vue';
import VERSION from '@/env/version';
import SERVICE_WORKER_ACTIVE from '@/env/serviceWorkerActive';
import { useVersionStore } from '@/stores/version';

export default function useSWAppUpdates(serviceWorkerRegistration: Ref<ServiceWorkerRegistration | null>) {
  const versionStore = useVersionStore();
  const updateExists = ref(false);
  const refreshingPage = ref(false);

  onBeforeMount(async () => {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshingPage.value) return;
      refreshingPage.value = true;
      window.location.reload();
    });
    if (SERVICE_WORKER_ACTIVE === 'true' && versionStore.lastVersion !== VERSION) {
      versionStore.lastVersion = VERSION;
      await Toast.show({
        text: 'ZenTrains wurde erfolgreich aktualisiert.',
        duration: 'long',
      });
    }
  });
  const updateApp = async () => {
    updateExists.value = false;
    if (!serviceWorkerRegistration.value || !serviceWorkerRegistration.value?.waiting) {
      return;
    }
    const loading = await loadingController.create({
      cssClass: 'updating-app',
      message: 'ZenTrains wird aktualisiert,\nbitte warten...',
      duration: 5000,
    });

    serviceWorkerRegistration.value?.waiting.postMessage({
      type: 'SKIP_WAITING',
    });
    await loading.present();
  };

  const manualUpdateSearchInProgress = ref(false);
  const manualUpdateLoadingController = ref(null as HTMLIonLoadingElement | null);

  const enableUpdating = async () => {
    updateExists.value = true;
    if (!manualUpdateSearchInProgress.value) {
      await Toast.show({
        text: 'Es ist ein Update verfügbar.\nTippe zum Aktualisieren oben rechts auf das Update-Symbol.',
        duration: 'long',
      });
    } else {
      await manualUpdateLoadingController.value?.dismiss();
      await updateApp();
    }
  };

  const searchForUpdates = async () => {
    if (serviceWorkerRegistration.value) {
      manualUpdateSearchInProgress.value = true;
      manualUpdateLoadingController.value = await loadingController.create({
        cssClass: 'updating-app',
        message: 'ZenTrains sucht nach Updates...',
        duration: 5000,
        keyboardClose: true,
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      serviceWorkerRegistration.value?.update().then((r) => {
        /*noop*/
      });
      manualUpdateLoadingController.value.onDidDismiss().then(async () => {
        if (!updateExists.value) {
          await Toast.show({
            text: 'ZenTrains ist bereits aktuell.',
          });
        }
        manualUpdateSearchInProgress.value = false;
      });

      await manualUpdateLoadingController.value.present();
    }
  };

  return {
    updateExists,
    updateApp,
    enableUpdating,
    searchForUpdates,
  };
}
