import { api } from '@zentrains/api';
import { FahrzeugEigenschaftenKlasse, FahrzeugKlasse, TextKlasse, type WagenCSS } from '@/model/wagenreihung';
import { filterNotNull } from '@/mapper/from-api/helper';

export function mapExternalWagenCSS(allExternalCSS: api.WagenCSS): WagenCSS {
  return {
    anfangAmHalt: allExternalCSS.anfangAmHalt,
    endeAmHalt: allExternalCSS.endeAmHalt,
    allFahrzeugEigenschaftenKlasse: filterNotNull(
      allExternalCSS.allFahrzeugEigenschaftenKlasse.map(mapExternalFahrzeugEigenschaftenKlasse),
    ),
    allFahrzeugKlasse: filterNotNull(allExternalCSS.allFahrzeugKlasse.map(mapExternalFahrzeugKlasse)),
    allTextKlasse: filterNotNull(allExternalCSS.allTextKlasse.map(mapExternalTextKlasse)),
  };
}

export function mapExternalFahrzeugEigenschaftenKlasse(
  externalKlasse: api.FahrzeugEigenschaftenKlasse,
): FahrzeugEigenschaftenKlasse | null {
  switch (externalKlasse) {
    case api.FahrzeugEigenschaftenKlasse.COMFORT_LINKS:
      return FahrzeugEigenschaftenKlasse.COMFORT_LINKS;
    case api.FahrzeugEigenschaftenKlasse.COMFORT_RECHTS:
      return FahrzeugEigenschaftenKlasse.COMFORT_RECHTS;
    case api.FahrzeugEigenschaftenKlasse.DOPPELSTOCK:
      return FahrzeugEigenschaftenKlasse.DOPPELSTOCK;
    case api.FahrzeugEigenschaftenKlasse.ERSTE_KLASSE:
      return FahrzeugEigenschaftenKlasse.ERSTE_KLASSE;
    case api.FahrzeugEigenschaftenKlasse.ZWEITE_KLASSE:
      return FahrzeugEigenschaftenKlasse.ZWEITE_KLASSE;
    case api.FahrzeugEigenschaftenKlasse.LIEGEWAGEN:
      return FahrzeugEigenschaftenKlasse.LIEGEWAGEN;
    case api.FahrzeugEigenschaftenKlasse.RESTAURANT:
      return FahrzeugEigenschaftenKlasse.RESTAURANT;
    case api.FahrzeugEigenschaftenKlasse.KIOSK:
      return FahrzeugEigenschaftenKlasse.KIOSK;
    case api.FahrzeugEigenschaftenKlasse.SCHLAFWAGEN:
      return FahrzeugEigenschaftenKlasse.SCHLAFWAGEN;
    case api.FahrzeugEigenschaftenKlasse.UNRECOGNIZED:
    default:
      return null;
  }
}

export function mapExternalFahrzeugKlasse(externalKlasse: api.FahrzeugKlasse): FahrzeugKlasse | null {
  switch (externalKlasse) {
    case api.FahrzeugKlasse.LOK:
      return FahrzeugKlasse.LOK;
    case api.FahrzeugKlasse.STEUERWAGEN:
      return FahrzeugKlasse.STEUERWAGEN;
    case api.FahrzeugKlasse.TRIEBKOPF:
      return FahrzeugKlasse.TRIEBKOPF;
    case api.FahrzeugKlasse.WAGEN:
      return FahrzeugKlasse.WAGEN;
    case api.FahrzeugKlasse.UNRECOGNIZED:
    default:
      return null;
  }
}

export function mapExternalTextKlasse(externalKlasse: api.TextKlasse): TextKlasse | null {
  switch (externalKlasse) {
    case api.TextKlasse.GESCHLOSSEN:
      return TextKlasse.GESCHLOSSEN;
    case api.TextKlasse.UNRECOGNIZED:
    default:
      return null;
  }
}
