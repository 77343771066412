import { defineStore } from 'pinia';
import { type JourneyAssistanceState } from '@/stores/types';
import { useReiseBegleitungStore } from '@/stores/reiseBegleitung';
import { ReiseIdentifikation, ReiseQuelle } from '@/model/reise';

export const useJourneyAssistanceStore = defineStore('journeyAssistance', {
  state: (): JourneyAssistanceState => ({
    ctxReconList: [],
    routes: {},
    updating: false,
    migrated: false,
  }),
  persist: true,
  actions: {
    /**
     * @deprecated remove when migration is done
     */
    async migrateToReiseBegleitung() {
      if (!this.migrated) {
        const reiseBegleitungStore = useReiseBegleitungStore();
        await reiseBegleitungStore.saveReise(
          ...this.ctxReconList.map((id) => new ReiseIdentifikation(ReiseQuelle.DB_BUSINESS_NAVIGATOR, id)),
        );
        this.migrated = true;
      }
    },
  },
});
