import type { OmniStop } from '@/proto/stop/stop';
import { StopType } from '@/proto/stop/stop';
import type { OmniBetriebsstelle, OmniHaltestelle, OmniItem, OmniTicket, OmniZug } from '@/model/omniSuche';
import { OmniTyp } from '@/model/omniSuche';
import { ReiseIdentifikation, ReiseQuelle } from '@/model/reise';

function mapLegacyOmniTrain(omniStop: OmniStop) {
  const allHalt = omniStop.ril100.split(' nach ');
  return {
    typ: OmniTyp.ZUG,
    reiseId: new ReiseIdentifikation(ReiseQuelle.DB_BUSINESS_NAVIGATOR, omniStop.evaNumber),
    name: omniStop.name,
    startHalt: allHalt[0],
    zielHalt: allHalt[1],
  } as OmniZug;
}

export function mapLegacyOmniStop(omniStop: OmniStop): OmniItem {
  switch (omniStop.stopType) {
    case StopType.STATION:
      return {
        typ: OmniTyp.HALTESTELLE,
        name: omniStop.name,
        evaNummer: omniStop.evaNumber,
        rl100: omniStop.ril100 !== '' ? omniStop.ril100 : null,
      } as OmniHaltestelle;
    case StopType.BETRIEBSSTELLE:
      return {
        typ: OmniTyp.BETRIEBSSTELLE,
        name: omniStop.name,
        rl100: omniStop.ril100 !== '' ? omniStop.ril100 : null,
        betriebsstelleTyp: null,
      } as OmniBetriebsstelle;
    case StopType.TICKET:
      return {
        typ: OmniTyp.TICKET,
        ticketId: omniStop.name,
      } as OmniTicket;
    case StopType.TRAIN:
      return mapLegacyOmniTrain(omniStop);
    case StopType.UNRECOGNIZED:
      throw 'Unrecognized OmniStop type';
  }
}
