import {
  ComfortSitze,
  Fahrzeug,
  FahrzeugEigenschaftenKlassen,
  FahrzeugGruppe,
  FahrzeugKlassen,
  Reihung,
  ReihungQuelle,
  Sektor as HafasSektor,
  TextKlassen,
} from '@/proto/hafas/hafas';
import {
  Fahrtrichtung,
  FahrzeugEigenschaftenKlasse,
  FahrzeugKlasse,
  type Sektor,
  TextKlasse,
  type Wagen,
  type WagenGruppe,
  type Wagenreihung,
  WagenStatus,
} from '@/model/wagenreihung';
import { mapHafasBaureiheToBaureihe } from '@/mapper/from-hafas/baureihe';
import { AuslastungWert } from '@/model/common';
import { type SitzplatzGruppe, SitzplatzGruppeTyp } from '@/model/sonderSitzplatz';

export function mapHafasReihungToWagenreihung(reihung: Reihung | undefined): Wagenreihung | null {
  if (reihung === undefined) {
    return null;
  }
  return {
    isCached: reihung.istCached,
    quelle: mapQuelle(reihung.quelle),
    fahrtNummer: reihung.vzn,
    fahrtGattung: reihung.zugGattung,
    hasFluegel: reihung.anderesZielOderZugnummer,
    isMatchingPlan: reihung.istPlanReihung,
    fahrtrichtung: reihung.fahrtrichtung ? Fahrtrichtung.LINKS : Fahrtrichtung.RECHTS,
    anfangAmHalt: reihung.startProzent,
    skalierung: reihung.skalierung,
    allSektor: reihung.sektoren.map(mapHafasSektorToSektor),
    allWagenGruppe: reihung.fahrzeugGruppen.map(mapHafasFahrzeugGruppeToWagenGruppe),
  };
}

export function mapQuelle(quelle: ReihungQuelle) {
  switch (quelle) {
    case ReihungQuelle.DB_NAVIGATOR_ALT:
      return 'DB Navigator (alt)';
    case ReihungQuelle.DB_NAVIGATOR_NEU:
      return 'DB Navigator (neu)';
    case ReihungQuelle.BAHNHOF_LIVE:
      return 'Bahnhof Live';
    case ReihungQuelle.BAHN_EXPERT:
      return 'bahn.expert';
    case ReihungQuelle.OEBB:
      return 'ÖBB Live';
    case ReihungQuelle.SBB:
      return 'SBB Mobile';
  }
  return 'unbekannt';
}

export function mapHafasSektorToSektor(sektor: HafasSektor): Sektor {
  return {
    name: sektor.name,
    anfangAmHalt: sektor.startProzent,
    endeAmHalt: sektor.endeProzent,
  };
}

export function mapHafasFahrzeugGruppeToWagenGruppe(fzgGruppe: FahrzeugGruppe): WagenGruppe {
  return {
    allWagen: fzgGruppe.fahrzeuge.map(mapHafasFahrzeugToWagen),
    bezeichnung: fzgGruppe.gruppenName,
    extraBezeichung: fzgGruppe.remarks !== '' ? fzgGruppe.remarks : null,
    baureihe: mapHafasBaureiheToBaureihe(fzgGruppe.baureihe),
    fahrtNummer: fzgGruppe.vzn,
    triebzugNummer: fzgGruppe.tzn !== 0 ? fzgGruppe.tzn : null,
    startHalt: fzgGruppe.start,
    zielHalt: fzgGruppe.ziel,
    anfangAmHalt: fzgGruppe.startProzent,
    endeAmHalt: fzgGruppe.endeProzent,
  };
}

export function mapHafasFahrzeugToWagen(fahrzeug: Fahrzeug): Wagen {
  return {
    bauart: fahrzeug.typ,
    uicNummer: fahrzeug.uic !== '' ? fahrzeug.uic : null,
    wagenordnungNummer: fahrzeug.won !== '' ? fahrzeug.won : null,
    allCSS: {
      allTextKlasse: mapHafasTextKlassen(fahrzeug.cssKlassen?.textKlassen),
      allFahrzeugEigenschaftenKlasse: mapHafasFahrzeugEigenschaften(fahrzeug.cssKlassen?.fahrzeugEigenschaftenKlassen),
      allFahrzeugKlasse: mapHafasFahrzeugKlassen(fahrzeug.cssKlassen?.fahrzeugKlassen),
      anfangAmHalt: fahrzeug.startProzent,
      endeAmHalt: fahrzeug.endeProzent,
    },
    status: WagenStatus.OFFEN,
    allSonderSitzplatzGruppe: mapHafasComfortSitze(fahrzeug.comfortSitze),
    auslastung: AuslastungWert.Unbekannt,
    klasse: fahrzeug.klasse,
    sektor: fahrzeug.sektor,
  };
}

function mapHafasComfortSitze(comfortSitze: ComfortSitze | undefined): SitzplatzGruppe[] {
  if (comfortSitze === undefined) {
    return [];
  }
  return [
    {
      typ: SitzplatzGruppeTyp.BAHNBONUS,
      sitzPlaetze: comfortSitze.sitze,
    },
  ];
}

function mapHafasFahrzeugKlassen(fahrzeugKlassen: FahrzeugKlassen | undefined): FahrzeugKlasse[] {
  const result: FahrzeugKlasse[] = [];
  if (fahrzeugKlassen === undefined) {
    return result;
  }
  if (fahrzeugKlassen.lok) result.push(FahrzeugKlasse.LOK);
  if (fahrzeugKlassen.triebkopf) result.push(FahrzeugKlasse.TRIEBKOPF);
  if (fahrzeugKlassen.steuerwagen) result.push(FahrzeugKlasse.STEUERWAGEN);
  if (fahrzeugKlassen.wagen) result.push(FahrzeugKlasse.WAGEN);
  return result;
}

function mapHafasTextKlassen(textKlassen: TextKlassen | undefined): TextKlasse[] {
  const result: TextKlasse[] = [];
  if (textKlassen === undefined) {
    return result;
  }
  if (textKlassen.verschlossen) result.push(TextKlasse.GESCHLOSSEN);
  return result;
}

function mapHafasFahrzeugEigenschaften(
  fahrzeugEigenschaftenKlassen: FahrzeugEigenschaftenKlassen | undefined,
): FahrzeugEigenschaftenKlasse[] {
  const result: FahrzeugEigenschaftenKlasse[] = [];
  if (fahrzeugEigenschaftenKlassen === undefined) {
    return result;
  }
  if (fahrzeugEigenschaftenKlassen.comfortlinks) result.push(FahrzeugEigenschaftenKlasse.COMFORT_LINKS);
  if (fahrzeugEigenschaftenKlassen.comfortrechts) result.push(FahrzeugEigenschaftenKlasse.COMFORT_RECHTS);
  if (fahrzeugEigenschaftenKlassen.ersteklasse) result.push(FahrzeugEigenschaftenKlasse.ERSTE_KLASSE);
  if (fahrzeugEigenschaftenKlassen.ersteklasserechts) result.push(FahrzeugEigenschaftenKlasse.ERSTE_KLASSE_RECHTS);
  if (fahrzeugEigenschaftenKlassen.ersteklasseoben) result.push(FahrzeugEigenschaftenKlasse.ERSTE_KLASSE_OBEN);
  if (fahrzeugEigenschaftenKlassen.ersteklasselinks) result.push(FahrzeugEigenschaftenKlasse.ERSTE_KLASSE_LINKS);
  if (fahrzeugEigenschaftenKlassen.zweiteklasse) result.push(FahrzeugEigenschaftenKlasse.ZWEITE_KLASSE);
  if (fahrzeugEigenschaftenKlassen.zweiteklasserechts) result.push(FahrzeugEigenschaftenKlasse.ZWEITE_KLASSE_RECHTS);
  if (fahrzeugEigenschaftenKlassen.zweiteklasselinks) result.push(FahrzeugEigenschaftenKlasse.ZWEITE_KLASSE_LINKS);
  if (fahrzeugEigenschaftenKlassen.liegewagen) result.push(FahrzeugEigenschaftenKlasse.LIEGEWAGEN);
  if (fahrzeugEigenschaftenKlassen.restaurant) result.push(FahrzeugEigenschaftenKlasse.RESTAURANT);
  if (fahrzeugEigenschaftenKlassen.schlafwagen) result.push(FahrzeugEigenschaftenKlasse.SCHLAFWAGEN);
  return result;
}
