<template>
  <ion-footer v-if="!hasNoRelevantLegs" class="ion-no-border">
    <ion-toolbar class="journey-overview">
      <div class="ion-text-center">
        <div class="first-line">
          {{ command }}
          <span class="time">
            {{ time }}h
            <span v-if="hasDelay" class="delay" :class="{ over5: isDelayOver5Minutes }">{{ delay }}</span>
          </span>
          {{ station }}
        </div>
        <div class="second-line"></div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import useReiseBegleitungCoreData from '@/composables/reise-begleitung/useReiseBegleitungCoreData';
import useReiseBegleitungTexts from '@/composables/reise-begleitung/useReiseBegleitungTexts';
import { IonFooter, IonToolbar } from '@ionic/vue';

export default {
  name: 'JourneyOverlay',
  components: {
    IonFooter,
    IonToolbar,
  },
  setup() {
    const journeyAssistanceCoreData = useReiseBegleitungCoreData();

    const assistanceTexts = useReiseBegleitungTexts(
      journeyAssistanceCoreData.currentTime,
      journeyAssistanceCoreData.isCurrentlyOnTrain,
      journeyAssistanceCoreData.lastDeparture,
      journeyAssistanceCoreData.nextArrival,
      journeyAssistanceCoreData.nextDeparture,
    );
    return {
      ...journeyAssistanceCoreData,
      ...assistanceTexts,
    };
  },
};
</script>

<style scoped lang="scss">
@import 'src/theme/db-style';

.journey-overview {
  --background: #{color('cool-gray', 800)};
  --color: #{color('cool-gray', 100)};

  font-weight: bold;

  .delay {
    color: $puenktlich;
    &.over5 {
      color: $verspaetet;
    }
  }
}
</style>
