import { api } from '@zentrains/api';
import { ReiseIdentifikation } from '@/model/reise';
import { mapToExternalReiseQuelle } from '@/mapper/to-api/reiseQuelle';

export function mapToExternalReiseIdentifikation(reiseId: ReiseIdentifikation): api.ReiseIdentifikation {
  return {
    reiseId: reiseId.reiseId,
    quelle: mapToExternalReiseQuelle(reiseId.reiseQuelle),
  };
}
