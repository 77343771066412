import { type AbschnittDto } from '@/stores/dto/AbschnittDto';
import { type Abschnitt, type AbschnittFluegel } from '@/model/abschnitt';
import { mapDtoToFahrt } from '@/mapper/from-store-dto/fahrt';
import { mapDtoToAbschnittFahrtereignis } from '@/mapper/from-store-dto/abschnittFahrtereignis';
import { mapDtoToHalt } from '@/mapper/from-store-dto/halt';
import { mapDtoToMeldung } from '@/mapper/from-store-dto/meldung';
import { type AbschnittFluegelDto } from '@/stores/dto/AbschnittFluegelDto';

export function mapDtoToAbschnitt(abschnitt: AbschnittDto): Abschnitt {
  return {
    fahrtId: abschnitt.fahrtId,
    typ: abschnitt.typ,
    fahrt: mapDtoToFahrt(abschnitt.fahrt),
    abfahrt: mapDtoToAbschnittFahrtereignis(abschnitt.abfahrt),
    ankunft: mapDtoToAbschnittFahrtereignis(abschnitt.ankunft),
    dauer: abschnitt.dauer,
    isAusfall: abschnitt.isAusfall,
    isErreichbar: abschnitt.isErreichbar,
    auslastung: abschnitt.auslastung,
    umstiegDauer: abschnitt.umstiegZeit,
    umstiegBewertung: abschnitt.umstiegBewertung,
    allFluegel: abschnitt.allFluegel.map(mapDtoToAbschnittFluegel),
    allHalt: abschnitt.allHalt.map(mapDtoToHalt),
    allMeldung: abschnitt.allMeldung.map(mapDtoToMeldung),
  };
}

export function mapDtoToAbschnittFluegel(abschnittFluegel: AbschnittFluegelDto): AbschnittFluegel {
  return {
    fahrtId: abschnittFluegel.fahrtId,
    fahrt: mapDtoToFahrt(abschnittFluegel.fahrt),
    allMeldung: abschnittFluegel.allMeldung.map(mapDtoToMeldung),
  };
}
