// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.27.2
// source: hafas/hafas.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

export const protobufPackage = "hafas";

export enum LegType {
  TRAIN = 0,
  WALK = 1,
  TRANSFER = 2,
  DEVIATION = 3,
  CHECKIN = 4,
  CHECKOUT = 5,
  UNRECOGNIZED = -1,
}

export function legTypeFromJSON(object: any): LegType {
  switch (object) {
    case 0:
    case "TRAIN":
      return LegType.TRAIN;
    case 1:
    case "WALK":
      return LegType.WALK;
    case 2:
    case "TRANSFER":
      return LegType.TRANSFER;
    case 3:
    case "DEVIATION":
      return LegType.DEVIATION;
    case 4:
    case "CHECKIN":
      return LegType.CHECKIN;
    case 5:
    case "CHECKOUT":
      return LegType.CHECKOUT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LegType.UNRECOGNIZED;
  }
}

export function legTypeToJSON(object: LegType): string {
  switch (object) {
    case LegType.TRAIN:
      return "TRAIN";
    case LegType.WALK:
      return "WALK";
    case LegType.TRANSFER:
      return "TRANSFER";
    case LegType.DEVIATION:
      return "DEVIATION";
    case LegType.CHECKIN:
      return "CHECKIN";
    case LegType.CHECKOUT:
      return "CHECKOUT";
    case LegType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ChangeRating {
  CONNECTION_UNKNOWN = 0,
  CONNECTION_SECURE = 1,
  CONNECTION_PROBABLE = 2,
  CONNECTION_NO_INFO = 3,
  CONNECTION_IMPROBABLE = 4,
  CONNECTION_DENIED = 5,
  UNRECOGNIZED = -1,
}

export function changeRatingFromJSON(object: any): ChangeRating {
  switch (object) {
    case 0:
    case "CONNECTION_UNKNOWN":
      return ChangeRating.CONNECTION_UNKNOWN;
    case 1:
    case "CONNECTION_SECURE":
      return ChangeRating.CONNECTION_SECURE;
    case 2:
    case "CONNECTION_PROBABLE":
      return ChangeRating.CONNECTION_PROBABLE;
    case 3:
    case "CONNECTION_NO_INFO":
      return ChangeRating.CONNECTION_NO_INFO;
    case 4:
    case "CONNECTION_IMPROBABLE":
      return ChangeRating.CONNECTION_IMPROBABLE;
    case 5:
    case "CONNECTION_DENIED":
      return ChangeRating.CONNECTION_DENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChangeRating.UNRECOGNIZED;
  }
}

export function changeRatingToJSON(object: ChangeRating): string {
  switch (object) {
    case ChangeRating.CONNECTION_UNKNOWN:
      return "CONNECTION_UNKNOWN";
    case ChangeRating.CONNECTION_SECURE:
      return "CONNECTION_SECURE";
    case ChangeRating.CONNECTION_PROBABLE:
      return "CONNECTION_PROBABLE";
    case ChangeRating.CONNECTION_NO_INFO:
      return "CONNECTION_NO_INFO";
    case ChangeRating.CONNECTION_IMPROBABLE:
      return "CONNECTION_IMPROBABLE";
    case ChangeRating.CONNECTION_DENIED:
      return "CONNECTION_DENIED";
    case ChangeRating.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AuslastungsValue {
  UNBEKANNT = 0,
  GERING = 1,
  HOCH = 2,
  SEHR_HOCH = 3,
  AUSGEBUCHT = 4,
  UNRECOGNIZED = -1,
}

export function auslastungsValueFromJSON(object: any): AuslastungsValue {
  switch (object) {
    case 0:
    case "UNBEKANNT":
      return AuslastungsValue.UNBEKANNT;
    case 1:
    case "GERING":
      return AuslastungsValue.GERING;
    case 2:
    case "HOCH":
      return AuslastungsValue.HOCH;
    case 3:
    case "SEHR_HOCH":
      return AuslastungsValue.SEHR_HOCH;
    case 4:
    case "AUSGEBUCHT":
      return AuslastungsValue.AUSGEBUCHT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuslastungsValue.UNRECOGNIZED;
  }
}

export function auslastungsValueToJSON(object: AuslastungsValue): string {
  switch (object) {
    case AuslastungsValue.UNBEKANNT:
      return "UNBEKANNT";
    case AuslastungsValue.GERING:
      return "GERING";
    case AuslastungsValue.HOCH:
      return "HOCH";
    case AuslastungsValue.SEHR_HOCH:
      return "SEHR_HOCH";
    case AuslastungsValue.AUSGEBUCHT:
      return "AUSGEBUCHT";
    case AuslastungsValue.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum IrisMessageType {
  DELAY = 0,
  QOS = 1,
  UNRECOGNIZED = -1,
}

export function irisMessageTypeFromJSON(object: any): IrisMessageType {
  switch (object) {
    case 0:
    case "DELAY":
      return IrisMessageType.DELAY;
    case 1:
    case "QOS":
      return IrisMessageType.QOS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IrisMessageType.UNRECOGNIZED;
  }
}

export function irisMessageTypeToJSON(object: IrisMessageType): string {
  switch (object) {
    case IrisMessageType.DELAY:
      return "DELAY";
    case IrisMessageType.QOS:
      return "QOS";
    case IrisMessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ReihungQuelle {
  DB_NAVIGATOR_ALT = 0,
  DB_NAVIGATOR_NEU = 1,
  BAHNHOF_LIVE = 2,
  BAHN_EXPERT = 3,
  OEBB = 4,
  SBB = 5,
  UNRECOGNIZED = -1,
}

export function reihungQuelleFromJSON(object: any): ReihungQuelle {
  switch (object) {
    case 0:
    case "DB_NAVIGATOR_ALT":
      return ReihungQuelle.DB_NAVIGATOR_ALT;
    case 1:
    case "DB_NAVIGATOR_NEU":
      return ReihungQuelle.DB_NAVIGATOR_NEU;
    case 2:
    case "BAHNHOF_LIVE":
      return ReihungQuelle.BAHNHOF_LIVE;
    case 3:
    case "BAHN_EXPERT":
      return ReihungQuelle.BAHN_EXPERT;
    case 4:
    case "OEBB":
      return ReihungQuelle.OEBB;
    case 5:
    case "SBB":
      return ReihungQuelle.SBB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReihungQuelle.UNRECOGNIZED;
  }
}

export function reihungQuelleToJSON(object: ReihungQuelle): string {
  switch (object) {
    case ReihungQuelle.DB_NAVIGATOR_ALT:
      return "DB_NAVIGATOR_ALT";
    case ReihungQuelle.DB_NAVIGATOR_NEU:
      return "DB_NAVIGATOR_NEU";
    case ReihungQuelle.BAHNHOF_LIVE:
      return "BAHNHOF_LIVE";
    case ReihungQuelle.BAHN_EXPERT:
      return "BAHN_EXPERT";
    case ReihungQuelle.OEBB:
      return "OEBB";
    case ReihungQuelle.SBB:
      return "SBB";
    case ReihungQuelle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Baureihe {
  UNKNOWN = 0,
  BR401 = 1,
  BR402 = 2,
  BR403 = 3,
  BR406 = 4,
  BR407 = 5,
  BR410_1 = 6,
  BR411 = 7,
  BR412 = 8,
  BR415 = 9,
  BR401_LDV = 10,
  BR401_9T = 11,
  BR411_S1 = 12,
  BR411_S2 = 13,
  BR412_7T = 14,
  BR412_13T = 15,
  BR403R = 16,
  BR403_S1 = 17,
  BR403_S2 = 18,
  BR406R = 19,
  IC2_TWIN = 20,
  IC2_KISS = 21,
  MET = 22,
  TGV = 23,
  EC = 24,
  IC = 25,
  RJ = 26,
  ETR610 = 27,
  NJ = 28,
  BR440 = 29,
  BR442 = 30,
  BR462 = 31,
  BR463 = 32,
  BR612 = 33,
  LINT = 34,
  BR628 = 35,
  BR650 = 36,
  DOSTO = 37,
  BR423 = 38,
  BR430 = 39,
  BR101 = 40,
  BR146 = 41,
  BR193 = 42,
  BR446 = 43,
  BR642 = 44,
  BR643 = 45,
  BR633 = 46,
  BR425 = 47,
  BR408 = 48,
  DSB_IC3 = 49,
  BR632 = 50,
  OEBB_4024 = 51,
  OEBB_4746 = 52,
  OEBB_1016 = 53,
  OEBB_1116 = 54,
  OEBB_1216 = 55,
  OEBB_CJX = 56,
  OEBB_DOSTO = 57,
  BR427 = 58,
  OEBB_4023 = 59,
  UNRECOGNIZED = -1,
}

export function baureiheFromJSON(object: any): Baureihe {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Baureihe.UNKNOWN;
    case 1:
    case "BR401":
      return Baureihe.BR401;
    case 2:
    case "BR402":
      return Baureihe.BR402;
    case 3:
    case "BR403":
      return Baureihe.BR403;
    case 4:
    case "BR406":
      return Baureihe.BR406;
    case 5:
    case "BR407":
      return Baureihe.BR407;
    case 6:
    case "BR410_1":
      return Baureihe.BR410_1;
    case 7:
    case "BR411":
      return Baureihe.BR411;
    case 8:
    case "BR412":
      return Baureihe.BR412;
    case 9:
    case "BR415":
      return Baureihe.BR415;
    case 10:
    case "BR401_LDV":
      return Baureihe.BR401_LDV;
    case 11:
    case "BR401_9T":
      return Baureihe.BR401_9T;
    case 12:
    case "BR411_S1":
      return Baureihe.BR411_S1;
    case 13:
    case "BR411_S2":
      return Baureihe.BR411_S2;
    case 14:
    case "BR412_7T":
      return Baureihe.BR412_7T;
    case 15:
    case "BR412_13T":
      return Baureihe.BR412_13T;
    case 16:
    case "BR403R":
      return Baureihe.BR403R;
    case 17:
    case "BR403_S1":
      return Baureihe.BR403_S1;
    case 18:
    case "BR403_S2":
      return Baureihe.BR403_S2;
    case 19:
    case "BR406R":
      return Baureihe.BR406R;
    case 20:
    case "IC2_TWIN":
      return Baureihe.IC2_TWIN;
    case 21:
    case "IC2_KISS":
      return Baureihe.IC2_KISS;
    case 22:
    case "MET":
      return Baureihe.MET;
    case 23:
    case "TGV":
      return Baureihe.TGV;
    case 24:
    case "EC":
      return Baureihe.EC;
    case 25:
    case "IC":
      return Baureihe.IC;
    case 26:
    case "RJ":
      return Baureihe.RJ;
    case 27:
    case "ETR610":
      return Baureihe.ETR610;
    case 28:
    case "NJ":
      return Baureihe.NJ;
    case 29:
    case "BR440":
      return Baureihe.BR440;
    case 30:
    case "BR442":
      return Baureihe.BR442;
    case 31:
    case "BR462":
      return Baureihe.BR462;
    case 32:
    case "BR463":
      return Baureihe.BR463;
    case 33:
    case "BR612":
      return Baureihe.BR612;
    case 34:
    case "LINT":
      return Baureihe.LINT;
    case 35:
    case "BR628":
      return Baureihe.BR628;
    case 36:
    case "BR650":
      return Baureihe.BR650;
    case 37:
    case "DOSTO":
      return Baureihe.DOSTO;
    case 38:
    case "BR423":
      return Baureihe.BR423;
    case 39:
    case "BR430":
      return Baureihe.BR430;
    case 40:
    case "BR101":
      return Baureihe.BR101;
    case 41:
    case "BR146":
      return Baureihe.BR146;
    case 42:
    case "BR193":
      return Baureihe.BR193;
    case 43:
    case "BR446":
      return Baureihe.BR446;
    case 44:
    case "BR642":
      return Baureihe.BR642;
    case 45:
    case "BR643":
      return Baureihe.BR643;
    case 46:
    case "BR633":
      return Baureihe.BR633;
    case 47:
    case "BR425":
      return Baureihe.BR425;
    case 48:
    case "BR408":
      return Baureihe.BR408;
    case 49:
    case "DSB_IC3":
      return Baureihe.DSB_IC3;
    case 50:
    case "BR632":
      return Baureihe.BR632;
    case 51:
    case "OEBB_4024":
      return Baureihe.OEBB_4024;
    case 52:
    case "OEBB_4746":
      return Baureihe.OEBB_4746;
    case 53:
    case "OEBB_1016":
      return Baureihe.OEBB_1016;
    case 54:
    case "OEBB_1116":
      return Baureihe.OEBB_1116;
    case 55:
    case "OEBB_1216":
      return Baureihe.OEBB_1216;
    case 56:
    case "OEBB_CJX":
      return Baureihe.OEBB_CJX;
    case 57:
    case "OEBB_DOSTO":
      return Baureihe.OEBB_DOSTO;
    case 58:
    case "BR427":
      return Baureihe.BR427;
    case 59:
    case "OEBB_4023":
      return Baureihe.OEBB_4023;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Baureihe.UNRECOGNIZED;
  }
}

export function baureiheToJSON(object: Baureihe): string {
  switch (object) {
    case Baureihe.UNKNOWN:
      return "UNKNOWN";
    case Baureihe.BR401:
      return "BR401";
    case Baureihe.BR402:
      return "BR402";
    case Baureihe.BR403:
      return "BR403";
    case Baureihe.BR406:
      return "BR406";
    case Baureihe.BR407:
      return "BR407";
    case Baureihe.BR410_1:
      return "BR410_1";
    case Baureihe.BR411:
      return "BR411";
    case Baureihe.BR412:
      return "BR412";
    case Baureihe.BR415:
      return "BR415";
    case Baureihe.BR401_LDV:
      return "BR401_LDV";
    case Baureihe.BR401_9T:
      return "BR401_9T";
    case Baureihe.BR411_S1:
      return "BR411_S1";
    case Baureihe.BR411_S2:
      return "BR411_S2";
    case Baureihe.BR412_7T:
      return "BR412_7T";
    case Baureihe.BR412_13T:
      return "BR412_13T";
    case Baureihe.BR403R:
      return "BR403R";
    case Baureihe.BR403_S1:
      return "BR403_S1";
    case Baureihe.BR403_S2:
      return "BR403_S2";
    case Baureihe.BR406R:
      return "BR406R";
    case Baureihe.IC2_TWIN:
      return "IC2_TWIN";
    case Baureihe.IC2_KISS:
      return "IC2_KISS";
    case Baureihe.MET:
      return "MET";
    case Baureihe.TGV:
      return "TGV";
    case Baureihe.EC:
      return "EC";
    case Baureihe.IC:
      return "IC";
    case Baureihe.RJ:
      return "RJ";
    case Baureihe.ETR610:
      return "ETR610";
    case Baureihe.NJ:
      return "NJ";
    case Baureihe.BR440:
      return "BR440";
    case Baureihe.BR442:
      return "BR442";
    case Baureihe.BR462:
      return "BR462";
    case Baureihe.BR463:
      return "BR463";
    case Baureihe.BR612:
      return "BR612";
    case Baureihe.LINT:
      return "LINT";
    case Baureihe.BR628:
      return "BR628";
    case Baureihe.BR650:
      return "BR650";
    case Baureihe.DOSTO:
      return "DOSTO";
    case Baureihe.BR423:
      return "BR423";
    case Baureihe.BR430:
      return "BR430";
    case Baureihe.BR101:
      return "BR101";
    case Baureihe.BR146:
      return "BR146";
    case Baureihe.BR193:
      return "BR193";
    case Baureihe.BR446:
      return "BR446";
    case Baureihe.BR642:
      return "BR642";
    case Baureihe.BR643:
      return "BR643";
    case Baureihe.BR633:
      return "BR633";
    case Baureihe.BR425:
      return "BR425";
    case Baureihe.BR408:
      return "BR408";
    case Baureihe.DSB_IC3:
      return "DSB_IC3";
    case Baureihe.BR632:
      return "BR632";
    case Baureihe.OEBB_4024:
      return "OEBB_4024";
    case Baureihe.OEBB_4746:
      return "OEBB_4746";
    case Baureihe.OEBB_1016:
      return "OEBB_1016";
    case Baureihe.OEBB_1116:
      return "OEBB_1116";
    case Baureihe.OEBB_1216:
      return "OEBB_1216";
    case Baureihe.OEBB_CJX:
      return "OEBB_CJX";
    case Baureihe.OEBB_DOSTO:
      return "OEBB_DOSTO";
    case Baureihe.BR427:
      return "BR427";
    case Baureihe.OEBB_4023:
      return "OEBB_4023";
    case Baureihe.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RefreshJourneyRequest {
  ctxRecon: string[];
}

export interface UpdatedJourneyResponse {
  routes: Route[];
}

export interface AlternativesResponse {
  routes: RouteWithAlternatives[];
}

export interface RouteWithAlternatives {
  originalRoute: Route | undefined;
  alternatives: Route[];
}

export interface RouteFromDateTimeRequest {
  startEvaNumber: string;
  destinationEvaNumber: string;
  time: number;
  maxChanges: number;
  notOnlyFastestRoute: boolean;
  timeIsDeparture: boolean;
  minTransferTime: number;
  journeyFilter: JourneyFilter[];
  via: Via[];
  allowsBicycles: boolean;
  allowsNetzcard: boolean;
}

export interface RouteFromContextRequest {
  startEvaNumber: string;
  destinationEvaNumber: string;
  ctxRecon: string;
  maxChanges: number;
  notOnlyFastestRoute: boolean;
  timeIsDeparture: boolean;
  minTransferTime: number;
  journeyFilter: JourneyFilter[];
  via: Via[];
  allowsBicycles: boolean;
  allowsNetzcard: boolean;
}

export interface JourneyFilter {
  value: string;
  mode: string;
  type: string;
}

export interface Via {
  evaNumber: string;
  minChangeTime: number;
}

export interface RouteResponse {
  earlier: string;
  later: string;
  routes: Route[];
}

export interface Route {
  arrival: StopInfo | undefined;
  departure: StopInfo | undefined;
  isRideable: boolean;
  isAlternative: boolean;
  duration: number;
  changes: number;
  ctxRecon: string;
  legs: Leg[];
}

export interface Leg {
  type: LegType;
  arrival: StopInfo | undefined;
  departure: StopInfo | undefined;
  cancelled: boolean;
  changeDuration: number;
  duration: number;
  journeyId: string;
  segmentStart: Station | undefined;
  segmentDestination: Station | undefined;
  train: Train | undefined;
  auslastung: Auslastung | undefined;
  coachSequenceDeparture: Reihung | undefined;
  coachSequenceArrival: Reihung | undefined;
  finalDestination: string;
  isReachable: boolean;
  isSonderfahrt: boolean;
  isErsatzzug: boolean;
  stops: Stop[];
  wings: Leg[];
  messages: RemL[];
  irisMessages: IrisMessage[];
  disturbances: Disturbance[];
  allowsBicycles: boolean;
  allowsNetzcard: boolean;
  changeRating: ChangeRating;
}

export interface Train {
  type: string;
  number: number;
  line: string;
  operator: string;
  operatorId: string;
}

export interface CoachSequenceRequest {
  calculateCSSClassesForReihung: boolean;
  query: CoachSequenceQuery[];
}

export interface CoachSequenceQuery {
  number: number;
  date: number;
  type: string;
  adminId: string;
  evaNumber: string;
}

export interface TicketSearchRequest {
  ticketId: string;
  name: string;
}

export interface TrainDetailsRequest {
  calculateCSSClassesForReihung: boolean;
  query: TrainDetailsQuery[];
}

export interface TrainDetailsWithSearchRequest {
  calculateCSSClassesForReihung: boolean;
  query: TrainDetailsWithSearchQuery[];
}

export interface StreamTrainDetailsRequest {
  calculateCSSClassesForReihung: boolean;
  query: TrainDetailsQuery | undefined;
}

export interface StreamTrainDetailsWithSearchRequest {
  calculateCSSClassesForReihung: boolean;
  query: TrainDetailsWithSearchQuery | undefined;
}

export interface TrainSearchRequest {
  date: number;
  name: string;
}

export interface TrainDetailsResponse {
  id: string;
  trainDetails: TrainDetails | undefined;
}

export interface CoachSequenceResponse {
  response: { [key: number]: Reihung };
}

export interface CoachSequenceResponse_ResponseEntry {
  key: number;
  value: Reihung | undefined;
}

export interface TrainDetailsQuery {
  firstStopScheduledDeparture: number;
  lastStopScheduledArrival: number;
  firstStopEvaNumber: string;
  lastStopEvaNumber: string;
  jid: string;
  trainName: string;
  trainNumber: number;
}

export interface TrainDetailsWithSearchQuery {
  type: string;
  number: number;
  evaNumber: string;
  date: number;
  initialDeparture: number;
}

export interface TrainDetails {
  auslastung: Auslastung | undefined;
  arrival: StopInfo | undefined;
  departure: StopInfo | undefined;
  duration: number;
  finalDestination: string;
  journeyId: string;
  currentStop: Stop | undefined;
  segmentStart: Station | undefined;
  segmentDestination: Station | undefined;
  train: Train | undefined;
  cancelled: boolean;
  coachSequence: Reihung | undefined;
  messages: RemL[];
  stops: Stop[];
  disturbances: Disturbance[];
  allowsBicycles: boolean;
  allowsNetzcard: boolean;
}

export interface Auslastung {
  first: AuslastungsValue;
  second: AuslastungsValue;
}

export interface PlannedSequence {
  shortType: string;
  type: string;
}

export interface RemL {
  type: string;
  code: string;
  text: string;
  summary: string;
}

export interface Disturbance {
  id: string;
  fromDate: number;
  toDate: number;
  lastUpdated: number;
  fromStation: string;
  toStation: string;
  isResolved: boolean;
  title: string;
  text: string;
  impact: string[];
}

export interface IrisMessage {
  timestamp: number;
  superseded: boolean;
  code: number;
  type: IrisMessageType;
}

export interface StopInfo {
  scheduledTime: number;
  time: number;
  delay: number;
  scheduledPlatform: string;
  platform: string;
  hasDelay: boolean;
  cancelled: boolean;
  messages: RemL[];
}

export interface Stop {
  auslastung: Auslastung | undefined;
  arrival: StopInfo | undefined;
  departure: StopInfo | undefined;
  station: Station | undefined;
  additional: boolean;
  cancelled: boolean;
  messages: RemL[];
  irisMessages: IrisMessage[];
}

export interface Station {
  evaNumber: string;
  title: string;
}

export interface Reihung {
  istPlanReihung: boolean;
  fahrtrichtung: boolean;
  anderesZielOderZugnummer: boolean;
  zugGattung: string;
  startProzent: number;
  endeProzent: number;
  skalierung: number;
  vzn: number;
  fahrzeugGruppen: FahrzeugGruppe[];
  sektoren: Sektor[];
  quelle: ReihungQuelle;
  istCached: boolean;
}

export interface FahrzeugGruppe {
  baureihe: Baureihe;
  start: string;
  ziel: string;
  gruppenName: string;
  tzn: number;
  vzn: number;
  startProzent: number;
  endeProzent: number;
  fahrzeuge: Fahrzeug[];
  remarks: string;
}

export interface ComfortSitze {
  klasse: number;
  wagen: string;
  sitze: string;
}

export interface Fahrzeug {
  typ: string;
  uic: string;
  won: string;
  klasse: number;
  status: string;
  sektor: string;
  startProzent: number;
  endeProzent: number;
  cssKlassen: CSSKlassen | undefined;
  comfortSitze: ComfortSitze | undefined;
}

export interface CSSKlassen {
  textKlassen: TextKlassen | undefined;
  fahrzeugKlassen: FahrzeugKlassen | undefined;
  fahrzeugEigenschaftenKlassen: FahrzeugEigenschaftenKlassen | undefined;
}

export interface TextKlassen {
  verschlossen: boolean;
}

export interface FahrzeugKlassen {
  lok: boolean;
  steuerwagen: boolean;
  triebkopf: boolean;
  wagen: boolean;
}

export interface FahrzeugEigenschaftenKlassen {
  restaurant: boolean;
  schlafwagen: boolean;
  liegewagen: boolean;
  comfortlinks: boolean;
  comfortrechts: boolean;
  zweiteklasse: boolean;
  ersteklasse: boolean;
  ersteklasselinks: boolean;
  ersteklasserechts: boolean;
  ersteklasseoben: boolean;
  zweiteklasselinks: boolean;
  zweiteklasserechts: boolean;
}

export interface Sektor {
  name: string;
  startProzent: number;
  endeProzent: number;
}

function createBaseRefreshJourneyRequest(): RefreshJourneyRequest {
  return { ctxRecon: [] };
}

export const RefreshJourneyRequest = {
  encode(message: RefreshJourneyRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ctxRecon) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RefreshJourneyRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshJourneyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ctxRecon.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshJourneyRequest {
    return {
      ctxRecon: globalThis.Array.isArray(object?.ctxRecon) ? object.ctxRecon.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: RefreshJourneyRequest): unknown {
    const obj: any = {};
    if (message.ctxRecon?.length) {
      obj.ctxRecon = message.ctxRecon;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshJourneyRequest>, I>>(base?: I): RefreshJourneyRequest {
    return RefreshJourneyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshJourneyRequest>, I>>(object: I): RefreshJourneyRequest {
    const message = createBaseRefreshJourneyRequest();
    message.ctxRecon = object.ctxRecon?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdatedJourneyResponse(): UpdatedJourneyResponse {
  return { routes: [] };
}

export const UpdatedJourneyResponse = {
  encode(message: UpdatedJourneyResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.routes) {
      Route.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdatedJourneyResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatedJourneyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routes.push(Route.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatedJourneyResponse {
    return { routes: globalThis.Array.isArray(object?.routes) ? object.routes.map((e: any) => Route.fromJSON(e)) : [] };
  },

  toJSON(message: UpdatedJourneyResponse): unknown {
    const obj: any = {};
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => Route.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatedJourneyResponse>, I>>(base?: I): UpdatedJourneyResponse {
    return UpdatedJourneyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatedJourneyResponse>, I>>(object: I): UpdatedJourneyResponse {
    const message = createBaseUpdatedJourneyResponse();
    message.routes = object.routes?.map((e) => Route.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAlternativesResponse(): AlternativesResponse {
  return { routes: [] };
}

export const AlternativesResponse = {
  encode(message: AlternativesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.routes) {
      RouteWithAlternatives.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AlternativesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlternativesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routes.push(RouteWithAlternatives.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AlternativesResponse {
    return {
      routes: globalThis.Array.isArray(object?.routes)
        ? object.routes.map((e: any) => RouteWithAlternatives.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AlternativesResponse): unknown {
    const obj: any = {};
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => RouteWithAlternatives.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlternativesResponse>, I>>(base?: I): AlternativesResponse {
    return AlternativesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AlternativesResponse>, I>>(object: I): AlternativesResponse {
    const message = createBaseAlternativesResponse();
    message.routes = object.routes?.map((e) => RouteWithAlternatives.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRouteWithAlternatives(): RouteWithAlternatives {
  return { originalRoute: undefined, alternatives: [] };
}

export const RouteWithAlternatives = {
  encode(message: RouteWithAlternatives, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.originalRoute !== undefined) {
      Route.encode(message.originalRoute, writer.uint32(10).fork()).join();
    }
    for (const v of message.alternatives) {
      Route.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RouteWithAlternatives {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteWithAlternatives();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.originalRoute = Route.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alternatives.push(Route.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteWithAlternatives {
    return {
      originalRoute: isSet(object.originalRoute) ? Route.fromJSON(object.originalRoute) : undefined,
      alternatives: globalThis.Array.isArray(object?.alternatives)
        ? object.alternatives.map((e: any) => Route.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RouteWithAlternatives): unknown {
    const obj: any = {};
    if (message.originalRoute !== undefined) {
      obj.originalRoute = Route.toJSON(message.originalRoute);
    }
    if (message.alternatives?.length) {
      obj.alternatives = message.alternatives.map((e) => Route.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteWithAlternatives>, I>>(base?: I): RouteWithAlternatives {
    return RouteWithAlternatives.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteWithAlternatives>, I>>(object: I): RouteWithAlternatives {
    const message = createBaseRouteWithAlternatives();
    message.originalRoute = (object.originalRoute !== undefined && object.originalRoute !== null)
      ? Route.fromPartial(object.originalRoute)
      : undefined;
    message.alternatives = object.alternatives?.map((e) => Route.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRouteFromDateTimeRequest(): RouteFromDateTimeRequest {
  return {
    startEvaNumber: "",
    destinationEvaNumber: "",
    time: 0,
    maxChanges: 0,
    notOnlyFastestRoute: false,
    timeIsDeparture: false,
    minTransferTime: 0,
    journeyFilter: [],
    via: [],
    allowsBicycles: false,
    allowsNetzcard: false,
  };
}

export const RouteFromDateTimeRequest = {
  encode(message: RouteFromDateTimeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startEvaNumber !== "") {
      writer.uint32(10).string(message.startEvaNumber);
    }
    if (message.destinationEvaNumber !== "") {
      writer.uint32(18).string(message.destinationEvaNumber);
    }
    if (message.time !== 0) {
      writer.uint32(24).int64(message.time);
    }
    if (message.maxChanges !== 0) {
      writer.uint32(72).int32(message.maxChanges);
    }
    if (message.notOnlyFastestRoute !== false) {
      writer.uint32(80).bool(message.notOnlyFastestRoute);
    }
    if (message.timeIsDeparture !== false) {
      writer.uint32(88).bool(message.timeIsDeparture);
    }
    if (message.minTransferTime !== 0) {
      writer.uint32(96).int32(message.minTransferTime);
    }
    for (const v of message.journeyFilter) {
      JourneyFilter.encode(v!, writer.uint32(106).fork()).join();
    }
    for (const v of message.via) {
      Via.encode(v!, writer.uint32(114).fork()).join();
    }
    if (message.allowsBicycles !== false) {
      writer.uint32(120).bool(message.allowsBicycles);
    }
    if (message.allowsNetzcard !== false) {
      writer.uint32(128).bool(message.allowsNetzcard);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RouteFromDateTimeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteFromDateTimeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startEvaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destinationEvaNumber = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.time = longToNumber(reader.int64());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maxChanges = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.notOnlyFastestRoute = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.timeIsDeparture = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.minTransferTime = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.journeyFilter.push(JourneyFilter.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.via.push(Via.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.allowsBicycles = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.allowsNetzcard = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteFromDateTimeRequest {
    return {
      startEvaNumber: isSet(object.startEvaNumber) ? globalThis.String(object.startEvaNumber) : "",
      destinationEvaNumber: isSet(object.destinationEvaNumber) ? globalThis.String(object.destinationEvaNumber) : "",
      time: isSet(object.time) ? globalThis.Number(object.time) : 0,
      maxChanges: isSet(object.maxChanges) ? globalThis.Number(object.maxChanges) : 0,
      notOnlyFastestRoute: isSet(object.notOnlyFastestRoute) ? globalThis.Boolean(object.notOnlyFastestRoute) : false,
      timeIsDeparture: isSet(object.timeIsDeparture) ? globalThis.Boolean(object.timeIsDeparture) : false,
      minTransferTime: isSet(object.minTransferTime) ? globalThis.Number(object.minTransferTime) : 0,
      journeyFilter: globalThis.Array.isArray(object?.journeyFilter)
        ? object.journeyFilter.map((e: any) => JourneyFilter.fromJSON(e))
        : [],
      via: globalThis.Array.isArray(object?.via) ? object.via.map((e: any) => Via.fromJSON(e)) : [],
      allowsBicycles: isSet(object.allowsBicycles) ? globalThis.Boolean(object.allowsBicycles) : false,
      allowsNetzcard: isSet(object.allowsNetzcard) ? globalThis.Boolean(object.allowsNetzcard) : false,
    };
  },

  toJSON(message: RouteFromDateTimeRequest): unknown {
    const obj: any = {};
    if (message.startEvaNumber !== "") {
      obj.startEvaNumber = message.startEvaNumber;
    }
    if (message.destinationEvaNumber !== "") {
      obj.destinationEvaNumber = message.destinationEvaNumber;
    }
    if (message.time !== 0) {
      obj.time = Math.round(message.time);
    }
    if (message.maxChanges !== 0) {
      obj.maxChanges = Math.round(message.maxChanges);
    }
    if (message.notOnlyFastestRoute !== false) {
      obj.notOnlyFastestRoute = message.notOnlyFastestRoute;
    }
    if (message.timeIsDeparture !== false) {
      obj.timeIsDeparture = message.timeIsDeparture;
    }
    if (message.minTransferTime !== 0) {
      obj.minTransferTime = Math.round(message.minTransferTime);
    }
    if (message.journeyFilter?.length) {
      obj.journeyFilter = message.journeyFilter.map((e) => JourneyFilter.toJSON(e));
    }
    if (message.via?.length) {
      obj.via = message.via.map((e) => Via.toJSON(e));
    }
    if (message.allowsBicycles !== false) {
      obj.allowsBicycles = message.allowsBicycles;
    }
    if (message.allowsNetzcard !== false) {
      obj.allowsNetzcard = message.allowsNetzcard;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteFromDateTimeRequest>, I>>(base?: I): RouteFromDateTimeRequest {
    return RouteFromDateTimeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteFromDateTimeRequest>, I>>(object: I): RouteFromDateTimeRequest {
    const message = createBaseRouteFromDateTimeRequest();
    message.startEvaNumber = object.startEvaNumber ?? "";
    message.destinationEvaNumber = object.destinationEvaNumber ?? "";
    message.time = object.time ?? 0;
    message.maxChanges = object.maxChanges ?? 0;
    message.notOnlyFastestRoute = object.notOnlyFastestRoute ?? false;
    message.timeIsDeparture = object.timeIsDeparture ?? false;
    message.minTransferTime = object.minTransferTime ?? 0;
    message.journeyFilter = object.journeyFilter?.map((e) => JourneyFilter.fromPartial(e)) || [];
    message.via = object.via?.map((e) => Via.fromPartial(e)) || [];
    message.allowsBicycles = object.allowsBicycles ?? false;
    message.allowsNetzcard = object.allowsNetzcard ?? false;
    return message;
  },
};

function createBaseRouteFromContextRequest(): RouteFromContextRequest {
  return {
    startEvaNumber: "",
    destinationEvaNumber: "",
    ctxRecon: "",
    maxChanges: 0,
    notOnlyFastestRoute: false,
    timeIsDeparture: false,
    minTransferTime: 0,
    journeyFilter: [],
    via: [],
    allowsBicycles: false,
    allowsNetzcard: false,
  };
}

export const RouteFromContextRequest = {
  encode(message: RouteFromContextRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startEvaNumber !== "") {
      writer.uint32(10).string(message.startEvaNumber);
    }
    if (message.destinationEvaNumber !== "") {
      writer.uint32(18).string(message.destinationEvaNumber);
    }
    if (message.ctxRecon !== "") {
      writer.uint32(26).string(message.ctxRecon);
    }
    if (message.maxChanges !== 0) {
      writer.uint32(72).int32(message.maxChanges);
    }
    if (message.notOnlyFastestRoute !== false) {
      writer.uint32(80).bool(message.notOnlyFastestRoute);
    }
    if (message.timeIsDeparture !== false) {
      writer.uint32(88).bool(message.timeIsDeparture);
    }
    if (message.minTransferTime !== 0) {
      writer.uint32(96).int32(message.minTransferTime);
    }
    for (const v of message.journeyFilter) {
      JourneyFilter.encode(v!, writer.uint32(106).fork()).join();
    }
    for (const v of message.via) {
      Via.encode(v!, writer.uint32(114).fork()).join();
    }
    if (message.allowsBicycles !== false) {
      writer.uint32(120).bool(message.allowsBicycles);
    }
    if (message.allowsNetzcard !== false) {
      writer.uint32(128).bool(message.allowsNetzcard);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RouteFromContextRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteFromContextRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startEvaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destinationEvaNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ctxRecon = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maxChanges = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.notOnlyFastestRoute = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.timeIsDeparture = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.minTransferTime = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.journeyFilter.push(JourneyFilter.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.via.push(Via.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.allowsBicycles = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.allowsNetzcard = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteFromContextRequest {
    return {
      startEvaNumber: isSet(object.startEvaNumber) ? globalThis.String(object.startEvaNumber) : "",
      destinationEvaNumber: isSet(object.destinationEvaNumber) ? globalThis.String(object.destinationEvaNumber) : "",
      ctxRecon: isSet(object.ctxRecon) ? globalThis.String(object.ctxRecon) : "",
      maxChanges: isSet(object.maxChanges) ? globalThis.Number(object.maxChanges) : 0,
      notOnlyFastestRoute: isSet(object.notOnlyFastestRoute) ? globalThis.Boolean(object.notOnlyFastestRoute) : false,
      timeIsDeparture: isSet(object.timeIsDeparture) ? globalThis.Boolean(object.timeIsDeparture) : false,
      minTransferTime: isSet(object.minTransferTime) ? globalThis.Number(object.minTransferTime) : 0,
      journeyFilter: globalThis.Array.isArray(object?.journeyFilter)
        ? object.journeyFilter.map((e: any) => JourneyFilter.fromJSON(e))
        : [],
      via: globalThis.Array.isArray(object?.via) ? object.via.map((e: any) => Via.fromJSON(e)) : [],
      allowsBicycles: isSet(object.allowsBicycles) ? globalThis.Boolean(object.allowsBicycles) : false,
      allowsNetzcard: isSet(object.allowsNetzcard) ? globalThis.Boolean(object.allowsNetzcard) : false,
    };
  },

  toJSON(message: RouteFromContextRequest): unknown {
    const obj: any = {};
    if (message.startEvaNumber !== "") {
      obj.startEvaNumber = message.startEvaNumber;
    }
    if (message.destinationEvaNumber !== "") {
      obj.destinationEvaNumber = message.destinationEvaNumber;
    }
    if (message.ctxRecon !== "") {
      obj.ctxRecon = message.ctxRecon;
    }
    if (message.maxChanges !== 0) {
      obj.maxChanges = Math.round(message.maxChanges);
    }
    if (message.notOnlyFastestRoute !== false) {
      obj.notOnlyFastestRoute = message.notOnlyFastestRoute;
    }
    if (message.timeIsDeparture !== false) {
      obj.timeIsDeparture = message.timeIsDeparture;
    }
    if (message.minTransferTime !== 0) {
      obj.minTransferTime = Math.round(message.minTransferTime);
    }
    if (message.journeyFilter?.length) {
      obj.journeyFilter = message.journeyFilter.map((e) => JourneyFilter.toJSON(e));
    }
    if (message.via?.length) {
      obj.via = message.via.map((e) => Via.toJSON(e));
    }
    if (message.allowsBicycles !== false) {
      obj.allowsBicycles = message.allowsBicycles;
    }
    if (message.allowsNetzcard !== false) {
      obj.allowsNetzcard = message.allowsNetzcard;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteFromContextRequest>, I>>(base?: I): RouteFromContextRequest {
    return RouteFromContextRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteFromContextRequest>, I>>(object: I): RouteFromContextRequest {
    const message = createBaseRouteFromContextRequest();
    message.startEvaNumber = object.startEvaNumber ?? "";
    message.destinationEvaNumber = object.destinationEvaNumber ?? "";
    message.ctxRecon = object.ctxRecon ?? "";
    message.maxChanges = object.maxChanges ?? 0;
    message.notOnlyFastestRoute = object.notOnlyFastestRoute ?? false;
    message.timeIsDeparture = object.timeIsDeparture ?? false;
    message.minTransferTime = object.minTransferTime ?? 0;
    message.journeyFilter = object.journeyFilter?.map((e) => JourneyFilter.fromPartial(e)) || [];
    message.via = object.via?.map((e) => Via.fromPartial(e)) || [];
    message.allowsBicycles = object.allowsBicycles ?? false;
    message.allowsNetzcard = object.allowsNetzcard ?? false;
    return message;
  },
};

function createBaseJourneyFilter(): JourneyFilter {
  return { value: "", mode: "", type: "" };
}

export const JourneyFilter = {
  encode(message: JourneyFilter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.mode !== "") {
      writer.uint32(18).string(message.mode);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JourneyFilter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJourneyFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JourneyFilter {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      mode: isSet(object.mode) ? globalThis.String(object.mode) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: JourneyFilter): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.mode !== "") {
      obj.mode = message.mode;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JourneyFilter>, I>>(base?: I): JourneyFilter {
    return JourneyFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JourneyFilter>, I>>(object: I): JourneyFilter {
    const message = createBaseJourneyFilter();
    message.value = object.value ?? "";
    message.mode = object.mode ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseVia(): Via {
  return { evaNumber: "", minChangeTime: 0 };
}

export const Via = {
  encode(message: Via, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.evaNumber !== "") {
      writer.uint32(10).string(message.evaNumber);
    }
    if (message.minChangeTime !== 0) {
      writer.uint32(16).uint32(message.minChangeTime);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Via {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVia();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.minChangeTime = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Via {
    return {
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
      minChangeTime: isSet(object.minChangeTime) ? globalThis.Number(object.minChangeTime) : 0,
    };
  },

  toJSON(message: Via): unknown {
    const obj: any = {};
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    if (message.minChangeTime !== 0) {
      obj.minChangeTime = Math.round(message.minChangeTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Via>, I>>(base?: I): Via {
    return Via.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Via>, I>>(object: I): Via {
    const message = createBaseVia();
    message.evaNumber = object.evaNumber ?? "";
    message.minChangeTime = object.minChangeTime ?? 0;
    return message;
  },
};

function createBaseRouteResponse(): RouteResponse {
  return { earlier: "", later: "", routes: [] };
}

export const RouteResponse = {
  encode(message: RouteResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.earlier !== "") {
      writer.uint32(10).string(message.earlier);
    }
    if (message.later !== "") {
      writer.uint32(18).string(message.later);
    }
    for (const v of message.routes) {
      Route.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RouteResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.earlier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.later = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routes.push(Route.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteResponse {
    return {
      earlier: isSet(object.earlier) ? globalThis.String(object.earlier) : "",
      later: isSet(object.later) ? globalThis.String(object.later) : "",
      routes: globalThis.Array.isArray(object?.routes) ? object.routes.map((e: any) => Route.fromJSON(e)) : [],
    };
  },

  toJSON(message: RouteResponse): unknown {
    const obj: any = {};
    if (message.earlier !== "") {
      obj.earlier = message.earlier;
    }
    if (message.later !== "") {
      obj.later = message.later;
    }
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => Route.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteResponse>, I>>(base?: I): RouteResponse {
    return RouteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteResponse>, I>>(object: I): RouteResponse {
    const message = createBaseRouteResponse();
    message.earlier = object.earlier ?? "";
    message.later = object.later ?? "";
    message.routes = object.routes?.map((e) => Route.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRoute(): Route {
  return {
    arrival: undefined,
    departure: undefined,
    isRideable: false,
    isAlternative: false,
    duration: 0,
    changes: 0,
    ctxRecon: "",
    legs: [],
  };
}

export const Route = {
  encode(message: Route, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.arrival !== undefined) {
      StopInfo.encode(message.arrival, writer.uint32(10).fork()).join();
    }
    if (message.departure !== undefined) {
      StopInfo.encode(message.departure, writer.uint32(18).fork()).join();
    }
    if (message.isRideable !== false) {
      writer.uint32(24).bool(message.isRideable);
    }
    if (message.isAlternative !== false) {
      writer.uint32(56).bool(message.isAlternative);
    }
    if (message.duration !== 0) {
      writer.uint32(32).uint64(message.duration);
    }
    if (message.changes !== 0) {
      writer.uint32(40).uint32(message.changes);
    }
    if (message.ctxRecon !== "") {
      writer.uint32(50).string(message.ctxRecon);
    }
    for (const v of message.legs) {
      Leg.encode(v!, writer.uint32(82).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Route {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.arrival = StopInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.departure = StopInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isRideable = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isAlternative = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.changes = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ctxRecon = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.legs.push(Leg.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Route {
    return {
      arrival: isSet(object.arrival) ? StopInfo.fromJSON(object.arrival) : undefined,
      departure: isSet(object.departure) ? StopInfo.fromJSON(object.departure) : undefined,
      isRideable: isSet(object.isRideable) ? globalThis.Boolean(object.isRideable) : false,
      isAlternative: isSet(object.isAlternative) ? globalThis.Boolean(object.isAlternative) : false,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      changes: isSet(object.changes) ? globalThis.Number(object.changes) : 0,
      ctxRecon: isSet(object.ctxRecon) ? globalThis.String(object.ctxRecon) : "",
      legs: globalThis.Array.isArray(object?.legs) ? object.legs.map((e: any) => Leg.fromJSON(e)) : [],
    };
  },

  toJSON(message: Route): unknown {
    const obj: any = {};
    if (message.arrival !== undefined) {
      obj.arrival = StopInfo.toJSON(message.arrival);
    }
    if (message.departure !== undefined) {
      obj.departure = StopInfo.toJSON(message.departure);
    }
    if (message.isRideable !== false) {
      obj.isRideable = message.isRideable;
    }
    if (message.isAlternative !== false) {
      obj.isAlternative = message.isAlternative;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.changes !== 0) {
      obj.changes = Math.round(message.changes);
    }
    if (message.ctxRecon !== "") {
      obj.ctxRecon = message.ctxRecon;
    }
    if (message.legs?.length) {
      obj.legs = message.legs.map((e) => Leg.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Route>, I>>(base?: I): Route {
    return Route.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Route>, I>>(object: I): Route {
    const message = createBaseRoute();
    message.arrival = (object.arrival !== undefined && object.arrival !== null)
      ? StopInfo.fromPartial(object.arrival)
      : undefined;
    message.departure = (object.departure !== undefined && object.departure !== null)
      ? StopInfo.fromPartial(object.departure)
      : undefined;
    message.isRideable = object.isRideable ?? false;
    message.isAlternative = object.isAlternative ?? false;
    message.duration = object.duration ?? 0;
    message.changes = object.changes ?? 0;
    message.ctxRecon = object.ctxRecon ?? "";
    message.legs = object.legs?.map((e) => Leg.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLeg(): Leg {
  return {
    type: 0,
    arrival: undefined,
    departure: undefined,
    cancelled: false,
    changeDuration: 0,
    duration: 0,
    journeyId: "",
    segmentStart: undefined,
    segmentDestination: undefined,
    train: undefined,
    auslastung: undefined,
    coachSequenceDeparture: undefined,
    coachSequenceArrival: undefined,
    finalDestination: "",
    isReachable: false,
    isSonderfahrt: false,
    isErsatzzug: false,
    stops: [],
    wings: [],
    messages: [],
    irisMessages: [],
    disturbances: [],
    allowsBicycles: false,
    allowsNetzcard: false,
    changeRating: 0,
  };
}

export const Leg = {
  encode(message: Leg, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.arrival !== undefined) {
      StopInfo.encode(message.arrival, writer.uint32(18).fork()).join();
    }
    if (message.departure !== undefined) {
      StopInfo.encode(message.departure, writer.uint32(26).fork()).join();
    }
    if (message.cancelled !== false) {
      writer.uint32(32).bool(message.cancelled);
    }
    if (message.changeDuration !== 0) {
      writer.uint32(40).uint64(message.changeDuration);
    }
    if (message.duration !== 0) {
      writer.uint32(48).uint64(message.duration);
    }
    if (message.journeyId !== "") {
      writer.uint32(58).string(message.journeyId);
    }
    if (message.segmentStart !== undefined) {
      Station.encode(message.segmentStart, writer.uint32(66).fork()).join();
    }
    if (message.segmentDestination !== undefined) {
      Station.encode(message.segmentDestination, writer.uint32(74).fork()).join();
    }
    if (message.train !== undefined) {
      Train.encode(message.train, writer.uint32(82).fork()).join();
    }
    if (message.auslastung !== undefined) {
      Auslastung.encode(message.auslastung, writer.uint32(90).fork()).join();
    }
    if (message.coachSequenceDeparture !== undefined) {
      Reihung.encode(message.coachSequenceDeparture, writer.uint32(98).fork()).join();
    }
    if (message.coachSequenceArrival !== undefined) {
      Reihung.encode(message.coachSequenceArrival, writer.uint32(106).fork()).join();
    }
    if (message.finalDestination !== "") {
      writer.uint32(114).string(message.finalDestination);
    }
    if (message.isReachable !== false) {
      writer.uint32(120).bool(message.isReachable);
    }
    if (message.isSonderfahrt !== false) {
      writer.uint32(128).bool(message.isSonderfahrt);
    }
    if (message.isErsatzzug !== false) {
      writer.uint32(136).bool(message.isErsatzzug);
    }
    for (const v of message.stops) {
      Stop.encode(v!, writer.uint32(162).fork()).join();
    }
    for (const v of message.wings) {
      Leg.encode(v!, writer.uint32(170).fork()).join();
    }
    for (const v of message.messages) {
      RemL.encode(v!, writer.uint32(178).fork()).join();
    }
    for (const v of message.irisMessages) {
      IrisMessage.encode(v!, writer.uint32(186).fork()).join();
    }
    for (const v of message.disturbances) {
      Disturbance.encode(v!, writer.uint32(194).fork()).join();
    }
    if (message.allowsBicycles !== false) {
      writer.uint32(200).bool(message.allowsBicycles);
    }
    if (message.allowsNetzcard !== false) {
      writer.uint32(208).bool(message.allowsNetzcard);
    }
    if (message.changeRating !== 0) {
      writer.uint32(216).int32(message.changeRating);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Leg {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arrival = StopInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departure = StopInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.changeDuration = longToNumber(reader.uint64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.duration = longToNumber(reader.uint64());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.journeyId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.segmentStart = Station.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.segmentDestination = Station.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.train = Train.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.auslastung = Auslastung.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.coachSequenceDeparture = Reihung.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.coachSequenceArrival = Reihung.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.finalDestination = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.isReachable = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.isSonderfahrt = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.isErsatzzug = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.stops.push(Stop.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.wings.push(Leg.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.messages.push(RemL.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.irisMessages.push(IrisMessage.decode(reader, reader.uint32()));
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.disturbances.push(Disturbance.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.allowsBicycles = reader.bool();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.allowsNetzcard = reader.bool();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.changeRating = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Leg {
    return {
      type: isSet(object.type) ? legTypeFromJSON(object.type) : 0,
      arrival: isSet(object.arrival) ? StopInfo.fromJSON(object.arrival) : undefined,
      departure: isSet(object.departure) ? StopInfo.fromJSON(object.departure) : undefined,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      changeDuration: isSet(object.changeDuration) ? globalThis.Number(object.changeDuration) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      journeyId: isSet(object.journeyId) ? globalThis.String(object.journeyId) : "",
      segmentStart: isSet(object.segmentStart) ? Station.fromJSON(object.segmentStart) : undefined,
      segmentDestination: isSet(object.segmentDestination) ? Station.fromJSON(object.segmentDestination) : undefined,
      train: isSet(object.train) ? Train.fromJSON(object.train) : undefined,
      auslastung: isSet(object.auslastung) ? Auslastung.fromJSON(object.auslastung) : undefined,
      coachSequenceDeparture: isSet(object.coachSequenceDeparture)
        ? Reihung.fromJSON(object.coachSequenceDeparture)
        : undefined,
      coachSequenceArrival: isSet(object.coachSequenceArrival)
        ? Reihung.fromJSON(object.coachSequenceArrival)
        : undefined,
      finalDestination: isSet(object.finalDestination) ? globalThis.String(object.finalDestination) : "",
      isReachable: isSet(object.isReachable) ? globalThis.Boolean(object.isReachable) : false,
      isSonderfahrt: isSet(object.isSonderfahrt) ? globalThis.Boolean(object.isSonderfahrt) : false,
      isErsatzzug: isSet(object.isErsatzzug) ? globalThis.Boolean(object.isErsatzzug) : false,
      stops: globalThis.Array.isArray(object?.stops) ? object.stops.map((e: any) => Stop.fromJSON(e)) : [],
      wings: globalThis.Array.isArray(object?.wings) ? object.wings.map((e: any) => Leg.fromJSON(e)) : [],
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => RemL.fromJSON(e)) : [],
      irisMessages: globalThis.Array.isArray(object?.irisMessages)
        ? object.irisMessages.map((e: any) => IrisMessage.fromJSON(e))
        : [],
      disturbances: globalThis.Array.isArray(object?.disturbances)
        ? object.disturbances.map((e: any) => Disturbance.fromJSON(e))
        : [],
      allowsBicycles: isSet(object.allowsBicycles) ? globalThis.Boolean(object.allowsBicycles) : false,
      allowsNetzcard: isSet(object.allowsNetzcard) ? globalThis.Boolean(object.allowsNetzcard) : false,
      changeRating: isSet(object.changeRating) ? changeRatingFromJSON(object.changeRating) : 0,
    };
  },

  toJSON(message: Leg): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = legTypeToJSON(message.type);
    }
    if (message.arrival !== undefined) {
      obj.arrival = StopInfo.toJSON(message.arrival);
    }
    if (message.departure !== undefined) {
      obj.departure = StopInfo.toJSON(message.departure);
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.changeDuration !== 0) {
      obj.changeDuration = Math.round(message.changeDuration);
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.journeyId !== "") {
      obj.journeyId = message.journeyId;
    }
    if (message.segmentStart !== undefined) {
      obj.segmentStart = Station.toJSON(message.segmentStart);
    }
    if (message.segmentDestination !== undefined) {
      obj.segmentDestination = Station.toJSON(message.segmentDestination);
    }
    if (message.train !== undefined) {
      obj.train = Train.toJSON(message.train);
    }
    if (message.auslastung !== undefined) {
      obj.auslastung = Auslastung.toJSON(message.auslastung);
    }
    if (message.coachSequenceDeparture !== undefined) {
      obj.coachSequenceDeparture = Reihung.toJSON(message.coachSequenceDeparture);
    }
    if (message.coachSequenceArrival !== undefined) {
      obj.coachSequenceArrival = Reihung.toJSON(message.coachSequenceArrival);
    }
    if (message.finalDestination !== "") {
      obj.finalDestination = message.finalDestination;
    }
    if (message.isReachable !== false) {
      obj.isReachable = message.isReachable;
    }
    if (message.isSonderfahrt !== false) {
      obj.isSonderfahrt = message.isSonderfahrt;
    }
    if (message.isErsatzzug !== false) {
      obj.isErsatzzug = message.isErsatzzug;
    }
    if (message.stops?.length) {
      obj.stops = message.stops.map((e) => Stop.toJSON(e));
    }
    if (message.wings?.length) {
      obj.wings = message.wings.map((e) => Leg.toJSON(e));
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => RemL.toJSON(e));
    }
    if (message.irisMessages?.length) {
      obj.irisMessages = message.irisMessages.map((e) => IrisMessage.toJSON(e));
    }
    if (message.disturbances?.length) {
      obj.disturbances = message.disturbances.map((e) => Disturbance.toJSON(e));
    }
    if (message.allowsBicycles !== false) {
      obj.allowsBicycles = message.allowsBicycles;
    }
    if (message.allowsNetzcard !== false) {
      obj.allowsNetzcard = message.allowsNetzcard;
    }
    if (message.changeRating !== 0) {
      obj.changeRating = changeRatingToJSON(message.changeRating);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Leg>, I>>(base?: I): Leg {
    return Leg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Leg>, I>>(object: I): Leg {
    const message = createBaseLeg();
    message.type = object.type ?? 0;
    message.arrival = (object.arrival !== undefined && object.arrival !== null)
      ? StopInfo.fromPartial(object.arrival)
      : undefined;
    message.departure = (object.departure !== undefined && object.departure !== null)
      ? StopInfo.fromPartial(object.departure)
      : undefined;
    message.cancelled = object.cancelled ?? false;
    message.changeDuration = object.changeDuration ?? 0;
    message.duration = object.duration ?? 0;
    message.journeyId = object.journeyId ?? "";
    message.segmentStart = (object.segmentStart !== undefined && object.segmentStart !== null)
      ? Station.fromPartial(object.segmentStart)
      : undefined;
    message.segmentDestination = (object.segmentDestination !== undefined && object.segmentDestination !== null)
      ? Station.fromPartial(object.segmentDestination)
      : undefined;
    message.train = (object.train !== undefined && object.train !== null) ? Train.fromPartial(object.train) : undefined;
    message.auslastung = (object.auslastung !== undefined && object.auslastung !== null)
      ? Auslastung.fromPartial(object.auslastung)
      : undefined;
    message.coachSequenceDeparture =
      (object.coachSequenceDeparture !== undefined && object.coachSequenceDeparture !== null)
        ? Reihung.fromPartial(object.coachSequenceDeparture)
        : undefined;
    message.coachSequenceArrival = (object.coachSequenceArrival !== undefined && object.coachSequenceArrival !== null)
      ? Reihung.fromPartial(object.coachSequenceArrival)
      : undefined;
    message.finalDestination = object.finalDestination ?? "";
    message.isReachable = object.isReachable ?? false;
    message.isSonderfahrt = object.isSonderfahrt ?? false;
    message.isErsatzzug = object.isErsatzzug ?? false;
    message.stops = object.stops?.map((e) => Stop.fromPartial(e)) || [];
    message.wings = object.wings?.map((e) => Leg.fromPartial(e)) || [];
    message.messages = object.messages?.map((e) => RemL.fromPartial(e)) || [];
    message.irisMessages = object.irisMessages?.map((e) => IrisMessage.fromPartial(e)) || [];
    message.disturbances = object.disturbances?.map((e) => Disturbance.fromPartial(e)) || [];
    message.allowsBicycles = object.allowsBicycles ?? false;
    message.allowsNetzcard = object.allowsNetzcard ?? false;
    message.changeRating = object.changeRating ?? 0;
    return message;
  },
};

function createBaseTrain(): Train {
  return { type: "", number: 0, line: "", operator: "", operatorId: "" };
}

export const Train = {
  encode(message: Train, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.number !== 0) {
      writer.uint32(16).uint32(message.number);
    }
    if (message.line !== "") {
      writer.uint32(26).string(message.line);
    }
    if (message.operator !== "") {
      writer.uint32(34).string(message.operator);
    }
    if (message.operatorId !== "") {
      writer.uint32(42).string(message.operatorId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Train {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrain();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.number = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.line = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.operatorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Train {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
      line: isSet(object.line) ? globalThis.String(object.line) : "",
      operator: isSet(object.operator) ? globalThis.String(object.operator) : "",
      operatorId: isSet(object.operatorId) ? globalThis.String(object.operatorId) : "",
    };
  },

  toJSON(message: Train): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    if (message.line !== "") {
      obj.line = message.line;
    }
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.operatorId !== "") {
      obj.operatorId = message.operatorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Train>, I>>(base?: I): Train {
    return Train.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Train>, I>>(object: I): Train {
    const message = createBaseTrain();
    message.type = object.type ?? "";
    message.number = object.number ?? 0;
    message.line = object.line ?? "";
    message.operator = object.operator ?? "";
    message.operatorId = object.operatorId ?? "";
    return message;
  },
};

function createBaseCoachSequenceRequest(): CoachSequenceRequest {
  return { calculateCSSClassesForReihung: false, query: [] };
}

export const CoachSequenceRequest = {
  encode(message: CoachSequenceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.calculateCSSClassesForReihung !== false) {
      writer.uint32(8).bool(message.calculateCSSClassesForReihung);
    }
    for (const v of message.query) {
      CoachSequenceQuery.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CoachSequenceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoachSequenceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.calculateCSSClassesForReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query.push(CoachSequenceQuery.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoachSequenceRequest {
    return {
      calculateCSSClassesForReihung: isSet(object.calculateCSSClassesForReihung)
        ? globalThis.Boolean(object.calculateCSSClassesForReihung)
        : false,
      query: globalThis.Array.isArray(object?.query)
        ? object.query.map((e: any) => CoachSequenceQuery.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CoachSequenceRequest): unknown {
    const obj: any = {};
    if (message.calculateCSSClassesForReihung !== false) {
      obj.calculateCSSClassesForReihung = message.calculateCSSClassesForReihung;
    }
    if (message.query?.length) {
      obj.query = message.query.map((e) => CoachSequenceQuery.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoachSequenceRequest>, I>>(base?: I): CoachSequenceRequest {
    return CoachSequenceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoachSequenceRequest>, I>>(object: I): CoachSequenceRequest {
    const message = createBaseCoachSequenceRequest();
    message.calculateCSSClassesForReihung = object.calculateCSSClassesForReihung ?? false;
    message.query = object.query?.map((e) => CoachSequenceQuery.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCoachSequenceQuery(): CoachSequenceQuery {
  return { number: 0, date: 0, type: "", adminId: "", evaNumber: "" };
}

export const CoachSequenceQuery = {
  encode(message: CoachSequenceQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.number !== 0) {
      writer.uint32(8).uint32(message.number);
    }
    if (message.date !== 0) {
      writer.uint32(16).int64(message.date);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.adminId !== "") {
      writer.uint32(34).string(message.adminId);
    }
    if (message.evaNumber !== "") {
      writer.uint32(42).string(message.evaNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CoachSequenceQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoachSequenceQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.number = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.adminId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoachSequenceQuery {
    return {
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
      date: isSet(object.date) ? globalThis.Number(object.date) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      adminId: isSet(object.adminId) ? globalThis.String(object.adminId) : "",
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
    };
  },

  toJSON(message: CoachSequenceQuery): unknown {
    const obj: any = {};
    if (message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    if (message.date !== 0) {
      obj.date = Math.round(message.date);
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.adminId !== "") {
      obj.adminId = message.adminId;
    }
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoachSequenceQuery>, I>>(base?: I): CoachSequenceQuery {
    return CoachSequenceQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoachSequenceQuery>, I>>(object: I): CoachSequenceQuery {
    const message = createBaseCoachSequenceQuery();
    message.number = object.number ?? 0;
    message.date = object.date ?? 0;
    message.type = object.type ?? "";
    message.adminId = object.adminId ?? "";
    message.evaNumber = object.evaNumber ?? "";
    return message;
  },
};

function createBaseTicketSearchRequest(): TicketSearchRequest {
  return { ticketId: "", name: "" };
}

export const TicketSearchRequest = {
  encode(message: TicketSearchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ticketId !== "") {
      writer.uint32(10).string(message.ticketId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TicketSearchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ticketId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TicketSearchRequest {
    return {
      ticketId: isSet(object.ticketId) ? globalThis.String(object.ticketId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: TicketSearchRequest): unknown {
    const obj: any = {};
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketSearchRequest>, I>>(base?: I): TicketSearchRequest {
    return TicketSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TicketSearchRequest>, I>>(object: I): TicketSearchRequest {
    const message = createBaseTicketSearchRequest();
    message.ticketId = object.ticketId ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseTrainDetailsRequest(): TrainDetailsRequest {
  return { calculateCSSClassesForReihung: false, query: [] };
}

export const TrainDetailsRequest = {
  encode(message: TrainDetailsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.calculateCSSClassesForReihung !== false) {
      writer.uint32(8).bool(message.calculateCSSClassesForReihung);
    }
    for (const v of message.query) {
      TrainDetailsQuery.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetailsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.calculateCSSClassesForReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query.push(TrainDetailsQuery.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetailsRequest {
    return {
      calculateCSSClassesForReihung: isSet(object.calculateCSSClassesForReihung)
        ? globalThis.Boolean(object.calculateCSSClassesForReihung)
        : false,
      query: globalThis.Array.isArray(object?.query) ? object.query.map((e: any) => TrainDetailsQuery.fromJSON(e)) : [],
    };
  },

  toJSON(message: TrainDetailsRequest): unknown {
    const obj: any = {};
    if (message.calculateCSSClassesForReihung !== false) {
      obj.calculateCSSClassesForReihung = message.calculateCSSClassesForReihung;
    }
    if (message.query?.length) {
      obj.query = message.query.map((e) => TrainDetailsQuery.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetailsRequest>, I>>(base?: I): TrainDetailsRequest {
    return TrainDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetailsRequest>, I>>(object: I): TrainDetailsRequest {
    const message = createBaseTrainDetailsRequest();
    message.calculateCSSClassesForReihung = object.calculateCSSClassesForReihung ?? false;
    message.query = object.query?.map((e) => TrainDetailsQuery.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTrainDetailsWithSearchRequest(): TrainDetailsWithSearchRequest {
  return { calculateCSSClassesForReihung: false, query: [] };
}

export const TrainDetailsWithSearchRequest = {
  encode(message: TrainDetailsWithSearchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.calculateCSSClassesForReihung !== false) {
      writer.uint32(8).bool(message.calculateCSSClassesForReihung);
    }
    for (const v of message.query) {
      TrainDetailsWithSearchQuery.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetailsWithSearchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetailsWithSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.calculateCSSClassesForReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query.push(TrainDetailsWithSearchQuery.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetailsWithSearchRequest {
    return {
      calculateCSSClassesForReihung: isSet(object.calculateCSSClassesForReihung)
        ? globalThis.Boolean(object.calculateCSSClassesForReihung)
        : false,
      query: globalThis.Array.isArray(object?.query)
        ? object.query.map((e: any) => TrainDetailsWithSearchQuery.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TrainDetailsWithSearchRequest): unknown {
    const obj: any = {};
    if (message.calculateCSSClassesForReihung !== false) {
      obj.calculateCSSClassesForReihung = message.calculateCSSClassesForReihung;
    }
    if (message.query?.length) {
      obj.query = message.query.map((e) => TrainDetailsWithSearchQuery.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetailsWithSearchRequest>, I>>(base?: I): TrainDetailsWithSearchRequest {
    return TrainDetailsWithSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetailsWithSearchRequest>, I>>(
    object: I,
  ): TrainDetailsWithSearchRequest {
    const message = createBaseTrainDetailsWithSearchRequest();
    message.calculateCSSClassesForReihung = object.calculateCSSClassesForReihung ?? false;
    message.query = object.query?.map((e) => TrainDetailsWithSearchQuery.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStreamTrainDetailsRequest(): StreamTrainDetailsRequest {
  return { calculateCSSClassesForReihung: false, query: undefined };
}

export const StreamTrainDetailsRequest = {
  encode(message: StreamTrainDetailsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.calculateCSSClassesForReihung !== false) {
      writer.uint32(8).bool(message.calculateCSSClassesForReihung);
    }
    if (message.query !== undefined) {
      TrainDetailsQuery.encode(message.query, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StreamTrainDetailsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamTrainDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.calculateCSSClassesForReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query = TrainDetailsQuery.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamTrainDetailsRequest {
    return {
      calculateCSSClassesForReihung: isSet(object.calculateCSSClassesForReihung)
        ? globalThis.Boolean(object.calculateCSSClassesForReihung)
        : false,
      query: isSet(object.query) ? TrainDetailsQuery.fromJSON(object.query) : undefined,
    };
  },

  toJSON(message: StreamTrainDetailsRequest): unknown {
    const obj: any = {};
    if (message.calculateCSSClassesForReihung !== false) {
      obj.calculateCSSClassesForReihung = message.calculateCSSClassesForReihung;
    }
    if (message.query !== undefined) {
      obj.query = TrainDetailsQuery.toJSON(message.query);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamTrainDetailsRequest>, I>>(base?: I): StreamTrainDetailsRequest {
    return StreamTrainDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamTrainDetailsRequest>, I>>(object: I): StreamTrainDetailsRequest {
    const message = createBaseStreamTrainDetailsRequest();
    message.calculateCSSClassesForReihung = object.calculateCSSClassesForReihung ?? false;
    message.query = (object.query !== undefined && object.query !== null)
      ? TrainDetailsQuery.fromPartial(object.query)
      : undefined;
    return message;
  },
};

function createBaseStreamTrainDetailsWithSearchRequest(): StreamTrainDetailsWithSearchRequest {
  return { calculateCSSClassesForReihung: false, query: undefined };
}

export const StreamTrainDetailsWithSearchRequest = {
  encode(message: StreamTrainDetailsWithSearchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.calculateCSSClassesForReihung !== false) {
      writer.uint32(8).bool(message.calculateCSSClassesForReihung);
    }
    if (message.query !== undefined) {
      TrainDetailsWithSearchQuery.encode(message.query, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StreamTrainDetailsWithSearchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamTrainDetailsWithSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.calculateCSSClassesForReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query = TrainDetailsWithSearchQuery.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamTrainDetailsWithSearchRequest {
    return {
      calculateCSSClassesForReihung: isSet(object.calculateCSSClassesForReihung)
        ? globalThis.Boolean(object.calculateCSSClassesForReihung)
        : false,
      query: isSet(object.query) ? TrainDetailsWithSearchQuery.fromJSON(object.query) : undefined,
    };
  },

  toJSON(message: StreamTrainDetailsWithSearchRequest): unknown {
    const obj: any = {};
    if (message.calculateCSSClassesForReihung !== false) {
      obj.calculateCSSClassesForReihung = message.calculateCSSClassesForReihung;
    }
    if (message.query !== undefined) {
      obj.query = TrainDetailsWithSearchQuery.toJSON(message.query);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamTrainDetailsWithSearchRequest>, I>>(
    base?: I,
  ): StreamTrainDetailsWithSearchRequest {
    return StreamTrainDetailsWithSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamTrainDetailsWithSearchRequest>, I>>(
    object: I,
  ): StreamTrainDetailsWithSearchRequest {
    const message = createBaseStreamTrainDetailsWithSearchRequest();
    message.calculateCSSClassesForReihung = object.calculateCSSClassesForReihung ?? false;
    message.query = (object.query !== undefined && object.query !== null)
      ? TrainDetailsWithSearchQuery.fromPartial(object.query)
      : undefined;
    return message;
  },
};

function createBaseTrainSearchRequest(): TrainSearchRequest {
  return { date: 0, name: "" };
}

export const TrainSearchRequest = {
  encode(message: TrainSearchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.date !== 0) {
      writer.uint32(8).int64(message.date);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainSearchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.date = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainSearchRequest {
    return {
      date: isSet(object.date) ? globalThis.Number(object.date) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: TrainSearchRequest): unknown {
    const obj: any = {};
    if (message.date !== 0) {
      obj.date = Math.round(message.date);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainSearchRequest>, I>>(base?: I): TrainSearchRequest {
    return TrainSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainSearchRequest>, I>>(object: I): TrainSearchRequest {
    const message = createBaseTrainSearchRequest();
    message.date = object.date ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseTrainDetailsResponse(): TrainDetailsResponse {
  return { id: "", trainDetails: undefined };
}

export const TrainDetailsResponse = {
  encode(message: TrainDetailsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.trainDetails !== undefined) {
      TrainDetails.encode(message.trainDetails, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetailsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trainDetails = TrainDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetailsResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      trainDetails: isSet(object.trainDetails) ? TrainDetails.fromJSON(object.trainDetails) : undefined,
    };
  },

  toJSON(message: TrainDetailsResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.trainDetails !== undefined) {
      obj.trainDetails = TrainDetails.toJSON(message.trainDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetailsResponse>, I>>(base?: I): TrainDetailsResponse {
    return TrainDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetailsResponse>, I>>(object: I): TrainDetailsResponse {
    const message = createBaseTrainDetailsResponse();
    message.id = object.id ?? "";
    message.trainDetails = (object.trainDetails !== undefined && object.trainDetails !== null)
      ? TrainDetails.fromPartial(object.trainDetails)
      : undefined;
    return message;
  },
};

function createBaseCoachSequenceResponse(): CoachSequenceResponse {
  return { response: {} };
}

export const CoachSequenceResponse = {
  encode(message: CoachSequenceResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.response).forEach(([key, value]) => {
      CoachSequenceResponse_ResponseEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CoachSequenceResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoachSequenceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = CoachSequenceResponse_ResponseEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.response[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoachSequenceResponse {
    return {
      response: isObject(object.response)
        ? Object.entries(object.response).reduce<{ [key: number]: Reihung }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = Reihung.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: CoachSequenceResponse): unknown {
    const obj: any = {};
    if (message.response) {
      const entries = Object.entries(message.response);
      if (entries.length > 0) {
        obj.response = {};
        entries.forEach(([k, v]) => {
          obj.response[k] = Reihung.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoachSequenceResponse>, I>>(base?: I): CoachSequenceResponse {
    return CoachSequenceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoachSequenceResponse>, I>>(object: I): CoachSequenceResponse {
    const message = createBaseCoachSequenceResponse();
    message.response = Object.entries(object.response ?? {}).reduce<{ [key: number]: Reihung }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = Reihung.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCoachSequenceResponse_ResponseEntry(): CoachSequenceResponse_ResponseEntry {
  return { key: 0, value: undefined };
}

export const CoachSequenceResponse_ResponseEntry = {
  encode(message: CoachSequenceResponse_ResponseEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== undefined) {
      Reihung.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CoachSequenceResponse_ResponseEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoachSequenceResponse_ResponseEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Reihung.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CoachSequenceResponse_ResponseEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? Reihung.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CoachSequenceResponse_ResponseEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== undefined) {
      obj.value = Reihung.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CoachSequenceResponse_ResponseEntry>, I>>(
    base?: I,
  ): CoachSequenceResponse_ResponseEntry {
    return CoachSequenceResponse_ResponseEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CoachSequenceResponse_ResponseEntry>, I>>(
    object: I,
  ): CoachSequenceResponse_ResponseEntry {
    const message = createBaseCoachSequenceResponse_ResponseEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? Reihung.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseTrainDetailsQuery(): TrainDetailsQuery {
  return {
    firstStopScheduledDeparture: 0,
    lastStopScheduledArrival: 0,
    firstStopEvaNumber: "",
    lastStopEvaNumber: "",
    jid: "",
    trainName: "",
    trainNumber: 0,
  };
}

export const TrainDetailsQuery = {
  encode(message: TrainDetailsQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.firstStopScheduledDeparture !== 0) {
      writer.uint32(16).int64(message.firstStopScheduledDeparture);
    }
    if (message.lastStopScheduledArrival !== 0) {
      writer.uint32(24).int64(message.lastStopScheduledArrival);
    }
    if (message.firstStopEvaNumber !== "") {
      writer.uint32(34).string(message.firstStopEvaNumber);
    }
    if (message.lastStopEvaNumber !== "") {
      writer.uint32(42).string(message.lastStopEvaNumber);
    }
    if (message.jid !== "") {
      writer.uint32(50).string(message.jid);
    }
    if (message.trainName !== "") {
      writer.uint32(58).string(message.trainName);
    }
    if (message.trainNumber !== 0) {
      writer.uint32(64).uint32(message.trainNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetailsQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetailsQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.firstStopScheduledDeparture = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lastStopScheduledArrival = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.firstStopEvaNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastStopEvaNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.jid = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.trainName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.trainNumber = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetailsQuery {
    return {
      firstStopScheduledDeparture: isSet(object.firstStopScheduledDeparture)
        ? globalThis.Number(object.firstStopScheduledDeparture)
        : 0,
      lastStopScheduledArrival: isSet(object.lastStopScheduledArrival)
        ? globalThis.Number(object.lastStopScheduledArrival)
        : 0,
      firstStopEvaNumber: isSet(object.firstStopEvaNumber) ? globalThis.String(object.firstStopEvaNumber) : "",
      lastStopEvaNumber: isSet(object.lastStopEvaNumber) ? globalThis.String(object.lastStopEvaNumber) : "",
      jid: isSet(object.jid) ? globalThis.String(object.jid) : "",
      trainName: isSet(object.trainName) ? globalThis.String(object.trainName) : "",
      trainNumber: isSet(object.trainNumber) ? globalThis.Number(object.trainNumber) : 0,
    };
  },

  toJSON(message: TrainDetailsQuery): unknown {
    const obj: any = {};
    if (message.firstStopScheduledDeparture !== 0) {
      obj.firstStopScheduledDeparture = Math.round(message.firstStopScheduledDeparture);
    }
    if (message.lastStopScheduledArrival !== 0) {
      obj.lastStopScheduledArrival = Math.round(message.lastStopScheduledArrival);
    }
    if (message.firstStopEvaNumber !== "") {
      obj.firstStopEvaNumber = message.firstStopEvaNumber;
    }
    if (message.lastStopEvaNumber !== "") {
      obj.lastStopEvaNumber = message.lastStopEvaNumber;
    }
    if (message.jid !== "") {
      obj.jid = message.jid;
    }
    if (message.trainName !== "") {
      obj.trainName = message.trainName;
    }
    if (message.trainNumber !== 0) {
      obj.trainNumber = Math.round(message.trainNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetailsQuery>, I>>(base?: I): TrainDetailsQuery {
    return TrainDetailsQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetailsQuery>, I>>(object: I): TrainDetailsQuery {
    const message = createBaseTrainDetailsQuery();
    message.firstStopScheduledDeparture = object.firstStopScheduledDeparture ?? 0;
    message.lastStopScheduledArrival = object.lastStopScheduledArrival ?? 0;
    message.firstStopEvaNumber = object.firstStopEvaNumber ?? "";
    message.lastStopEvaNumber = object.lastStopEvaNumber ?? "";
    message.jid = object.jid ?? "";
    message.trainName = object.trainName ?? "";
    message.trainNumber = object.trainNumber ?? 0;
    return message;
  },
};

function createBaseTrainDetailsWithSearchQuery(): TrainDetailsWithSearchQuery {
  return { type: "", number: 0, evaNumber: "", date: 0, initialDeparture: 0 };
}

export const TrainDetailsWithSearchQuery = {
  encode(message: TrainDetailsWithSearchQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.number !== 0) {
      writer.uint32(16).uint32(message.number);
    }
    if (message.evaNumber !== "") {
      writer.uint32(26).string(message.evaNumber);
    }
    if (message.date !== 0) {
      writer.uint32(32).int64(message.date);
    }
    if (message.initialDeparture !== 0) {
      writer.uint32(40).int64(message.initialDeparture);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetailsWithSearchQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetailsWithSearchQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.number = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.date = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.initialDeparture = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetailsWithSearchQuery {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
      date: isSet(object.date) ? globalThis.Number(object.date) : 0,
      initialDeparture: isSet(object.initialDeparture) ? globalThis.Number(object.initialDeparture) : 0,
    };
  },

  toJSON(message: TrainDetailsWithSearchQuery): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    if (message.date !== 0) {
      obj.date = Math.round(message.date);
    }
    if (message.initialDeparture !== 0) {
      obj.initialDeparture = Math.round(message.initialDeparture);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetailsWithSearchQuery>, I>>(base?: I): TrainDetailsWithSearchQuery {
    return TrainDetailsWithSearchQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetailsWithSearchQuery>, I>>(object: I): TrainDetailsWithSearchQuery {
    const message = createBaseTrainDetailsWithSearchQuery();
    message.type = object.type ?? "";
    message.number = object.number ?? 0;
    message.evaNumber = object.evaNumber ?? "";
    message.date = object.date ?? 0;
    message.initialDeparture = object.initialDeparture ?? 0;
    return message;
  },
};

function createBaseTrainDetails(): TrainDetails {
  return {
    auslastung: undefined,
    arrival: undefined,
    departure: undefined,
    duration: 0,
    finalDestination: "",
    journeyId: "",
    currentStop: undefined,
    segmentStart: undefined,
    segmentDestination: undefined,
    train: undefined,
    cancelled: false,
    coachSequence: undefined,
    messages: [],
    stops: [],
    disturbances: [],
    allowsBicycles: false,
    allowsNetzcard: false,
  };
}

export const TrainDetails = {
  encode(message: TrainDetails, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.auslastung !== undefined) {
      Auslastung.encode(message.auslastung, writer.uint32(10).fork()).join();
    }
    if (message.arrival !== undefined) {
      StopInfo.encode(message.arrival, writer.uint32(18).fork()).join();
    }
    if (message.departure !== undefined) {
      StopInfo.encode(message.departure, writer.uint32(26).fork()).join();
    }
    if (message.duration !== 0) {
      writer.uint32(32).uint64(message.duration);
    }
    if (message.finalDestination !== "") {
      writer.uint32(42).string(message.finalDestination);
    }
    if (message.journeyId !== "") {
      writer.uint32(50).string(message.journeyId);
    }
    if (message.currentStop !== undefined) {
      Stop.encode(message.currentStop, writer.uint32(58).fork()).join();
    }
    if (message.segmentStart !== undefined) {
      Station.encode(message.segmentStart, writer.uint32(66).fork()).join();
    }
    if (message.segmentDestination !== undefined) {
      Station.encode(message.segmentDestination, writer.uint32(74).fork()).join();
    }
    if (message.train !== undefined) {
      Train.encode(message.train, writer.uint32(82).fork()).join();
    }
    if (message.cancelled !== false) {
      writer.uint32(88).bool(message.cancelled);
    }
    if (message.coachSequence !== undefined) {
      Reihung.encode(message.coachSequence, writer.uint32(106).fork()).join();
    }
    for (const v of message.messages) {
      RemL.encode(v!, writer.uint32(114).fork()).join();
    }
    for (const v of message.stops) {
      Stop.encode(v!, writer.uint32(122).fork()).join();
    }
    for (const v of message.disturbances) {
      Disturbance.encode(v!, writer.uint32(130).fork()).join();
    }
    if (message.allowsBicycles !== false) {
      writer.uint32(136).bool(message.allowsBicycles);
    }
    if (message.allowsNetzcard !== false) {
      writer.uint32(144).bool(message.allowsNetzcard);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TrainDetails {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.auslastung = Auslastung.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arrival = StopInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departure = StopInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = longToNumber(reader.uint64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.finalDestination = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.journeyId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.currentStop = Stop.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.segmentStart = Station.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.segmentDestination = Station.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.train = Train.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.coachSequence = Reihung.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.messages.push(RemL.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.stops.push(Stop.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.disturbances.push(Disturbance.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.allowsBicycles = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.allowsNetzcard = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainDetails {
    return {
      auslastung: isSet(object.auslastung) ? Auslastung.fromJSON(object.auslastung) : undefined,
      arrival: isSet(object.arrival) ? StopInfo.fromJSON(object.arrival) : undefined,
      departure: isSet(object.departure) ? StopInfo.fromJSON(object.departure) : undefined,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      finalDestination: isSet(object.finalDestination) ? globalThis.String(object.finalDestination) : "",
      journeyId: isSet(object.journeyId) ? globalThis.String(object.journeyId) : "",
      currentStop: isSet(object.currentStop) ? Stop.fromJSON(object.currentStop) : undefined,
      segmentStart: isSet(object.segmentStart) ? Station.fromJSON(object.segmentStart) : undefined,
      segmentDestination: isSet(object.segmentDestination) ? Station.fromJSON(object.segmentDestination) : undefined,
      train: isSet(object.train) ? Train.fromJSON(object.train) : undefined,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      coachSequence: isSet(object.coachSequence) ? Reihung.fromJSON(object.coachSequence) : undefined,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => RemL.fromJSON(e)) : [],
      stops: globalThis.Array.isArray(object?.stops) ? object.stops.map((e: any) => Stop.fromJSON(e)) : [],
      disturbances: globalThis.Array.isArray(object?.disturbances)
        ? object.disturbances.map((e: any) => Disturbance.fromJSON(e))
        : [],
      allowsBicycles: isSet(object.allowsBicycles) ? globalThis.Boolean(object.allowsBicycles) : false,
      allowsNetzcard: isSet(object.allowsNetzcard) ? globalThis.Boolean(object.allowsNetzcard) : false,
    };
  },

  toJSON(message: TrainDetails): unknown {
    const obj: any = {};
    if (message.auslastung !== undefined) {
      obj.auslastung = Auslastung.toJSON(message.auslastung);
    }
    if (message.arrival !== undefined) {
      obj.arrival = StopInfo.toJSON(message.arrival);
    }
    if (message.departure !== undefined) {
      obj.departure = StopInfo.toJSON(message.departure);
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.finalDestination !== "") {
      obj.finalDestination = message.finalDestination;
    }
    if (message.journeyId !== "") {
      obj.journeyId = message.journeyId;
    }
    if (message.currentStop !== undefined) {
      obj.currentStop = Stop.toJSON(message.currentStop);
    }
    if (message.segmentStart !== undefined) {
      obj.segmentStart = Station.toJSON(message.segmentStart);
    }
    if (message.segmentDestination !== undefined) {
      obj.segmentDestination = Station.toJSON(message.segmentDestination);
    }
    if (message.train !== undefined) {
      obj.train = Train.toJSON(message.train);
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.coachSequence !== undefined) {
      obj.coachSequence = Reihung.toJSON(message.coachSequence);
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => RemL.toJSON(e));
    }
    if (message.stops?.length) {
      obj.stops = message.stops.map((e) => Stop.toJSON(e));
    }
    if (message.disturbances?.length) {
      obj.disturbances = message.disturbances.map((e) => Disturbance.toJSON(e));
    }
    if (message.allowsBicycles !== false) {
      obj.allowsBicycles = message.allowsBicycles;
    }
    if (message.allowsNetzcard !== false) {
      obj.allowsNetzcard = message.allowsNetzcard;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainDetails>, I>>(base?: I): TrainDetails {
    return TrainDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainDetails>, I>>(object: I): TrainDetails {
    const message = createBaseTrainDetails();
    message.auslastung = (object.auslastung !== undefined && object.auslastung !== null)
      ? Auslastung.fromPartial(object.auslastung)
      : undefined;
    message.arrival = (object.arrival !== undefined && object.arrival !== null)
      ? StopInfo.fromPartial(object.arrival)
      : undefined;
    message.departure = (object.departure !== undefined && object.departure !== null)
      ? StopInfo.fromPartial(object.departure)
      : undefined;
    message.duration = object.duration ?? 0;
    message.finalDestination = object.finalDestination ?? "";
    message.journeyId = object.journeyId ?? "";
    message.currentStop = (object.currentStop !== undefined && object.currentStop !== null)
      ? Stop.fromPartial(object.currentStop)
      : undefined;
    message.segmentStart = (object.segmentStart !== undefined && object.segmentStart !== null)
      ? Station.fromPartial(object.segmentStart)
      : undefined;
    message.segmentDestination = (object.segmentDestination !== undefined && object.segmentDestination !== null)
      ? Station.fromPartial(object.segmentDestination)
      : undefined;
    message.train = (object.train !== undefined && object.train !== null) ? Train.fromPartial(object.train) : undefined;
    message.cancelled = object.cancelled ?? false;
    message.coachSequence = (object.coachSequence !== undefined && object.coachSequence !== null)
      ? Reihung.fromPartial(object.coachSequence)
      : undefined;
    message.messages = object.messages?.map((e) => RemL.fromPartial(e)) || [];
    message.stops = object.stops?.map((e) => Stop.fromPartial(e)) || [];
    message.disturbances = object.disturbances?.map((e) => Disturbance.fromPartial(e)) || [];
    message.allowsBicycles = object.allowsBicycles ?? false;
    message.allowsNetzcard = object.allowsNetzcard ?? false;
    return message;
  },
};

function createBaseAuslastung(): Auslastung {
  return { first: 0, second: 0 };
}

export const Auslastung = {
  encode(message: Auslastung, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.first !== 0) {
      writer.uint32(8).int32(message.first);
    }
    if (message.second !== 0) {
      writer.uint32(16).int32(message.second);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Auslastung {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuslastung();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.first = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.second = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auslastung {
    return {
      first: isSet(object.first) ? auslastungsValueFromJSON(object.first) : 0,
      second: isSet(object.second) ? auslastungsValueFromJSON(object.second) : 0,
    };
  },

  toJSON(message: Auslastung): unknown {
    const obj: any = {};
    if (message.first !== 0) {
      obj.first = auslastungsValueToJSON(message.first);
    }
    if (message.second !== 0) {
      obj.second = auslastungsValueToJSON(message.second);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Auslastung>, I>>(base?: I): Auslastung {
    return Auslastung.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Auslastung>, I>>(object: I): Auslastung {
    const message = createBaseAuslastung();
    message.first = object.first ?? 0;
    message.second = object.second ?? 0;
    return message;
  },
};

function createBasePlannedSequence(): PlannedSequence {
  return { shortType: "", type: "" };
}

export const PlannedSequence = {
  encode(message: PlannedSequence, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.shortType !== "") {
      writer.uint32(10).string(message.shortType);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PlannedSequence {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlannedSequence();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.shortType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlannedSequence {
    return {
      shortType: isSet(object.shortType) ? globalThis.String(object.shortType) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: PlannedSequence): unknown {
    const obj: any = {};
    if (message.shortType !== "") {
      obj.shortType = message.shortType;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlannedSequence>, I>>(base?: I): PlannedSequence {
    return PlannedSequence.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlannedSequence>, I>>(object: I): PlannedSequence {
    const message = createBasePlannedSequence();
    message.shortType = object.shortType ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseRemL(): RemL {
  return { type: "", code: "", text: "", summary: "" };
}

export const RemL = {
  encode(message: RemL, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.text !== "") {
      writer.uint32(26).string(message.text);
    }
    if (message.summary !== "") {
      writer.uint32(34).string(message.summary);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RemL {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.summary = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemL {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
    };
  },

  toJSON(message: RemL): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemL>, I>>(base?: I): RemL {
    return RemL.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemL>, I>>(object: I): RemL {
    const message = createBaseRemL();
    message.type = object.type ?? "";
    message.code = object.code ?? "";
    message.text = object.text ?? "";
    message.summary = object.summary ?? "";
    return message;
  },
};

function createBaseDisturbance(): Disturbance {
  return {
    id: "",
    fromDate: 0,
    toDate: 0,
    lastUpdated: 0,
    fromStation: "",
    toStation: "",
    isResolved: false,
    title: "",
    text: "",
    impact: [],
  };
}

export const Disturbance = {
  encode(message: Disturbance, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.fromDate !== 0) {
      writer.uint32(16).int64(message.fromDate);
    }
    if (message.toDate !== 0) {
      writer.uint32(24).int64(message.toDate);
    }
    if (message.lastUpdated !== 0) {
      writer.uint32(32).int64(message.lastUpdated);
    }
    if (message.fromStation !== "") {
      writer.uint32(42).string(message.fromStation);
    }
    if (message.toStation !== "") {
      writer.uint32(50).string(message.toStation);
    }
    if (message.isResolved !== false) {
      writer.uint32(56).bool(message.isResolved);
    }
    if (message.title !== "") {
      writer.uint32(66).string(message.title);
    }
    if (message.text !== "") {
      writer.uint32(74).string(message.text);
    }
    for (const v of message.impact) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Disturbance {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisturbance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fromDate = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.toDate = longToNumber(reader.int64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lastUpdated = longToNumber(reader.int64());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fromStation = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.toStation = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isResolved = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.title = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.text = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.impact.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Disturbance {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      fromDate: isSet(object.fromDate) ? globalThis.Number(object.fromDate) : 0,
      toDate: isSet(object.toDate) ? globalThis.Number(object.toDate) : 0,
      lastUpdated: isSet(object.lastUpdated) ? globalThis.Number(object.lastUpdated) : 0,
      fromStation: isSet(object.fromStation) ? globalThis.String(object.fromStation) : "",
      toStation: isSet(object.toStation) ? globalThis.String(object.toStation) : "",
      isResolved: isSet(object.isResolved) ? globalThis.Boolean(object.isResolved) : false,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      impact: globalThis.Array.isArray(object?.impact) ? object.impact.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Disturbance): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.fromDate !== 0) {
      obj.fromDate = Math.round(message.fromDate);
    }
    if (message.toDate !== 0) {
      obj.toDate = Math.round(message.toDate);
    }
    if (message.lastUpdated !== 0) {
      obj.lastUpdated = Math.round(message.lastUpdated);
    }
    if (message.fromStation !== "") {
      obj.fromStation = message.fromStation;
    }
    if (message.toStation !== "") {
      obj.toStation = message.toStation;
    }
    if (message.isResolved !== false) {
      obj.isResolved = message.isResolved;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.impact?.length) {
      obj.impact = message.impact;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Disturbance>, I>>(base?: I): Disturbance {
    return Disturbance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Disturbance>, I>>(object: I): Disturbance {
    const message = createBaseDisturbance();
    message.id = object.id ?? "";
    message.fromDate = object.fromDate ?? 0;
    message.toDate = object.toDate ?? 0;
    message.lastUpdated = object.lastUpdated ?? 0;
    message.fromStation = object.fromStation ?? "";
    message.toStation = object.toStation ?? "";
    message.isResolved = object.isResolved ?? false;
    message.title = object.title ?? "";
    message.text = object.text ?? "";
    message.impact = object.impact?.map((e) => e) || [];
    return message;
  },
};

function createBaseIrisMessage(): IrisMessage {
  return { timestamp: 0, superseded: false, code: 0, type: 0 };
}

export const IrisMessage = {
  encode(message: IrisMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestamp !== 0) {
      writer.uint32(8).int64(message.timestamp);
    }
    if (message.superseded !== false) {
      writer.uint32(16).bool(message.superseded);
    }
    if (message.code !== 0) {
      writer.uint32(24).uint32(message.code);
    }
    if (message.type !== 0) {
      writer.uint32(32).int32(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IrisMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIrisMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.superseded = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IrisMessage {
    return {
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      superseded: isSet(object.superseded) ? globalThis.Boolean(object.superseded) : false,
      code: isSet(object.code) ? globalThis.Number(object.code) : 0,
      type: isSet(object.type) ? irisMessageTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: IrisMessage): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.superseded !== false) {
      obj.superseded = message.superseded;
    }
    if (message.code !== 0) {
      obj.code = Math.round(message.code);
    }
    if (message.type !== 0) {
      obj.type = irisMessageTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IrisMessage>, I>>(base?: I): IrisMessage {
    return IrisMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IrisMessage>, I>>(object: I): IrisMessage {
    const message = createBaseIrisMessage();
    message.timestamp = object.timestamp ?? 0;
    message.superseded = object.superseded ?? false;
    message.code = object.code ?? 0;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseStopInfo(): StopInfo {
  return {
    scheduledTime: 0,
    time: 0,
    delay: 0,
    scheduledPlatform: "",
    platform: "",
    hasDelay: false,
    cancelled: false,
    messages: [],
  };
}

export const StopInfo = {
  encode(message: StopInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scheduledTime !== 0) {
      writer.uint32(8).int64(message.scheduledTime);
    }
    if (message.time !== 0) {
      writer.uint32(16).int64(message.time);
    }
    if (message.delay !== 0) {
      writer.uint32(24).int32(message.delay);
    }
    if (message.scheduledPlatform !== "") {
      writer.uint32(34).string(message.scheduledPlatform);
    }
    if (message.platform !== "") {
      writer.uint32(42).string(message.platform);
    }
    if (message.hasDelay !== false) {
      writer.uint32(48).bool(message.hasDelay);
    }
    if (message.cancelled !== false) {
      writer.uint32(56).bool(message.cancelled);
    }
    for (const v of message.messages) {
      RemL.encode(v!, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StopInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStopInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.scheduledTime = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.time = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.delay = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scheduledPlatform = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.platform = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hasDelay = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.messages.push(RemL.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StopInfo {
    return {
      scheduledTime: isSet(object.scheduledTime) ? globalThis.Number(object.scheduledTime) : 0,
      time: isSet(object.time) ? globalThis.Number(object.time) : 0,
      delay: isSet(object.delay) ? globalThis.Number(object.delay) : 0,
      scheduledPlatform: isSet(object.scheduledPlatform) ? globalThis.String(object.scheduledPlatform) : "",
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      hasDelay: isSet(object.hasDelay) ? globalThis.Boolean(object.hasDelay) : false,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => RemL.fromJSON(e)) : [],
    };
  },

  toJSON(message: StopInfo): unknown {
    const obj: any = {};
    if (message.scheduledTime !== 0) {
      obj.scheduledTime = Math.round(message.scheduledTime);
    }
    if (message.time !== 0) {
      obj.time = Math.round(message.time);
    }
    if (message.delay !== 0) {
      obj.delay = Math.round(message.delay);
    }
    if (message.scheduledPlatform !== "") {
      obj.scheduledPlatform = message.scheduledPlatform;
    }
    if (message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.hasDelay !== false) {
      obj.hasDelay = message.hasDelay;
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => RemL.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StopInfo>, I>>(base?: I): StopInfo {
    return StopInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StopInfo>, I>>(object: I): StopInfo {
    const message = createBaseStopInfo();
    message.scheduledTime = object.scheduledTime ?? 0;
    message.time = object.time ?? 0;
    message.delay = object.delay ?? 0;
    message.scheduledPlatform = object.scheduledPlatform ?? "";
    message.platform = object.platform ?? "";
    message.hasDelay = object.hasDelay ?? false;
    message.cancelled = object.cancelled ?? false;
    message.messages = object.messages?.map((e) => RemL.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStop(): Stop {
  return {
    auslastung: undefined,
    arrival: undefined,
    departure: undefined,
    station: undefined,
    additional: false,
    cancelled: false,
    messages: [],
    irisMessages: [],
  };
}

export const Stop = {
  encode(message: Stop, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.auslastung !== undefined) {
      Auslastung.encode(message.auslastung, writer.uint32(10).fork()).join();
    }
    if (message.arrival !== undefined) {
      StopInfo.encode(message.arrival, writer.uint32(18).fork()).join();
    }
    if (message.departure !== undefined) {
      StopInfo.encode(message.departure, writer.uint32(26).fork()).join();
    }
    if (message.station !== undefined) {
      Station.encode(message.station, writer.uint32(34).fork()).join();
    }
    if (message.additional !== false) {
      writer.uint32(40).bool(message.additional);
    }
    if (message.cancelled !== false) {
      writer.uint32(48).bool(message.cancelled);
    }
    for (const v of message.messages) {
      RemL.encode(v!, writer.uint32(58).fork()).join();
    }
    for (const v of message.irisMessages) {
      IrisMessage.encode(v!, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Stop {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.auslastung = Auslastung.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arrival = StopInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.departure = StopInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.station = Station.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.additional = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.cancelled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.messages.push(RemL.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.irisMessages.push(IrisMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Stop {
    return {
      auslastung: isSet(object.auslastung) ? Auslastung.fromJSON(object.auslastung) : undefined,
      arrival: isSet(object.arrival) ? StopInfo.fromJSON(object.arrival) : undefined,
      departure: isSet(object.departure) ? StopInfo.fromJSON(object.departure) : undefined,
      station: isSet(object.station) ? Station.fromJSON(object.station) : undefined,
      additional: isSet(object.additional) ? globalThis.Boolean(object.additional) : false,
      cancelled: isSet(object.cancelled) ? globalThis.Boolean(object.cancelled) : false,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => RemL.fromJSON(e)) : [],
      irisMessages: globalThis.Array.isArray(object?.irisMessages)
        ? object.irisMessages.map((e: any) => IrisMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Stop): unknown {
    const obj: any = {};
    if (message.auslastung !== undefined) {
      obj.auslastung = Auslastung.toJSON(message.auslastung);
    }
    if (message.arrival !== undefined) {
      obj.arrival = StopInfo.toJSON(message.arrival);
    }
    if (message.departure !== undefined) {
      obj.departure = StopInfo.toJSON(message.departure);
    }
    if (message.station !== undefined) {
      obj.station = Station.toJSON(message.station);
    }
    if (message.additional !== false) {
      obj.additional = message.additional;
    }
    if (message.cancelled !== false) {
      obj.cancelled = message.cancelled;
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => RemL.toJSON(e));
    }
    if (message.irisMessages?.length) {
      obj.irisMessages = message.irisMessages.map((e) => IrisMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Stop>, I>>(base?: I): Stop {
    return Stop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Stop>, I>>(object: I): Stop {
    const message = createBaseStop();
    message.auslastung = (object.auslastung !== undefined && object.auslastung !== null)
      ? Auslastung.fromPartial(object.auslastung)
      : undefined;
    message.arrival = (object.arrival !== undefined && object.arrival !== null)
      ? StopInfo.fromPartial(object.arrival)
      : undefined;
    message.departure = (object.departure !== undefined && object.departure !== null)
      ? StopInfo.fromPartial(object.departure)
      : undefined;
    message.station = (object.station !== undefined && object.station !== null)
      ? Station.fromPartial(object.station)
      : undefined;
    message.additional = object.additional ?? false;
    message.cancelled = object.cancelled ?? false;
    message.messages = object.messages?.map((e) => RemL.fromPartial(e)) || [];
    message.irisMessages = object.irisMessages?.map((e) => IrisMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStation(): Station {
  return { evaNumber: "", title: "" };
}

export const Station = {
  encode(message: Station, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.evaNumber !== "") {
      writer.uint32(10).string(message.evaNumber);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Station {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.evaNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Station {
    return {
      evaNumber: isSet(object.evaNumber) ? globalThis.String(object.evaNumber) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: Station): unknown {
    const obj: any = {};
    if (message.evaNumber !== "") {
      obj.evaNumber = message.evaNumber;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Station>, I>>(base?: I): Station {
    return Station.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Station>, I>>(object: I): Station {
    const message = createBaseStation();
    message.evaNumber = object.evaNumber ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseReihung(): Reihung {
  return {
    istPlanReihung: false,
    fahrtrichtung: false,
    anderesZielOderZugnummer: false,
    zugGattung: "",
    startProzent: 0,
    endeProzent: 0,
    skalierung: 0,
    vzn: 0,
    fahrzeugGruppen: [],
    sektoren: [],
    quelle: 0,
    istCached: false,
  };
}

export const Reihung = {
  encode(message: Reihung, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.istPlanReihung !== false) {
      writer.uint32(8).bool(message.istPlanReihung);
    }
    if (message.fahrtrichtung !== false) {
      writer.uint32(16).bool(message.fahrtrichtung);
    }
    if (message.anderesZielOderZugnummer !== false) {
      writer.uint32(24).bool(message.anderesZielOderZugnummer);
    }
    if (message.zugGattung !== "") {
      writer.uint32(34).string(message.zugGattung);
    }
    if (message.startProzent !== 0) {
      writer.uint32(40).int32(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      writer.uint32(48).int32(message.endeProzent);
    }
    if (message.skalierung !== 0) {
      writer.uint32(57).double(message.skalierung);
    }
    if (message.vzn !== 0) {
      writer.uint32(64).uint32(message.vzn);
    }
    for (const v of message.fahrzeugGruppen) {
      FahrzeugGruppe.encode(v!, writer.uint32(74).fork()).join();
    }
    for (const v of message.sektoren) {
      Sektor.encode(v!, writer.uint32(82).fork()).join();
    }
    if (message.quelle !== 0) {
      writer.uint32(88).int32(message.quelle);
    }
    if (message.istCached !== false) {
      writer.uint32(96).bool(message.istCached);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Reihung {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReihung();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.istPlanReihung = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fahrtrichtung = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.anderesZielOderZugnummer = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.zugGattung = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.startProzent = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.endeProzent = reader.int32();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.skalierung = reader.double();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.vzn = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fahrzeugGruppen.push(FahrzeugGruppe.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sektoren.push(Sektor.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.quelle = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.istCached = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Reihung {
    return {
      istPlanReihung: isSet(object.istPlanReihung) ? globalThis.Boolean(object.istPlanReihung) : false,
      fahrtrichtung: isSet(object.fahrtrichtung) ? globalThis.Boolean(object.fahrtrichtung) : false,
      anderesZielOderZugnummer: isSet(object.anderesZielOderZugnummer)
        ? globalThis.Boolean(object.anderesZielOderZugnummer)
        : false,
      zugGattung: isSet(object.zugGattung) ? globalThis.String(object.zugGattung) : "",
      startProzent: isSet(object.startProzent) ? globalThis.Number(object.startProzent) : 0,
      endeProzent: isSet(object.endeProzent) ? globalThis.Number(object.endeProzent) : 0,
      skalierung: isSet(object.skalierung) ? globalThis.Number(object.skalierung) : 0,
      vzn: isSet(object.vzn) ? globalThis.Number(object.vzn) : 0,
      fahrzeugGruppen: globalThis.Array.isArray(object?.fahrzeugGruppen)
        ? object.fahrzeugGruppen.map((e: any) => FahrzeugGruppe.fromJSON(e))
        : [],
      sektoren: globalThis.Array.isArray(object?.sektoren) ? object.sektoren.map((e: any) => Sektor.fromJSON(e)) : [],
      quelle: isSet(object.quelle) ? reihungQuelleFromJSON(object.quelle) : 0,
      istCached: isSet(object.istCached) ? globalThis.Boolean(object.istCached) : false,
    };
  },

  toJSON(message: Reihung): unknown {
    const obj: any = {};
    if (message.istPlanReihung !== false) {
      obj.istPlanReihung = message.istPlanReihung;
    }
    if (message.fahrtrichtung !== false) {
      obj.fahrtrichtung = message.fahrtrichtung;
    }
    if (message.anderesZielOderZugnummer !== false) {
      obj.anderesZielOderZugnummer = message.anderesZielOderZugnummer;
    }
    if (message.zugGattung !== "") {
      obj.zugGattung = message.zugGattung;
    }
    if (message.startProzent !== 0) {
      obj.startProzent = Math.round(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      obj.endeProzent = Math.round(message.endeProzent);
    }
    if (message.skalierung !== 0) {
      obj.skalierung = message.skalierung;
    }
    if (message.vzn !== 0) {
      obj.vzn = Math.round(message.vzn);
    }
    if (message.fahrzeugGruppen?.length) {
      obj.fahrzeugGruppen = message.fahrzeugGruppen.map((e) => FahrzeugGruppe.toJSON(e));
    }
    if (message.sektoren?.length) {
      obj.sektoren = message.sektoren.map((e) => Sektor.toJSON(e));
    }
    if (message.quelle !== 0) {
      obj.quelle = reihungQuelleToJSON(message.quelle);
    }
    if (message.istCached !== false) {
      obj.istCached = message.istCached;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Reihung>, I>>(base?: I): Reihung {
    return Reihung.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Reihung>, I>>(object: I): Reihung {
    const message = createBaseReihung();
    message.istPlanReihung = object.istPlanReihung ?? false;
    message.fahrtrichtung = object.fahrtrichtung ?? false;
    message.anderesZielOderZugnummer = object.anderesZielOderZugnummer ?? false;
    message.zugGattung = object.zugGattung ?? "";
    message.startProzent = object.startProzent ?? 0;
    message.endeProzent = object.endeProzent ?? 0;
    message.skalierung = object.skalierung ?? 0;
    message.vzn = object.vzn ?? 0;
    message.fahrzeugGruppen = object.fahrzeugGruppen?.map((e) => FahrzeugGruppe.fromPartial(e)) || [];
    message.sektoren = object.sektoren?.map((e) => Sektor.fromPartial(e)) || [];
    message.quelle = object.quelle ?? 0;
    message.istCached = object.istCached ?? false;
    return message;
  },
};

function createBaseFahrzeugGruppe(): FahrzeugGruppe {
  return {
    baureihe: 0,
    start: "",
    ziel: "",
    gruppenName: "",
    tzn: 0,
    vzn: 0,
    startProzent: 0,
    endeProzent: 0,
    fahrzeuge: [],
    remarks: "",
  };
}

export const FahrzeugGruppe = {
  encode(message: FahrzeugGruppe, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.baureihe !== 0) {
      writer.uint32(8).int32(message.baureihe);
    }
    if (message.start !== "") {
      writer.uint32(18).string(message.start);
    }
    if (message.ziel !== "") {
      writer.uint32(26).string(message.ziel);
    }
    if (message.gruppenName !== "") {
      writer.uint32(34).string(message.gruppenName);
    }
    if (message.tzn !== 0) {
      writer.uint32(40).uint32(message.tzn);
    }
    if (message.vzn !== 0) {
      writer.uint32(48).uint32(message.vzn);
    }
    if (message.startProzent !== 0) {
      writer.uint32(56).int32(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      writer.uint32(64).int32(message.endeProzent);
    }
    for (const v of message.fahrzeuge) {
      Fahrzeug.encode(v!, writer.uint32(74).fork()).join();
    }
    if (message.remarks !== "") {
      writer.uint32(82).string(message.remarks);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FahrzeugGruppe {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFahrzeugGruppe();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.baureihe = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.start = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ziel = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.gruppenName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.tzn = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.vzn = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.startProzent = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.endeProzent = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fahrzeuge.push(Fahrzeug.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remarks = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FahrzeugGruppe {
    return {
      baureihe: isSet(object.baureihe) ? baureiheFromJSON(object.baureihe) : 0,
      start: isSet(object.start) ? globalThis.String(object.start) : "",
      ziel: isSet(object.ziel) ? globalThis.String(object.ziel) : "",
      gruppenName: isSet(object.gruppenName) ? globalThis.String(object.gruppenName) : "",
      tzn: isSet(object.tzn) ? globalThis.Number(object.tzn) : 0,
      vzn: isSet(object.vzn) ? globalThis.Number(object.vzn) : 0,
      startProzent: isSet(object.startProzent) ? globalThis.Number(object.startProzent) : 0,
      endeProzent: isSet(object.endeProzent) ? globalThis.Number(object.endeProzent) : 0,
      fahrzeuge: globalThis.Array.isArray(object?.fahrzeuge)
        ? object.fahrzeuge.map((e: any) => Fahrzeug.fromJSON(e))
        : [],
      remarks: isSet(object.remarks) ? globalThis.String(object.remarks) : "",
    };
  },

  toJSON(message: FahrzeugGruppe): unknown {
    const obj: any = {};
    if (message.baureihe !== 0) {
      obj.baureihe = baureiheToJSON(message.baureihe);
    }
    if (message.start !== "") {
      obj.start = message.start;
    }
    if (message.ziel !== "") {
      obj.ziel = message.ziel;
    }
    if (message.gruppenName !== "") {
      obj.gruppenName = message.gruppenName;
    }
    if (message.tzn !== 0) {
      obj.tzn = Math.round(message.tzn);
    }
    if (message.vzn !== 0) {
      obj.vzn = Math.round(message.vzn);
    }
    if (message.startProzent !== 0) {
      obj.startProzent = Math.round(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      obj.endeProzent = Math.round(message.endeProzent);
    }
    if (message.fahrzeuge?.length) {
      obj.fahrzeuge = message.fahrzeuge.map((e) => Fahrzeug.toJSON(e));
    }
    if (message.remarks !== "") {
      obj.remarks = message.remarks;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FahrzeugGruppe>, I>>(base?: I): FahrzeugGruppe {
    return FahrzeugGruppe.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FahrzeugGruppe>, I>>(object: I): FahrzeugGruppe {
    const message = createBaseFahrzeugGruppe();
    message.baureihe = object.baureihe ?? 0;
    message.start = object.start ?? "";
    message.ziel = object.ziel ?? "";
    message.gruppenName = object.gruppenName ?? "";
    message.tzn = object.tzn ?? 0;
    message.vzn = object.vzn ?? 0;
    message.startProzent = object.startProzent ?? 0;
    message.endeProzent = object.endeProzent ?? 0;
    message.fahrzeuge = object.fahrzeuge?.map((e) => Fahrzeug.fromPartial(e)) || [];
    message.remarks = object.remarks ?? "";
    return message;
  },
};

function createBaseComfortSitze(): ComfortSitze {
  return { klasse: 0, wagen: "", sitze: "" };
}

export const ComfortSitze = {
  encode(message: ComfortSitze, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.klasse !== 0) {
      writer.uint32(8).uint32(message.klasse);
    }
    if (message.wagen !== "") {
      writer.uint32(18).string(message.wagen);
    }
    if (message.sitze !== "") {
      writer.uint32(26).string(message.sitze);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ComfortSitze {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComfortSitze();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.klasse = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wagen = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sitze = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ComfortSitze {
    return {
      klasse: isSet(object.klasse) ? globalThis.Number(object.klasse) : 0,
      wagen: isSet(object.wagen) ? globalThis.String(object.wagen) : "",
      sitze: isSet(object.sitze) ? globalThis.String(object.sitze) : "",
    };
  },

  toJSON(message: ComfortSitze): unknown {
    const obj: any = {};
    if (message.klasse !== 0) {
      obj.klasse = Math.round(message.klasse);
    }
    if (message.wagen !== "") {
      obj.wagen = message.wagen;
    }
    if (message.sitze !== "") {
      obj.sitze = message.sitze;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ComfortSitze>, I>>(base?: I): ComfortSitze {
    return ComfortSitze.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ComfortSitze>, I>>(object: I): ComfortSitze {
    const message = createBaseComfortSitze();
    message.klasse = object.klasse ?? 0;
    message.wagen = object.wagen ?? "";
    message.sitze = object.sitze ?? "";
    return message;
  },
};

function createBaseFahrzeug(): Fahrzeug {
  return {
    typ: "",
    uic: "",
    won: "",
    klasse: 0,
    status: "",
    sektor: "",
    startProzent: 0,
    endeProzent: 0,
    cssKlassen: undefined,
    comfortSitze: undefined,
  };
}

export const Fahrzeug = {
  encode(message: Fahrzeug, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.typ !== "") {
      writer.uint32(10).string(message.typ);
    }
    if (message.uic !== "") {
      writer.uint32(18).string(message.uic);
    }
    if (message.won !== "") {
      writer.uint32(26).string(message.won);
    }
    if (message.klasse !== 0) {
      writer.uint32(32).uint32(message.klasse);
    }
    if (message.status !== "") {
      writer.uint32(42).string(message.status);
    }
    if (message.sektor !== "") {
      writer.uint32(50).string(message.sektor);
    }
    if (message.startProzent !== 0) {
      writer.uint32(56).int32(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      writer.uint32(64).int32(message.endeProzent);
    }
    if (message.cssKlassen !== undefined) {
      CSSKlassen.encode(message.cssKlassen, writer.uint32(74).fork()).join();
    }
    if (message.comfortSitze !== undefined) {
      ComfortSitze.encode(message.comfortSitze, writer.uint32(82).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Fahrzeug {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFahrzeug();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.typ = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uic = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.won = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.klasse = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.status = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sektor = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.startProzent = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.endeProzent = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.cssKlassen = CSSKlassen.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.comfortSitze = ComfortSitze.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fahrzeug {
    return {
      typ: isSet(object.typ) ? globalThis.String(object.typ) : "",
      uic: isSet(object.uic) ? globalThis.String(object.uic) : "",
      won: isSet(object.won) ? globalThis.String(object.won) : "",
      klasse: isSet(object.klasse) ? globalThis.Number(object.klasse) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      sektor: isSet(object.sektor) ? globalThis.String(object.sektor) : "",
      startProzent: isSet(object.startProzent) ? globalThis.Number(object.startProzent) : 0,
      endeProzent: isSet(object.endeProzent) ? globalThis.Number(object.endeProzent) : 0,
      cssKlassen: isSet(object.cssKlassen) ? CSSKlassen.fromJSON(object.cssKlassen) : undefined,
      comfortSitze: isSet(object.comfortSitze) ? ComfortSitze.fromJSON(object.comfortSitze) : undefined,
    };
  },

  toJSON(message: Fahrzeug): unknown {
    const obj: any = {};
    if (message.typ !== "") {
      obj.typ = message.typ;
    }
    if (message.uic !== "") {
      obj.uic = message.uic;
    }
    if (message.won !== "") {
      obj.won = message.won;
    }
    if (message.klasse !== 0) {
      obj.klasse = Math.round(message.klasse);
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.sektor !== "") {
      obj.sektor = message.sektor;
    }
    if (message.startProzent !== 0) {
      obj.startProzent = Math.round(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      obj.endeProzent = Math.round(message.endeProzent);
    }
    if (message.cssKlassen !== undefined) {
      obj.cssKlassen = CSSKlassen.toJSON(message.cssKlassen);
    }
    if (message.comfortSitze !== undefined) {
      obj.comfortSitze = ComfortSitze.toJSON(message.comfortSitze);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fahrzeug>, I>>(base?: I): Fahrzeug {
    return Fahrzeug.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fahrzeug>, I>>(object: I): Fahrzeug {
    const message = createBaseFahrzeug();
    message.typ = object.typ ?? "";
    message.uic = object.uic ?? "";
    message.won = object.won ?? "";
    message.klasse = object.klasse ?? 0;
    message.status = object.status ?? "";
    message.sektor = object.sektor ?? "";
    message.startProzent = object.startProzent ?? 0;
    message.endeProzent = object.endeProzent ?? 0;
    message.cssKlassen = (object.cssKlassen !== undefined && object.cssKlassen !== null)
      ? CSSKlassen.fromPartial(object.cssKlassen)
      : undefined;
    message.comfortSitze = (object.comfortSitze !== undefined && object.comfortSitze !== null)
      ? ComfortSitze.fromPartial(object.comfortSitze)
      : undefined;
    return message;
  },
};

function createBaseCSSKlassen(): CSSKlassen {
  return { textKlassen: undefined, fahrzeugKlassen: undefined, fahrzeugEigenschaftenKlassen: undefined };
}

export const CSSKlassen = {
  encode(message: CSSKlassen, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.textKlassen !== undefined) {
      TextKlassen.encode(message.textKlassen, writer.uint32(10).fork()).join();
    }
    if (message.fahrzeugKlassen !== undefined) {
      FahrzeugKlassen.encode(message.fahrzeugKlassen, writer.uint32(18).fork()).join();
    }
    if (message.fahrzeugEigenschaftenKlassen !== undefined) {
      FahrzeugEigenschaftenKlassen.encode(message.fahrzeugEigenschaftenKlassen, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CSSKlassen {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCSSKlassen();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.textKlassen = TextKlassen.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fahrzeugKlassen = FahrzeugKlassen.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fahrzeugEigenschaftenKlassen = FahrzeugEigenschaftenKlassen.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CSSKlassen {
    return {
      textKlassen: isSet(object.textKlassen) ? TextKlassen.fromJSON(object.textKlassen) : undefined,
      fahrzeugKlassen: isSet(object.fahrzeugKlassen) ? FahrzeugKlassen.fromJSON(object.fahrzeugKlassen) : undefined,
      fahrzeugEigenschaftenKlassen: isSet(object.fahrzeugEigenschaftenKlassen)
        ? FahrzeugEigenschaftenKlassen.fromJSON(object.fahrzeugEigenschaftenKlassen)
        : undefined,
    };
  },

  toJSON(message: CSSKlassen): unknown {
    const obj: any = {};
    if (message.textKlassen !== undefined) {
      obj.textKlassen = TextKlassen.toJSON(message.textKlassen);
    }
    if (message.fahrzeugKlassen !== undefined) {
      obj.fahrzeugKlassen = FahrzeugKlassen.toJSON(message.fahrzeugKlassen);
    }
    if (message.fahrzeugEigenschaftenKlassen !== undefined) {
      obj.fahrzeugEigenschaftenKlassen = FahrzeugEigenschaftenKlassen.toJSON(message.fahrzeugEigenschaftenKlassen);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CSSKlassen>, I>>(base?: I): CSSKlassen {
    return CSSKlassen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CSSKlassen>, I>>(object: I): CSSKlassen {
    const message = createBaseCSSKlassen();
    message.textKlassen = (object.textKlassen !== undefined && object.textKlassen !== null)
      ? TextKlassen.fromPartial(object.textKlassen)
      : undefined;
    message.fahrzeugKlassen = (object.fahrzeugKlassen !== undefined && object.fahrzeugKlassen !== null)
      ? FahrzeugKlassen.fromPartial(object.fahrzeugKlassen)
      : undefined;
    message.fahrzeugEigenschaftenKlassen =
      (object.fahrzeugEigenschaftenKlassen !== undefined && object.fahrzeugEigenschaftenKlassen !== null)
        ? FahrzeugEigenschaftenKlassen.fromPartial(object.fahrzeugEigenschaftenKlassen)
        : undefined;
    return message;
  },
};

function createBaseTextKlassen(): TextKlassen {
  return { verschlossen: false };
}

export const TextKlassen = {
  encode(message: TextKlassen, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.verschlossen !== false) {
      writer.uint32(8).bool(message.verschlossen);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TextKlassen {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTextKlassen();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.verschlossen = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TextKlassen {
    return { verschlossen: isSet(object.verschlossen) ? globalThis.Boolean(object.verschlossen) : false };
  },

  toJSON(message: TextKlassen): unknown {
    const obj: any = {};
    if (message.verschlossen !== false) {
      obj.verschlossen = message.verschlossen;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TextKlassen>, I>>(base?: I): TextKlassen {
    return TextKlassen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TextKlassen>, I>>(object: I): TextKlassen {
    const message = createBaseTextKlassen();
    message.verschlossen = object.verschlossen ?? false;
    return message;
  },
};

function createBaseFahrzeugKlassen(): FahrzeugKlassen {
  return { lok: false, steuerwagen: false, triebkopf: false, wagen: false };
}

export const FahrzeugKlassen = {
  encode(message: FahrzeugKlassen, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lok !== false) {
      writer.uint32(8).bool(message.lok);
    }
    if (message.steuerwagen !== false) {
      writer.uint32(16).bool(message.steuerwagen);
    }
    if (message.triebkopf !== false) {
      writer.uint32(24).bool(message.triebkopf);
    }
    if (message.wagen !== false) {
      writer.uint32(32).bool(message.wagen);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FahrzeugKlassen {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFahrzeugKlassen();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lok = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.steuerwagen = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.triebkopf = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.wagen = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FahrzeugKlassen {
    return {
      lok: isSet(object.lok) ? globalThis.Boolean(object.lok) : false,
      steuerwagen: isSet(object.steuerwagen) ? globalThis.Boolean(object.steuerwagen) : false,
      triebkopf: isSet(object.triebkopf) ? globalThis.Boolean(object.triebkopf) : false,
      wagen: isSet(object.wagen) ? globalThis.Boolean(object.wagen) : false,
    };
  },

  toJSON(message: FahrzeugKlassen): unknown {
    const obj: any = {};
    if (message.lok !== false) {
      obj.lok = message.lok;
    }
    if (message.steuerwagen !== false) {
      obj.steuerwagen = message.steuerwagen;
    }
    if (message.triebkopf !== false) {
      obj.triebkopf = message.triebkopf;
    }
    if (message.wagen !== false) {
      obj.wagen = message.wagen;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FahrzeugKlassen>, I>>(base?: I): FahrzeugKlassen {
    return FahrzeugKlassen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FahrzeugKlassen>, I>>(object: I): FahrzeugKlassen {
    const message = createBaseFahrzeugKlassen();
    message.lok = object.lok ?? false;
    message.steuerwagen = object.steuerwagen ?? false;
    message.triebkopf = object.triebkopf ?? false;
    message.wagen = object.wagen ?? false;
    return message;
  },
};

function createBaseFahrzeugEigenschaftenKlassen(): FahrzeugEigenschaftenKlassen {
  return {
    restaurant: false,
    schlafwagen: false,
    liegewagen: false,
    comfortlinks: false,
    comfortrechts: false,
    zweiteklasse: false,
    ersteklasse: false,
    ersteklasselinks: false,
    ersteklasserechts: false,
    ersteklasseoben: false,
    zweiteklasselinks: false,
    zweiteklasserechts: false,
  };
}

export const FahrzeugEigenschaftenKlassen = {
  encode(message: FahrzeugEigenschaftenKlassen, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.restaurant !== false) {
      writer.uint32(8).bool(message.restaurant);
    }
    if (message.schlafwagen !== false) {
      writer.uint32(16).bool(message.schlafwagen);
    }
    if (message.liegewagen !== false) {
      writer.uint32(24).bool(message.liegewagen);
    }
    if (message.comfortlinks !== false) {
      writer.uint32(32).bool(message.comfortlinks);
    }
    if (message.comfortrechts !== false) {
      writer.uint32(40).bool(message.comfortrechts);
    }
    if (message.zweiteklasse !== false) {
      writer.uint32(48).bool(message.zweiteklasse);
    }
    if (message.ersteklasse !== false) {
      writer.uint32(56).bool(message.ersteklasse);
    }
    if (message.ersteklasselinks !== false) {
      writer.uint32(64).bool(message.ersteklasselinks);
    }
    if (message.ersteklasserechts !== false) {
      writer.uint32(72).bool(message.ersteklasserechts);
    }
    if (message.ersteklasseoben !== false) {
      writer.uint32(80).bool(message.ersteklasseoben);
    }
    if (message.zweiteklasselinks !== false) {
      writer.uint32(88).bool(message.zweiteklasselinks);
    }
    if (message.zweiteklasserechts !== false) {
      writer.uint32(96).bool(message.zweiteklasserechts);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FahrzeugEigenschaftenKlassen {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFahrzeugEigenschaftenKlassen();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.restaurant = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.schlafwagen = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.liegewagen = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.comfortlinks = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.comfortrechts = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.zweiteklasse = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ersteklasse = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ersteklasselinks = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.ersteklasserechts = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.ersteklasseoben = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.zweiteklasselinks = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.zweiteklasserechts = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FahrzeugEigenschaftenKlassen {
    return {
      restaurant: isSet(object.restaurant) ? globalThis.Boolean(object.restaurant) : false,
      schlafwagen: isSet(object.schlafwagen) ? globalThis.Boolean(object.schlafwagen) : false,
      liegewagen: isSet(object.liegewagen) ? globalThis.Boolean(object.liegewagen) : false,
      comfortlinks: isSet(object.comfortlinks) ? globalThis.Boolean(object.comfortlinks) : false,
      comfortrechts: isSet(object.comfortrechts) ? globalThis.Boolean(object.comfortrechts) : false,
      zweiteklasse: isSet(object.zweiteklasse) ? globalThis.Boolean(object.zweiteklasse) : false,
      ersteklasse: isSet(object.ersteklasse) ? globalThis.Boolean(object.ersteklasse) : false,
      ersteklasselinks: isSet(object.ersteklasselinks) ? globalThis.Boolean(object.ersteklasselinks) : false,
      ersteklasserechts: isSet(object.ersteklasserechts) ? globalThis.Boolean(object.ersteklasserechts) : false,
      ersteklasseoben: isSet(object.ersteklasseoben) ? globalThis.Boolean(object.ersteklasseoben) : false,
      zweiteklasselinks: isSet(object.zweiteklasselinks) ? globalThis.Boolean(object.zweiteklasselinks) : false,
      zweiteklasserechts: isSet(object.zweiteklasserechts) ? globalThis.Boolean(object.zweiteklasserechts) : false,
    };
  },

  toJSON(message: FahrzeugEigenschaftenKlassen): unknown {
    const obj: any = {};
    if (message.restaurant !== false) {
      obj.restaurant = message.restaurant;
    }
    if (message.schlafwagen !== false) {
      obj.schlafwagen = message.schlafwagen;
    }
    if (message.liegewagen !== false) {
      obj.liegewagen = message.liegewagen;
    }
    if (message.comfortlinks !== false) {
      obj.comfortlinks = message.comfortlinks;
    }
    if (message.comfortrechts !== false) {
      obj.comfortrechts = message.comfortrechts;
    }
    if (message.zweiteklasse !== false) {
      obj.zweiteklasse = message.zweiteklasse;
    }
    if (message.ersteklasse !== false) {
      obj.ersteklasse = message.ersteklasse;
    }
    if (message.ersteklasselinks !== false) {
      obj.ersteklasselinks = message.ersteklasselinks;
    }
    if (message.ersteklasserechts !== false) {
      obj.ersteklasserechts = message.ersteklasserechts;
    }
    if (message.ersteklasseoben !== false) {
      obj.ersteklasseoben = message.ersteklasseoben;
    }
    if (message.zweiteklasselinks !== false) {
      obj.zweiteklasselinks = message.zweiteklasselinks;
    }
    if (message.zweiteklasserechts !== false) {
      obj.zweiteklasserechts = message.zweiteklasserechts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FahrzeugEigenschaftenKlassen>, I>>(base?: I): FahrzeugEigenschaftenKlassen {
    return FahrzeugEigenschaftenKlassen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FahrzeugEigenschaftenKlassen>, I>>(object: I): FahrzeugEigenschaftenKlassen {
    const message = createBaseFahrzeugEigenschaftenKlassen();
    message.restaurant = object.restaurant ?? false;
    message.schlafwagen = object.schlafwagen ?? false;
    message.liegewagen = object.liegewagen ?? false;
    message.comfortlinks = object.comfortlinks ?? false;
    message.comfortrechts = object.comfortrechts ?? false;
    message.zweiteklasse = object.zweiteklasse ?? false;
    message.ersteklasse = object.ersteklasse ?? false;
    message.ersteklasselinks = object.ersteklasselinks ?? false;
    message.ersteklasserechts = object.ersteklasserechts ?? false;
    message.ersteklasseoben = object.ersteklasseoben ?? false;
    message.zweiteklasselinks = object.zweiteklasselinks ?? false;
    message.zweiteklasserechts = object.zweiteklasserechts ?? false;
    return message;
  },
};

function createBaseSektor(): Sektor {
  return { name: "", startProzent: 0, endeProzent: 0 };
}

export const Sektor = {
  encode(message: Sektor, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.startProzent !== 0) {
      writer.uint32(16).int32(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      writer.uint32(24).int32(message.endeProzent);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Sektor {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSektor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.startProzent = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.endeProzent = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sektor {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      startProzent: isSet(object.startProzent) ? globalThis.Number(object.startProzent) : 0,
      endeProzent: isSet(object.endeProzent) ? globalThis.Number(object.endeProzent) : 0,
    };
  },

  toJSON(message: Sektor): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.startProzent !== 0) {
      obj.startProzent = Math.round(message.startProzent);
    }
    if (message.endeProzent !== 0) {
      obj.endeProzent = Math.round(message.endeProzent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Sektor>, I>>(base?: I): Sektor {
    return Sektor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Sektor>, I>>(object: I): Sektor {
    const message = createBaseSektor();
    message.name = object.name ?? "";
    message.startProzent = object.startProzent ?? 0;
    message.endeProzent = object.endeProzent ?? 0;
    return message;
  },
};

export interface HafasService {
  CoachSequence(request: DeepPartial<CoachSequenceRequest>, metadata?: grpc.Metadata): Promise<CoachSequenceResponse>;
  RoutesFromDateTime(request: DeepPartial<RouteFromDateTimeRequest>, metadata?: grpc.Metadata): Promise<RouteResponse>;
  RoutesFromContext(request: DeepPartial<RouteFromContextRequest>, metadata?: grpc.Metadata): Promise<RouteResponse>;
  TicketSearch(request: DeepPartial<TicketSearchRequest>, metadata?: grpc.Metadata): Promise<UpdatedJourneyResponse>;
  TrainDetailsWithSearch(
    request: DeepPartial<TrainDetailsWithSearchRequest>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse>;
  TrainDetails(request: DeepPartial<TrainDetailsRequest>, metadata?: grpc.Metadata): Observable<TrainDetailsResponse>;
  TrainSearch(request: DeepPartial<TrainSearchRequest>, metadata?: grpc.Metadata): Observable<TrainDetailsResponse>;
  RefreshJourney(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdatedJourneyResponse>;
  RefreshJourneyWithPlanSequence(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdatedJourneyResponse>;
  SearchForAlternatives(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AlternativesResponse>;
  StreamTrainDetailsWithSearch(
    request: Observable<DeepPartial<StreamTrainDetailsWithSearchRequest>>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse>;
  StreamTrainDetails(
    request: Observable<DeepPartial<StreamTrainDetailsRequest>>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse>;
}

export class HafasServiceClientImpl implements HafasService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CoachSequence = this.CoachSequence.bind(this);
    this.RoutesFromDateTime = this.RoutesFromDateTime.bind(this);
    this.RoutesFromContext = this.RoutesFromContext.bind(this);
    this.TicketSearch = this.TicketSearch.bind(this);
    this.TrainDetailsWithSearch = this.TrainDetailsWithSearch.bind(this);
    this.TrainDetails = this.TrainDetails.bind(this);
    this.TrainSearch = this.TrainSearch.bind(this);
    this.RefreshJourney = this.RefreshJourney.bind(this);
    this.RefreshJourneyWithPlanSequence = this.RefreshJourneyWithPlanSequence.bind(this);
    this.SearchForAlternatives = this.SearchForAlternatives.bind(this);
    this.StreamTrainDetailsWithSearch = this.StreamTrainDetailsWithSearch.bind(this);
    this.StreamTrainDetails = this.StreamTrainDetails.bind(this);
  }

  CoachSequence(request: DeepPartial<CoachSequenceRequest>, metadata?: grpc.Metadata): Promise<CoachSequenceResponse> {
    return this.rpc.unary(HafasServiceCoachSequenceDesc, CoachSequenceRequest.fromPartial(request), metadata);
  }

  RoutesFromDateTime(request: DeepPartial<RouteFromDateTimeRequest>, metadata?: grpc.Metadata): Promise<RouteResponse> {
    return this.rpc.unary(HafasServiceRoutesFromDateTimeDesc, RouteFromDateTimeRequest.fromPartial(request), metadata);
  }

  RoutesFromContext(request: DeepPartial<RouteFromContextRequest>, metadata?: grpc.Metadata): Promise<RouteResponse> {
    return this.rpc.unary(HafasServiceRoutesFromContextDesc, RouteFromContextRequest.fromPartial(request), metadata);
  }

  TicketSearch(request: DeepPartial<TicketSearchRequest>, metadata?: grpc.Metadata): Promise<UpdatedJourneyResponse> {
    return this.rpc.unary(HafasServiceTicketSearchDesc, TicketSearchRequest.fromPartial(request), metadata);
  }

  TrainDetailsWithSearch(
    request: DeepPartial<TrainDetailsWithSearchRequest>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse> {
    return this.rpc.invoke(
      HafasServiceTrainDetailsWithSearchDesc,
      TrainDetailsWithSearchRequest.fromPartial(request),
      metadata,
    );
  }

  TrainDetails(request: DeepPartial<TrainDetailsRequest>, metadata?: grpc.Metadata): Observable<TrainDetailsResponse> {
    return this.rpc.invoke(HafasServiceTrainDetailsDesc, TrainDetailsRequest.fromPartial(request), metadata);
  }

  TrainSearch(request: DeepPartial<TrainSearchRequest>, metadata?: grpc.Metadata): Observable<TrainDetailsResponse> {
    return this.rpc.invoke(HafasServiceTrainSearchDesc, TrainSearchRequest.fromPartial(request), metadata);
  }

  RefreshJourney(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdatedJourneyResponse> {
    return this.rpc.unary(HafasServiceRefreshJourneyDesc, RefreshJourneyRequest.fromPartial(request), metadata);
  }

  RefreshJourneyWithPlanSequence(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdatedJourneyResponse> {
    return this.rpc.unary(
      HafasServiceRefreshJourneyWithPlanSequenceDesc,
      RefreshJourneyRequest.fromPartial(request),
      metadata,
    );
  }

  SearchForAlternatives(
    request: DeepPartial<RefreshJourneyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AlternativesResponse> {
    return this.rpc.unary(HafasServiceSearchForAlternativesDesc, RefreshJourneyRequest.fromPartial(request), metadata);
  }

  StreamTrainDetailsWithSearch(
    request: Observable<DeepPartial<StreamTrainDetailsWithSearchRequest>>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse> {
    throw new Error("ts-proto does not yet support client streaming!");
  }

  StreamTrainDetails(
    request: Observable<DeepPartial<StreamTrainDetailsRequest>>,
    metadata?: grpc.Metadata,
  ): Observable<TrainDetailsResponse> {
    throw new Error("ts-proto does not yet support client streaming!");
  }
}

export const HafasServiceDesc = { serviceName: "hafas.HafasService" };

export const HafasServiceCoachSequenceDesc: UnaryMethodDefinitionish = {
  methodName: "CoachSequence",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CoachSequenceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CoachSequenceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceRoutesFromDateTimeDesc: UnaryMethodDefinitionish = {
  methodName: "RoutesFromDateTime",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RouteFromDateTimeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RouteResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceRoutesFromContextDesc: UnaryMethodDefinitionish = {
  methodName: "RoutesFromContext",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RouteFromContextRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RouteResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceTicketSearchDesc: UnaryMethodDefinitionish = {
  methodName: "TicketSearch",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TicketSearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdatedJourneyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceTrainDetailsWithSearchDesc: UnaryMethodDefinitionish = {
  methodName: "TrainDetailsWithSearch",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return TrainDetailsWithSearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TrainDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceTrainDetailsDesc: UnaryMethodDefinitionish = {
  methodName: "TrainDetails",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return TrainDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TrainDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceTrainSearchDesc: UnaryMethodDefinitionish = {
  methodName: "TrainSearch",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return TrainSearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TrainDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceRefreshJourneyDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshJourney",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshJourneyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdatedJourneyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceRefreshJourneyWithPlanSequenceDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshJourneyWithPlanSequence",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshJourneyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdatedJourneyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const HafasServiceSearchForAlternativesDesc: UnaryMethodDefinitionish = {
  methodName: "SearchForAlternatives",
  service: HafasServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshJourneyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AlternativesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
