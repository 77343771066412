import { type Meldung, MeldungQuelle } from '@/model/meldung';
import { type HimMeldungDto, type MeldungDto, type RisMeldungDto } from '@/stores/dto/MeldungDto';

export function mapMeldungToDto(meldung: Meldung): MeldungDto {
  switch (meldung.quelle) {
    case MeldungQuelle.RIS:
      return {
        quelle: meldung.quelle,
        id: meldung.id,
        typ: meldung.typ,
        zeitpunkt: meldung.zeitpunkt.valueOf(),
        code: meldung.code,
        isErsetzt: meldung.isErsetzt,
      } as RisMeldungDto;
    case MeldungQuelle.HIM:
      return {
        quelle: meldung.quelle,
        id: meldung.id,
        titel: meldung.titel,
        text: meldung.text,
      } as HimMeldungDto;
  }
}
